import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import ReleaseForm from './ReleaseForm';
import ReleaseListBody from './ReleaseListBody';
import ReleaseListHeader from './ReleaseListHeader';
import ReleaseView from './ReleaseView';

const Release = () => {
  return (
    <>
      <MDBox component="main" my={3}>
        <Container maxWidth={false}>
          <ReleaseListHeader />
          <MDBox sx={{ mt: 3 }}>
            <ReleaseListBody />
          </MDBox>
        </Container>
      </MDBox>
      <ReleaseForm />
      <ReleaseView />
    </>
  );
};

export default Release;
