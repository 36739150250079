import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './modules';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { MaterialUIControllerProvider } from 'context';
import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';
import enUsMsg from './lang/en-US.json';
import koMsg from './lang/ko.json';

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const store = createStore(rootReducer, composedEnhancer);
const locale = localStorage.getItem('locale') ?? 'ko';
const messages = { 'en-US': enUsMsg, ko: koMsg }[locale];

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <SnackbarProvider>
          <IntlProvider locale={locale} messages={messages}>
            <App />
          </IntlProvider>
        </SnackbarProvider>
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
