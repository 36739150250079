/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from '@mui/material';
import DeleteAlert from 'components/common/DeleteAlert';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import DefaultCell from 'components/datacell/DefaultCell';
import OperationCell from 'components/datacell/OperationCell';
import StatusCell from 'components/datacell/StatusCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import { existUser } from 'lib/api/modules';
import { existAuthonrities } from 'lib/utils/auth';
import { updateDeleteAlertState } from 'modules/common';
import { getCustomerList } from 'modules/customers';
import { getRoleList } from 'modules/roles';
import {
  changeForm,
  deleteUser,
  getUser,
  getUserList,
  pageOut,
} from 'modules/users';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FormattedMessage, useIntl } from 'react-intl';

const UserListBody = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { data, loading, error } = useSelector((state) => state.users.users);
  const fetch = useSelector((state) => state.users.fetch);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const existWriteAuth = existAuthonrities('USER_RW');

  const unLoadUser = useCallback(() => dispatch(pageOut()), [dispatch]);

  const loadUser = useCallback(() => dispatch(getUserList()), [dispatch]);

  useEffect(() => {
    if (fetch) {
      loadUser();
    }
    return () => {
      if (!fetch) unLoadUser();
    };
  }, [loadUser, unLoadUser, fetch]);

  const handleFormOpen = () => {
    dispatch(getRoleList());
    dispatch(getCustomerList());
    dispatch(changeForm({ status: true }));
  };

  const handleUpdateFormOpen = useCallback(
    (id) => () => {
      dispatch(getUser(id));
      handleFormOpen();
    },
    [],
  );

  const handleDeleteAlertOpen = useCallback(
    (id) => async () => {
      setSelectedUserId(id);
      try {
        const response = await existUser(id);
        if (!response.data.data) {
          dispatch(updateDeleteAlertState(true));
        } else {
          swal(
            'Failed',
            intl.formatMessage({
              id: 'valid-fail-delete-exist-module-user',
            }),
            'error',
          );
        }
      } catch (error) {
        swal('Failed', error.response.data, 'error');
      }
    },
    [],
  );

  const handleDelete = () => {
    dispatch(deleteUser(selectedUserId));
    dispatch(updateDeleteAlertState(false));
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const UserData = {
    columns: [
      {
        Header: <FormattedMessage id="name" />,
        accessor: 'name',
        sort: false,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="customer-name" />,
        accessor: 'customerName',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="department" />,
        accessor: 'department',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="position" />,
        accessor: 'position',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="phone" />,
        accessor: 'phone',
        sort: false,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="email" />,
        accessor: 'email',
        sort: false,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="role-name" />,
        accessor: 'roleName',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="activated" />,
        accessor: 'isActivated',
        sort: false,
        Cell: ({ value }) => (
          <StatusCell
            value={
              value ? (
                <FormattedMessage id="activated" />
              ) : (
                <FormattedMessage id="inactived" />
              )
            }
            color={value ? 'success' : 'error'}
          />
        ),
      },
      {
        Header: <FormattedMessage id="locked" />,
        accessor: 'isLocked',
        sort: false,
        Cell: ({ value }) => (
          <StatusCell
            value={
              value ? (
                <FormattedMessage id="locked" />
              ) : (
                <FormattedMessage id="unlocked" />
              )
            }
            color={value ? 'error' : 'success'}
          />
        ),
      },
      {
        Header: <FormattedMessage id="edit-remove" />,
        accessor: 'id',
        sort: false,
        Cell: ({ value }) => (
          <OperationCell
            enableUpdate={existWriteAuth}
            enableDelete={existWriteAuth}
            handleUpdateFormOpen={handleUpdateFormOpen(value)}
            handleDeleteAlertOpen={handleDeleteAlertOpen(value)}
          />
        ),
      },
    ],
    rows: data,
  };

  return (
    <Card>
      <DataTable table={UserData} entriesPerPage={false} canSearch />
      <DeleteAlert
        resource={<FormattedMessage id="user" />}
        handleDelete={handleDelete}
      />
    </Card>
  );
};

export default UserListBody;
