import axios from 'axios';

export const getSettingList = () => axios.get(`/api/settings`);
export const putSetting = (setting) =>
  axios.put(`/api/settings/${setting.id}`, setting);
export const getSettingProperties = (name) =>
  axios.get(`/api/settings/properties/${name}`);
export const getSettingOTP = () => axios.get(`/api/settings/otp`);
export const getSettingTokenSilent = () =>
  axios.get(`/api/settings/token/silent`);
