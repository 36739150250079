import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { updateDownloadAlertState } from 'modules/common';
import { useDispatch, useSelector } from 'react-redux';
import MDButton from '../otis/MDButton';
import { FormattedMessage } from 'react-intl';

const DownloadAlert = (props) => {
  const dispatch = useDispatch();
  const downloadAlertState = useSelector(
    (state) => state.common.alert.download,
  );
  const { handleDownload } = props;

  const downloadAlertClose = () => {
    dispatch(updateDownloadAlertState(false));
  };

  return (
    <Dialog open={downloadAlertState}>
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="module-download" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id="ask-download" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton autoFocus onClick={handleDownload}>
          <FormattedMessage id="download" />
        </MDButton>
        <MDButton onClick={downloadAlertClose}>
          <FormattedMessage id="cancel" />
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadAlert;
