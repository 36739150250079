import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton } from '@mui/material';
import MDBox from 'components/otis/MDBox';

function RetryCell({ enableRetry, handleRetry }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox>
        {enableRetry === 'FAILURE' && (
          <IconButton aria-label="download" size="small" onClick={handleRetry}>
            <ReplayIcon fontSize="small" />
          </IconButton>
        )}
      </MDBox>
    </MDBox>
  );
}

export default RetryCell;
