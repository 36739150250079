import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import LanguageIcon from '@mui/icons-material/Language';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {
  AppBar,
  Divider,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import axios from 'axios';
import PasswordUpdate from 'components/common/PasswordUpdate';
import Breadcrumbs from 'components/otis/Breadcrumbs';
import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';
import {
  setMiniSidenav,
  setTransparentNavbar,
  useMaterialUIController,
} from 'context';
import { logout } from 'lib/api/auth';
import { getRefreshToken, getUser, removeToken } from 'lib/utils/auth';
import {
  updateLanguageAlertState,
  updatePasswordUpdateAlertState,
} from 'modules/common';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from './styles/styles';
import LanguageUpdate from 'components/common/LanguageUpdate';

const Navbar = ({ absolute, light, isMini }) => {
  const [navbarType, setNavbarType] = useState();
  const navigate = useNavigate();
  const [openAuthMenu, setOpenAuthMenu] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const thunkDispatch = useDispatch();
  const intl = useIntl();
  const route = useLocation().pathname.split('/').slice(2);
  //const { contents } = useSelector((state) => state.common.notice);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleOpenAuthMenu = (event) => setOpenAuthMenu(event.currentTarget);
  const handleCloseAuthMenu = () => setOpenAuthMenu(false);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleOpenPwdUpdate = () => {
    handleCloseAuthMenu();
    thunkDispatch(updatePasswordUpdateAlertState(true));
  };

  const handleOpenLanguageUpdate = () => {
    handleCloseAuthMenu();
    thunkDispatch(updateLanguageAlertState(true));
  };

  const handleLogout = async () => {
    try {
      delete axios.defaults.headers.common['Authorization'];
      await logout(getRefreshToken());
      swal(
        intl.formatMessage({
          id: 'logout',
        }),
        intl.formatMessage({
          id: 'logout-success',
        }),
        'success',
        {
          buttons: false,
          timer: 2000,
        },
      ).then((value) => {
        removeToken();
        navigate('/');
        navigate(0);
      });
    } catch (error) {
      swal(
        intl.formatMessage({
          id: 'logout',
        }),
        error.response.data,
        'error',
      );
    }
  };

  const renderAuthMenu = () => (
    <Menu
      anchorEl={openAuthMenu}
      id="account-menu"
      open={Boolean(openAuthMenu)}
      onClose={handleCloseAuthMenu}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      sx={{ mt: 2 }}
    >
      <MenuItem onClick={handleOpenLanguageUpdate}>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <FormattedMessage id="language-change" />
      </MenuItem>
      <MenuItem onClick={handleOpenPwdUpdate}>
        <ListItemIcon>
          <KeyIcon />
        </ListItemIcon>
        <FormattedMessage id="password-change" />
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <FormattedMessage id="logout" />
      </MenuItem>
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
          <IconButton
            onClick={handleMiniSidenav}
            size="small"
            sx={navbarDesktopMenu}
            disableRipple
          >
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? <MenuOpenIcon /> : <MenuIcon />}
            </Icon>
          </IconButton>
        </MDBox>
        {!isMini && (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox display="flex" alignItems="center">
              <MDBox mr={1}>
                <MDTypography
                  variant="body2"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {getUser()}
                </MDTypography>
              </MDBox>
              <MDBox alignSelf="stretch">
                <Divider
                  orientation="vertical"
                  sx={{ opacity: 1, height: '75%', m: 1 }}
                />
              </MDBox>
              <IconButton
                onClick={handleMiniSidenav}
                size="small"
                sx={navbarMobileMenu}
                disableRipple
              >
                <Icon fontSize="medium" sx={iconsStyle}>
                  {miniSidenav ? <MenuOpenIcon /> : <MenuIcon />}
                </Icon>
              </IconButton>
              <MDBox>
                <IconButton
                  size="large"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenAuthMenu}
                >
                  <AccountCircleIcon sx={iconsStyle} />
                </IconButton>
                {renderAuthMenu()}
              </MDBox>
            </MDBox>
          </MDBox>
        )}
        <PasswordUpdate />
        <LanguageUpdate />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
