import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import RefreshIcon from '@mui/icons-material/Refresh';
import StoreIcon from '@mui/icons-material/Store';
import { Card, IconButton, Tooltip } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';
import CustomerStatisticsCard from 'components/otis/StatisticsCards/CustomerStatisticsCard';
import {
  getCountByCustomerAll,
  getCountByCustomerExpired,
  getCountByCustomerIncrease,
} from 'modules/dashboard';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const CustomerStatus = () => {
  const dispatch = useDispatch();
  const { all, expired, increase } = useSelector(
    (state) => state.dashboard.customer.data,
  );

  const handleRefresh = useCallback(() => {
    dispatch(getCountByCustomerAll());
    dispatch(getCountByCustomerExpired());
    dispatch(getCountByCustomerIncrease());
  }, [dispatch]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <Card sx={{ height: '100%' }}>
      <MDBox
        lineHeight={1}
        py={2}
        px={2}
        alignItems="center"
        justifyContent="space-between"
        display="flex"
      >
        <MDTypography variant="h6" fontWeight="medium">
          {<FormattedMessage id="customer" />}
        </MDTypography>
        <Tooltip title="refresh" placement="bottom" arrow>
          <IconButton aria-label="delete" size="m" onClick={handleRefresh}>
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </MDBox>
      <MDBox p={2}>
        <CustomerStatisticsCard
          icon={<StoreIcon />}
          title={<FormattedMessage id="all" />}
          count={all ? all : 0}
        />
        <MDBox pt={2}>
          <CustomerStatisticsCard
            color="success"
            icon={<AddBusinessIcon />}
            title={<FormattedMessage id="monthly-increase" />}
            count={increase ? increase : 0}
          />
        </MDBox>
        <MDBox pt={2}>
          <CustomerStatisticsCard
            color="primary"
            icon={<DoNotDisturbIcon />}
            title={<FormattedMessage id="expiration-usage-period" />}
            count={expired ? expired : 0}
          />
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default CustomerStatus;
