import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';
import { FormattedMessage } from 'react-intl';

const SettingListHeader = () => {
  return (
    <MDBox>
      <MDBox
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
        }}
      >
        <MDTypography
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'text.secondary' }}
          variant="h4"
        >
          <FormattedMessage id="setting" />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default SettingListHeader;
