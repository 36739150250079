import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';
import moment from 'moment';

function DateCell({ value }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox ml={1}>
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default DateCell;
