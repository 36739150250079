import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import CustomerForm from './CustomerForm';
import CustomerListBody from './CustomerListBody';
import CustomerListHeader from './CustomerListHeader';

const Customer = () => {
  return (
    <>
      <MDBox component="main" my={3}>
        <Container maxWidth={false}>
          <CustomerListHeader />
          <MDBox sx={{ mt: 3 }}>
            <CustomerListBody />
          </MDBox>
        </Container>
      </MDBox>
      <CustomerForm />
    </>
  );
};

export default Customer;
