/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from '@mui/material';
import DeleteAlert from 'components/common/DeleteAlert';
import Loading from 'components/common/Loading';
import Error from 'components/common/error/Error';
import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import ExpiredCell from 'components/datacell/ExpiredCell';
import OperationCell from 'components/datacell/OperationCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import { existCustomer } from 'lib/api/users';
import { existAuthonrities } from 'lib/utils/auth';
import { updateDeleteAlertState } from 'modules/common';
import {
  changeForm,
  deleteCustomer,
  getCustomer,
  getCustomerList,
  pageOut,
} from 'modules/customers';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';

const CustomerListBody = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { data, loading, error } = useSelector(
    (state) => state.customers.customers,
  );
  const fetch = useSelector((state) => state.customers.fetch);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const existWriteAuth = existAuthonrities('CUSTOMER_RW');

  const unLoadCustomer = useCallback(() => dispatch(pageOut()), [dispatch]);

  const loadCustomer = useCallback(
    () => dispatch(getCustomerList()),
    [dispatch],
  );

  const handleFormOpen = () => {
    dispatch(changeForm({ status: true }));
  };

  const handleUpdateFormOpen = useCallback(
    (id) => () => {
      dispatch(getCustomer(id));
      handleFormOpen();
    },
    [],
  );

  const handleDelete = () => {
    dispatch(deleteCustomer(selectedUserId));
    dispatch(updateDeleteAlertState(false));
  };

  const handleDeleteAlertOpen = useCallback(
    (id) => async () => {
      setSelectedUserId(id);

      try {
        const response = await existCustomer(id);
        if (!response.data.data) {
          dispatch(updateDeleteAlertState(true));
        } else {
          swal(
            'Failed',
            intl.formatMessage({
              id: 'valid-fail-delete-usage-customer',
            }),
            'error',
          );
        }
      } catch (error) {
        swal('Failed', error.response.data, 'error');
      }
    },
    [],
  );

  useEffect(() => {
    if (fetch) {
      loadCustomer();
    }
    return () => {
      if (!fetch) unLoadCustomer();
    };
  }, [loadCustomer, unLoadCustomer, fetch]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const CustomerData = {
    columns: [
      {
        Header: <FormattedMessage id="name" />,
        accessor: 'name',
        sort: false,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="expiry-start-date" />,
        accessor: 'expiredStartDt',
        sort: true,
        Cell: ({ value }) => <ExpiredCell value={value} />,
      },
      {
        Header: <FormattedMessage id="expiry-end-date" />,
        accessor: 'expiredEndDt',
        sort: true,
        Cell: ({ value }) => <ExpiredCell value={value} />,
      },
      {
        Header: <FormattedMessage id="created-date" />,
        accessor: 'createDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="edit-remove" />,
        accessor: 'id',
        sort: false,
        Cell: ({ value }) => (
          <OperationCell
            enableUpdate={existWriteAuth}
            enableDelete={existWriteAuth}
            handleUpdateFormOpen={handleUpdateFormOpen(value)}
            handleDeleteAlertOpen={handleDeleteAlertOpen(value)}
          />
        ),
      },
    ],
    rows: data,
  };
  return (
    <Card>
      <DataTable table={CustomerData} entriesPerPage={false} canSearch />
      <DeleteAlert
        resource={<FormattedMessage id="customer" />}
        handleDelete={handleDelete}
      />
    </Card>
  );
};

export default CustomerListBody;
