import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import RoleForm from './RoleForm';
import RoleListBody from './RoleListBody';
import RoleListHeader from './RoleListHeader';

const index = () => {
  return (
    <>
      <MDBox component="main" my={3}>
        <Container maxWidth={false}>
          <RoleListHeader />
          <MDBox sx={{ mt: 3 }}>
            <RoleListBody />
          </MDBox>
        </Container>
      </MDBox>
      <RoleForm />
    </>
  );
};

export default index;
