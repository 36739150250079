import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import MDButton from '../../otis/MDButton';
import { updateRetryAlertState } from 'modules/common';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const RetryAlert = (props) => {
  const dispatch = useDispatch();
  const retryAlertState = useSelector((state) => state.common.alert.retry);
  const { resource, handleRetry } = props;

  const retryAlertClose = () => {
    dispatch(updateRetryAlertState(false));
  };

  return (
    <Dialog open={retryAlertState}>
      <DialogTitle id="alert-dialog-title">
        {resource} <FormattedMessage id="retry" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id="ask-retry" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton autoFocus onClick={handleRetry}>
          <FormattedMessage id="retry" />
        </MDButton>
        <MDButton onClick={retryAlertClose}>
          <FormattedMessage id="cancel" />
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default RetryAlert;
