import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import OperationBody from './OperationBody';
import OperationHeader from './OperationHeader';

const Operation = () => {
  return (
    <>
      <MDBox component="main" my={3}>
        <Container maxWidth={false}>
          <OperationHeader />
          <MDBox sx={{ mt: 3 }}>
            <OperationBody />
          </MDBox>
        </Container>
      </MDBox>
    </>
  );
};

export default Operation;
