/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Otis Admin PRO React components
import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';

// Otis Admin PRO React context
import { useMaterialUIController } from 'context';

function Bill({ identity, keyValue, noGutter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? 'transparent' : 'grey-100'}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
          mb={2}
        >
          <MDTypography
            variant="button"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {keyValue[identity]}
          </MDTypography>
        </MDBox>
        {Object.entries(keyValue).map((items, index) =>
          items[0] !== identity ? (
            <MDBox mb={1} lineHeight={0} key={index}>
              <MDTypography
                variant="caption"
                color="text"
                textTransform="capitalize"
              >
                {items[0] ? items[0] : ''} :&nbsp;&nbsp;&nbsp;
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  {items[1] ? items[1] : ''}
                </MDTypography>
              </MDTypography>
            </MDBox>
          ) : null,
        )}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

export default Bill;
