// eslint-disable-next-line
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
} from '@mui/material';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDEditor from 'components/otis/MDEditor';
import MDInput from 'components/otis/MDInput';
import MDTypography from 'components/otis/MDTypography';
import {
  changeField,
  changeForm,
  deleteDevGuideFile,
  postDevGuide,
  putDevGuide,
} from 'modules/devguides';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FormattedMessage, useIntl } from 'react-intl';

const DevGuideForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { loading, error } = useSelector((state) => state.devguide.devguide);
  const devguide = useSelector((state) => state.devguide.devguide.data);
  var defaultList = devguide === null ? null : devguide.attachFileList;

  const mode = useSelector((state) => state.devguide.mode);
  const form = useSelector((state) => state.devguide.form);

  const [updateFileList, setUpdateFileList] = useState([]);
  const [deleteFileList, setDeleteFileList] = useState([]);
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setUpdateFileList(defaultList);
  }, [defaultList]);

  const handleAdd = () => {
    if (onValdation()) {
      const formData = new FormData();
      formData.append('guide', JSON.stringify(devguide));
      files.map((file) => formData.append('files', file));
      dispatch(postDevGuide(formData));
      handleFormClose();
    }
  };
  const handleUpdate = () => {
    if (onValdation()) {
      const formData = new FormData();
      formData.append('guide', JSON.stringify(devguide));
      files.map((file) => formData.append('files', file));
      //파일 업로드
      const dev_id = devguide.id;
      for (var i = 0; i < deleteFileList.length; i++) {
        dispatch(deleteDevGuideFile(deleteFileList[i], dev_id));
      }
      dispatch(putDevGuide(devguide.id, formData));
      handleFormClose();
    }
  };
  const onChanged = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.value,
      }),
    );
  };

  const onChangedContent = (fieldkey, fieldvalue) => {
    dispatch(
      changeField({
        key: 'contentHtml',
        value: fieldvalue,
      }),
    );
    dispatch(
      changeField({
        key: fieldkey,
        value: window.btoa(unescape(encodeURIComponent(fieldvalue))),
      }),
    );
  };

  const onChangedFileList = (fieldkey, fieldvalue) => {
    dispatch(changeField({ key: fieldkey, value: fieldvalue }));
  };

  const handleFormClose = () => {
    setFiles([]); //파일초기화
    setUpdateFileList([]); //보여주는 파일데이터 초기화
    setDeleteFileList([]); //삭제될파일명 초기화
    dispatch(changeForm({ status: false }));
    setErrors({});
  };
  const onValdation = () => {
    let temp = {};

    temp.title = devguide.title ? (
      ''
    ) : (
      <FormattedMessage id="valid-subject-input" />
    );
    temp.content = devguide.content ? (
      ''
    ) : (
      <FormattedMessage id="valid-content-input" />
    );

    if (devguide.title && devguide.title.length > 50) {
      temp.title = <FormattedMessage id="valid-dev-guide-title-length" />;
    }

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const onClickDeleteDevGuideFile = (e) => {
    let updateFiles = updateFileList.filter((item) => item.fileName !== e);
    setUpdateFileList(updateFiles);
    onChangedFileList('attachFileList', updateFiles);
    setDeleteFileList((prevArray) => [...prevArray, e]);
  };

  //파일 업로드
  const onChangeFileUpload = (e) => {
    var pattern = /[+|,<>?:{}]/;
    let fileUploadPattenError = false;
    const validationMsg = (
      <FormattedMessage id="valid-file-special-characters" />
    );
    setFiles([]); //파일 초기화
    const reqFileInfo = [];
    var files = [];
    for (var i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 10000000) {
        swal({
          title: intl.formatMessage({
            id: 'valid-file-size',
          }),
          text: intl.formatMessage({
            id: 'valid-file-maximum-size',
          }),
          timer: 2000,
        });
        continue;
      }

      //중복체크 시작
      let _id = e.target.files[i].name;
      let id = defaultList.find(function (v) {
        return v.fileName === _id;
      });
      //중복체크 종료

      if (id === undefined) {
        //중복이 아닌 경우
        let subData = {
          fileName: e.target.files[i].name,
          createDt: new Date(),
          updateDt: new Date(),
        };
        //특수문자 체크
        if (pattern.test(subData.fileName) === false) {
          reqFileInfo.push(subData);
        } else {
          fileUploadPattenError = true;
        }
      }

      if (fileUploadPattenError) {
        swal({
          title: intl.formatMessage({
            id: 'valid-file-upload',
          }),
          text: validationMsg,
          timer: 3000,
        });
      }
      const file = e.target.files[i];
      setFiles((prevArray) => [...prevArray, file]);
      files.push(e.target.files[i]);
    }

    //추가된 항목 배열
    var attachFileList = defaultList.concat(reqFileInfo);
    onChangedFileList('attachFileList', attachFileList);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Dialog open={form} maxWidth="md">
          <DialogTitle>
            {mode === 'create' ? (
              <FormattedMessage id="add-dev-guide" />
            ) : (
              <FormattedMessage id="edit-dev-guide" />
            )}
          </DialogTitle>
          <DialogContent>
            <MDBox>
              <FormControl>
                <MDBox>
                  <MDInput
                    autoFocus
                    id="title"
                    name="title"
                    label={<FormattedMessage id="title" />}
                    type="input"
                    variant="standard"
                    sx={{ m: 1, width: '80ch' }}
                    value={devguide.title ? devguide.title : ''}
                    onChange={onChanged}
                    required
                    {...(errors.title && {
                      error: true,
                      helperText: errors.title,
                      style: { minHeight: '1rem' },
                    })}
                  />
                  <MDBox sx={{ m: 1 }}>
                    {
                      <div>
                        <MDEditor
                          placeholder=""
                          style={{ border: 'none' }}
                          value={
                            devguide.contentHtml ? devguide.contentHtml : ''
                          }
                          onChange={(data) => {
                            onChangedContent('content', data);
                          }}
                        />
                        {errors.content && (
                          <h5
                            style={{
                              color: 'gray',
                              fontSize: '0.75rem',
                              fontWeight: '300',
                            }}
                          >
                            {errors.content}
                          </h5>
                        )}
                      </div>
                    }

                    <FileUploadCreate onChangeFileUpload={onChangeFileUpload} />
                    {errors.attachFileList && (
                      <h5
                        style={{
                          color: 'gray',
                          fontSize: '0.75rem',
                          fontWeight: '300',
                          paddingTop: '5px',
                        }}
                      >
                        {errors.attachFileList}
                      </h5>
                    )}

                    <FileUploadView
                      onClickDeleteDevGuideFile={onClickDeleteDevGuideFile}
                      updateFileList={updateFileList}
                    />
                  </MDBox>
                </MDBox>
              </FormControl>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={mode === 'create' ? handleAdd : handleUpdate}>
              <FormattedMessage id="save" />
            </MDButton>
            <MDButton onClick={handleFormClose}>
              <FormattedMessage id="cancel" />
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export function FileUploadCreate({ onChangeFileUpload }) {
  return (
    <>
      <form>
        <MDBox sx={{ marginTop: 1 }}>
          <MDButton
            component="label"
            color="info"
            variant="gradient"
            onChange={(e) => onChangeFileUpload(e)}
          >
            <FormattedMessage id="file-upload" />
            <input
              type="file"
              hidden
              multiple
              accept=".pdf,.jpg,.png,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </MDButton>
        </MDBox>
      </form>
    </>
  );
}

export function FileUploadView({ onClickDeleteDevGuideFile, updateFileList }) {
  const fileList = updateFileList;
  return (
    <>
      <MDTypography
        sx={{ color: 'text.secondary', margin: 1 }}
        variant="button"
        verticalAlign="bottom"
        fontWeight="bold"
      >
        {' '}
        <FormattedMessage id="attach-file" />{' '}
      </MDTypography>
      {fileList &&
        fileList.map((item) => (
          <Container key={item.fileName}>
            {item.fileName !== null ? (
              <MDTypography variant="button">
                {item.fileName}{' '}
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    onClickDeleteDevGuideFile(item.fileName);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </MDTypography>
            ) : (
              <></>
            )}
          </Container>
        ))}
    </>
  );
}

export default DevGuideForm;
