/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line
import { Card } from '@mui/material';
import {
  changeForm,
  changeView,
  deleteDevGuide,
  getDevGuide,
  getDevGuideDetail,
  getDevGuideList,
  getDevGuideZipDownLoad,
  pageOut,
} from 'modules/devguides';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteAlert from 'components/common/DeleteAlert';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import { updateDeleteAlertState } from '../../modules/common';

import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import OperationCell from 'components/datacell/OperationCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import { existAuthonrities } from 'lib/utils/auth';

import DevGuideView from './DevGuideView';
import { FormattedMessage } from 'react-intl';

const DevGuideBody = () => {
  const dispatch = useDispatch();
  const existWriteAuth = existAuthonrities('DEV_GUIDE_RW');
  const { data, loading, error } = useSelector(
    (state) => state.devguide.devguides,
  );
  const fetch = useSelector((state) => state.devguide.fetch);
  const [selectedId, setSelectedId] = useState(null);

  const unLoadDevGuide = useCallback(() => dispatch(pageOut()), [dispatch]);

  //useEffect Function
  const loadDevGuide = useCallback(
    () => dispatch(getDevGuideList()),
    [dispatch],
  );

  useEffect(() => {
    if (fetch) {
      loadDevGuide();
    }
    return () => {
      if (!fetch) unLoadDevGuide();
    };
  }, [loadDevGuide, unLoadDevGuide, fetch]);

  const handleFormOpen = () => {
    dispatch(changeForm({ status: true }));
  };

  //편집버튼 이벤트
  const handleUpdateFormOpen = useCallback(
    (id) => () => {
      dispatch(getDevGuide(id));
      handleFormOpen();
    },
    [dispatch],
  );

  //디테일
  const handleView = useCallback(
    (id) => () => {
      dispatch(getDevGuideDetail(id));
      dispatch(changeView({ status: true }));
    },
    [],
  );
  //삭제버튼 이벤트
  const handleDeleteAlertOpen = useCallback(
    (id) => () => {
      setSelectedId(id);
      dispatch(updateDeleteAlertState(true));
    },
    [dispatch],
  );

  const handleDownloadAlertOpen = useCallback(
    (id) => () => {
      try {
        dispatch(getDevGuideZipDownLoad(id));
      } catch (e) {
        console.log('err');
      }
    },
    [],
  );

  const handleDelete = () => {
    dispatch(deleteDevGuide(selectedId));
    dispatch(updateDeleteAlertState(false));
  };

  const DevGuideData = {
    columns: [
      {
        Header: <FormattedMessage id="title" />,
        accessor: 'title',
        width: '40%',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="user" />,
        accessor: 'userName',
        width: '15%',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value ? value : ''} />,
      },
      {
        Header: <FormattedMessage id="created-date" />,
        accessor: 'createDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="modified-date" />,
        accessor: 'updateDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="operation" />,
        accessor: 'id',
        width: '20%',
        Cell: ({ value }) => (
          <>
            <OperationCell
              enableUpdate={existWriteAuth}
              enableDelete={existWriteAuth}
              enableView={true}
              enableDownload={true}
              handleUpdateFormOpen={handleUpdateFormOpen(value)}
              handleDeleteAlertOpen={handleDeleteAlertOpen(value)}
              handleView={handleView(value)}
              handleDownloadAlertOpen={handleDownloadAlertOpen(value)}
            />
          </>
        ),
      },
    ],
    rows: data,
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <Card>
      <DataTable table={DevGuideData} entriesPerPage={false} canSearch />
      <DeleteAlert
        resource={<FormattedMessage id="dev-guide" />}
        handleDelete={handleDelete}
      />

      <DevGuideView />
    </Card>
  );
};

export default DevGuideBody;
