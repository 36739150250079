import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import MDButton from 'components/otis/MDButton';
import { getIsAdmin } from 'lib/utils/auth';
import {
  changeField,
  changeForm,
  changePrivilege,
  changeTransferList,
  postRole,
  putRole,
} from 'modules/roles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const RoleForm = () => {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.roles.form);
  const { loading, error } = useSelector((state) => state.roles.role);
  const { left, right } = useSelector((state) => state.roles.transferList);
  const role = useSelector((state) => state.roles.role.data);
  const mode = useSelector((state) => state.roles.mode);
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleFieldValidation = () => {
    let temp = {};
    temp.name = role.name ? (
      ''
    ) : (
      <FormattedMessage id="valid-role-name-input" />
    );

    if (role.name && role.name.length > 50) {
      temp.name = <FormattedMessage id="valid-role-name-length" />;
    }

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const handleFormClose = () => {
    dispatch(changeForm({ status: false }));
    setErrors({});
  };

  const handleRoleAdd = () => {
    if (handleFieldValidation()) {
      dispatch(postRole(role));
      handleFormClose();
    }
  };

  const handleRoleUpdate = () => {
    if (handleFieldValidation()) {
      dispatch(putRole(role.id, role));
      handleFormClose();
    }
  };

  const onChanged = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.value,
      }),
    );
  };

  const onChangedPrivileges = (privileges) => {
    dispatch(
      changePrivilege({
        value: privileges,
      }),
    );
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    dispatch(
      changeTransferList({
        right: right.concat(leftChecked),
        left: not(left, leftChecked),
      }),
    );
    setChecked(not(checked, leftChecked));
    onChangedPrivileges(right.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    dispatch(
      changeTransferList({
        right: not(right, rightChecked),
        left: left.concat(rightChecked),
      }),
    );
    setChecked(not(checked, rightChecked));
    onChangedPrivileges(not(right, rightChecked));
  };

  const onChangedChenckBox = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.checked,
      }),
    );
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        titleTypographyProps={{
          fontWeight: 'medium',
        }}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 250,
          height: 266,
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={value.code}
                primary={<FormattedMessage id={value.code} />}
                primaryTypographyProps={{
                  fontWeight: 'normal',
                }}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Dialog open={form} maxWidth="lg">
          <DialogTitle>
            {mode === 'create' ? (
              <FormattedMessage id="add-role" />
            ) : (
              <FormattedMessage id="edit-role" />
            )}
          </DialogTitle>
          <DialogContent>
            <Box>
              <FormControl
                variant="standard"
                sx={{ m: 2, py: 1, width: '50%' }}
              >
                <FormGroup>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      id="name"
                      name="name"
                      label={<FormattedMessage id="name" />}
                      type="text"
                      variant="standard"
                      fullWidth
                      value={role.name ? role.name : ''}
                      onChange={onChanged}
                      required
                      {...(errors.name && {
                        error: true,
                        helperText: errors.name,
                        style: { minHeight: '1rem' },
                      })}
                    />
                  </Box>
                </FormGroup>
                {getIsAdmin() && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isAdmin"
                          checked={role.isAdmin}
                          onChange={onChangedChenckBox}
                        />
                      }
                      label={<FormattedMessage id="administrator" />}
                    />
                  </FormGroup>
                )}
              </FormControl>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  sx={{
                    boxShadow: 3,
                    borderColor: 'inputBorderColor',
                    ml: 1,
                  }}
                >
                  {customList(<FormattedMessage id="role-list" />, left)}
                </Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      sx={{ my: 0.5, border: `2px solid` }}
                      variant="contained"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                      color="info"
                    >
                      &gt;
                    </Button>
                    <Button
                      sx={{ my: 0.5, border: `2px solid` }}
                      variant="contained"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                      color="info"
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    boxShadow: 3,
                    borderColor: 'inputBorderColor',
                    ml: 1,
                  }}
                >
                  {customList(<FormattedMessage id="selected-role" />, right)}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={mode === 'create' ? handleRoleAdd : handleRoleUpdate}
            >
              <FormattedMessage id="save" />
            </MDButton>
            <MDButton onClick={handleFormClose}>
              <FormattedMessage id="cancel" />
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default RoleForm;
