import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import MDEditor from 'components/otis/MDEditor';
import { changeView } from 'modules/notices';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const NoticeView = () => {
  const dispatch = useDispatch();
  const notice = useSelector((state) => state.notices.notice.data);
  const { loading, error } = useSelector((state) => state.notices.notice);
  const view = useSelector((state) => state.notices.view);

  const handleClose = () => {
    dispatch(changeView({ status: false }));
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={view}
          maxWidth="md"
          fullWidth={true}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {notice.title}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <MDEditor
              value={notice.contentHtml ? notice.contentHtml : ''}
              readOnly={true}
              modules={{
                toolbar: false,
              }}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      )}
    </div>
  );
};

export default NoticeView;
