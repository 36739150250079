import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  MenuItem,
} from '@mui/material';
import MDButton from 'components/otis/MDButton';
import MDInput from 'components/otis/MDInput';
import { updateLanguageAlertState } from 'modules/common';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const LanguageUpdate = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const [locale, setLocale] = useState(localStorage.getItem('locale') ?? 'ko');
  const languageUpdateState = useSelector(
    (state) => state.common.alert.language,
  );

  const handleChangeLocale = () => {
    try {
      localStorage.setItem('locale', locale);
      swal(
        'Success',
        intl.formatMessage({
          id: 'change-language-by-refreshing',
        }),
        'success',
        {
          buttons: false,
          timer: 2000,
        },
      ).then(() => navigate(0));
    } catch (e) {
      swal(
        'Failure',
        intl.formatMessage({
          id: 'valid-fail-change-language',
        }) +
          ' \n' +
          intl.formatMessage({
            id: 'valid-try-again',
          }),
        'failure',
        {
          buttons: false,
          timer: 2000,
        },
      );
    }
  };

  const handleLanguageUpdateAlertClose = () => {
    dispatch(updateLanguageAlertState(false));
  };

  return (
    <Dialog open={languageUpdateState} maxWidth="xs" fullWidth>
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="language-change" />
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControl variant="standard" sx={{ m: 2, width: '30inch' }}>
            <MDInput
              id="language"
              name="language"
              label={<FormattedMessage id="language" />}
              type="text"
              select
              fullWidth
              sx={{ mb: 1, mt: 1 }}
              variant="standard"
              value={locale}
              onChange={({ target: { value } }) => setLocale(value)}
            >
              <MenuItem value={'ko'}>
                <FormattedMessage id="ko" />
              </MenuItem>
              <MenuItem value={'en-US'}>
                <FormattedMessage id="en" />
              </MenuItem>
            </MDInput>
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleChangeLocale}>
          <FormattedMessage id="edit" />
        </MDButton>
        <MDButton onClick={handleLanguageUpdateAlertClose}>
          <FormattedMessage id="cancel" />
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageUpdate;
