import { Grid } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import CustomerStatus from './widgets/CustomerStatus';

const DashboardListBody = () => {
  return (
    <>
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <CustomerStatus />
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              {/* <ModuleStatus /> */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
};

export default DashboardListBody;
