import * as customer from 'lib/api/customers';
import { getModuleHistByWaitCountAndTime } from 'lib/api/modulehistories';
import { handleActions } from 'redux-actions';

const GET_COUNT_BY_CUSTOMER_ALL = 'dashboard/GET_COUNT_BY_CUSTOMER_ALL';
const GET_COUNT_BY_CUSTOMER_ALL_SUCCESS =
  'dashboard/GET_COUNT_BY_CUSTOMER_ALL_SUCCESS';
const GET_COUNT_BY_CUSTOMER_ALL_FAILURE =
  'dashboard/GET_COUNT_BY_CUSTOMER_ALL_FAILURE';

const GET_COUNT_BY_CUSTOMER_EXPIRED = 'dashboard/GET_COUNT_BY_CUSTOMER_EXPIRED';
const GET_COUNT_BY_CUSTOMER_EXPIRED_SUCCESS =
  'dashboard/GET_COUNT_BY_CUSTOMER_EXPIRED_SUCCESS';
const GET_COUNT_BY_CUSTOMER_EXPIRED_FAILURE =
  'dashboard/GET_COUNT_BY_CUSTOMER_EXPIRED_FAILURE';

const GET_COUNT_BY_CUSTOMER_INCRESE = 'dashboard/GET_COUNT_BY_CUSTOMER_INCRESE';
const GET_COUNT_BY_CUSTOMER_INCRESE_SUCCESS =
  'dashboard/GET_COUNT_BY_CUSTOMER_INCRESE_SUCCESS';
const GET_COUNT_BY_CUSTOMER_INCRESE_FAILURE =
  'dashboard/GET_COUNT_BY_CUSTOMER_INCRESE_FAILURE';
const GET_COUNT_TIME_BY_CREATE_MODULE =
  'dashboard/GET_COUNT_TIME_BY_CREATE_MODULE';
const GET_COUNT_TIME_BY_CREATE_MODULE_SUCCESS =
  'dashboard/GET_COUNT_TIME_BY_CREATE_MODULE_SUCCESS';
const GET_COUNT_TIME_BY_CREATE_MODULE_FAILURE =
  'dashboard/GET_COUNT_TIME_BY_CREATE_MODULE_FAILURE';

export const getCountByCustomerAll = () => async (dispatch) => {
  dispatch({ type: GET_COUNT_BY_CUSTOMER_ALL });
  try {
    const response = await customer.getCountByCustomerAll();
    dispatch({
      type: GET_COUNT_BY_CUSTOMER_ALL_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({
      type: GET_COUNT_BY_CUSTOMER_ALL_FAILURE,
      error: e.response,
    });
  }
};

export const getCountByCustomerExpired = () => async (dispatch) => {
  dispatch({ type: GET_COUNT_BY_CUSTOMER_EXPIRED });
  try {
    const response = await customer.getCountByCustomerExpired();
    dispatch({
      type: GET_COUNT_BY_CUSTOMER_EXPIRED_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({
      type: GET_COUNT_BY_CUSTOMER_EXPIRED_FAILURE,
      error: e.response,
    });
  }
};

export const getCountByCustomerIncrease = () => async (dispatch) => {
  dispatch({ type: GET_COUNT_BY_CUSTOMER_INCRESE });
  try {
    const response = await customer.getCountByCustomerIncrease();
    dispatch({
      type: GET_COUNT_BY_CUSTOMER_INCRESE_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({
      type: GET_COUNT_BY_CUSTOMER_INCRESE_FAILURE,
      error: e.response,
    });
  }
};

export const getCountTimeByModule = () => async (dispatch) => {
  dispatch({ type: GET_COUNT_TIME_BY_CREATE_MODULE });
  try {
    const response = await getModuleHistByWaitCountAndTime();
    dispatch({
      type: GET_COUNT_TIME_BY_CREATE_MODULE_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({
      type: GET_COUNT_TIME_BY_CREATE_MODULE_FAILURE,
      error: e.response,
    });
  }
};

const initialState = {
  customer: {
    loading: false,
    data: {
      all: 0,
      expired: 0,
      increase: 0,
    },
    error: null,
  },
  module: {
    loading: false,
    data: {
      countModule: 0,
      completeTime: 0,
    },
    error: null,
  },
};

const dashboard = handleActions(
  {
    [GET_COUNT_BY_CUSTOMER_ALL]: (state) => ({
      ...state,
      customer: {
        loading: true,
        data: state.customer.data,
        error: null,
      },
    }),
    [GET_COUNT_BY_CUSTOMER_ALL_SUCCESS]: (state, action) => ({
      ...state,
      customer: {
        loading: true,
        data: {
          ...state.customer.data,
          all: action.payload,
        },
        error: null,
      },
    }),
    [GET_COUNT_BY_CUSTOMER_ALL_FAILURE]: (state, action) => ({
      ...state,
      customer: {
        loading: false,
        data: state.customer.data,
        error: action.error,
      },
    }),
    [GET_COUNT_BY_CUSTOMER_EXPIRED]: (state) => ({
      ...state,
      customer: {
        loading: true,
        data: state.customer.data,
        error: null,
      },
    }),
    [GET_COUNT_BY_CUSTOMER_EXPIRED_SUCCESS]: (state, action) => ({
      ...state,
      customer: {
        loading: true,
        data: {
          ...state.customer.data,
          expired: action.payload,
        },
        error: null,
      },
    }),
    [GET_COUNT_BY_CUSTOMER_EXPIRED_FAILURE]: (state, action) => ({
      ...state,
      customer: {
        loading: false,
        data: state.customer.data,
        error: action.error,
      },
    }),
    [GET_COUNT_BY_CUSTOMER_INCRESE]: (state) => ({
      ...state,
      customer: {
        loading: true,
        data: state.customer.data,
        error: null,
      },
    }),
    [GET_COUNT_BY_CUSTOMER_INCRESE_SUCCESS]: (state, action) => ({
      ...state,
      customer: {
        loading: true,
        data: {
          ...state.customer.data,
          increase: action.payload,
        },
        error: null,
      },
    }),
    [GET_COUNT_BY_CUSTOMER_INCRESE_FAILURE]: (state, action) => ({
      ...state,
      customer: {
        loading: false,
        data: state.customer.data,
        error: action.error,
      },
    }),
    [GET_COUNT_TIME_BY_CREATE_MODULE]: (state) => ({
      ...state,
      module: {
        loading: true,
        data: state.module.data,
        error: null,
      },
    }),
    [GET_COUNT_TIME_BY_CREATE_MODULE_SUCCESS]: (state, action) => ({
      ...state,
      module: {
        loading: true,
        data: action.payload,
        error: null,
      },
    }),
    [GET_COUNT_TIME_BY_CREATE_MODULE_FAILURE]: (state, action) => ({
      ...state,
      module: {
        loading: false,
        data: state.module.data,
        error: action.error,
      },
    }),
  },
  initialState,
);

export default dashboard;
