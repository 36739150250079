import AddIcon from '@mui/icons-material/Add';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDTypography from 'components/otis/MDTypography';
import { existAuthonrities } from 'lib/utils/auth';
import { getCustomerList } from 'modules/customers';
import { getRoleList } from 'modules/roles';
import { changeForm, initialUser } from 'modules/users';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const UserListHeader = () => {
  const dispatch = useDispatch();
  const existWriteAuth = existAuthonrities('USER_RW');

  const handleFormOpen = () => {
    dispatch(getCustomerList());
    dispatch(getRoleList());
    dispatch(changeForm({ status: true }));
  };

  const handleAddUser = () => {
    dispatch(initialUser());
    handleFormOpen();
  };

  return (
    <MDBox>
      <MDBox
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
        }}
      >
        <MDTypography
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'text.secondary' }}
          variant="h4"
        >
          <FormattedMessage id="user" />
        </MDTypography>
        {existWriteAuth ? (
          <MDBox sx={{ m: 1 }}>
            <MDButton
              color="info"
              variant="contained"
              startIcon={<AddIcon fontSize="small" />}
              onClick={handleAddUser}
            >
              <FormattedMessage id="add-user" />
            </MDButton>
          </MDBox>
        ) : (
          <></>
        )}
      </MDBox>
    </MDBox>
  );
};

export default UserListHeader;
