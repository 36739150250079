/* eslint-disable */
import { Card } from '@mui/material';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import { ed_date, st_date } from 'components/common/SearchDateControl'; //검색 시작일자 종료일자 기본값
import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import StatusCell from 'components/datacell/StatusCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import {
  changeSearchEndDate,
  changeSearchStartDate,
  getMailHistoryList,
  pageOut,
} from 'modules/mailhistorys';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const MailHistoryBody = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.mailhistory.mailhistorys,
  );
  const fetch = useSelector((state) => state.mailhistory.fetch);

  const unLoadMailHistory = useCallback(() => dispatch(pageOut()), [dispatch]);

  const loadMailHistory = useCallback(() => {
    dispatch(getMailHistoryList(st_date, ed_date));
  }, [dispatch]);

  useEffect(() => {
    dispatch(changeSearchStartDate({ startDt: st_date }));
    dispatch(changeSearchEndDate({ endDt: ed_date }));

    if (fetch) {
      loadMailHistory();
    }
    return () => {
      if (!fetch) unLoadMailHistory();
    };
  }, [loadMailHistory, unLoadMailHistory, fetch]);

  const parsingStatus = (status) => {
    switch (status) {
      case 'WAIT':
        return <FormattedMessage id="wait" />;
      case 'SUCCESS':
        return <FormattedMessage id="success" />;
      case 'FAILURE':
        return <FormattedMessage id="failure" />;
      default:
        return null;
    }
  };
  const parsingStatusColor = (status) => {
    switch (status) {
      case 'WAIT':
        return 'primary';
      case 'SUCCESS':
        return 'success';
      case 'FAILURE':
        return 'error';
      default:
        return null;
    }
  };
  const MailHistoryData = {
    columns: [
      {
        Header: <FormattedMessage id="recipient" />,
        accessor: 'receiveUser',
        sort: true,
        width: '30%',
        Cell: ({ value }) => <DefaultCell value={value ? value : ''} />,
      },
      {
        Header: <FormattedMessage id="status" />,
        accessor: 'status',
        width: '15%',
        sort: true,
        Cell: ({ value }) => (
          <StatusCell
            value={parsingStatus(value)}
            color={parsingStatusColor(value)}
          />
        ),
      },
      {
        Header: <FormattedMessage id="message" />,
        accessor: 'message',
        width: '35%',
        sort: false,
        Cell: ({ value }) => (
          <DefaultCell value={value ? value.split(';')[0] : ''} />
        ),
      },
      {
        Header: <FormattedMessage id="created-date" />,
        accessor: 'createDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
    ],
    rows: data,
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <>
      <Card>
        <DataTable table={MailHistoryData} entriesPerPage={false} canSearch />
      </Card>
    </>
  );
};

export default MailHistoryBody;
