import NotFound from 'components/common/error/NotFound';
import ProtectedRoute from 'ProtectedRoute';
import { Navigate } from 'react-router-dom';
import ContentLayout from './components/layout/ContentLayout';
import MainLayout from './components/layout/MainLayout';
import Customer from './pages/customer/index';
import Dashboard from './pages/dashboard/index';
import DevGuide from './pages/devguide/index';
import Login from './pages/Login';
import MailHistory from './pages/mailhistory';
import Module from './pages/module/index';
import Modulehistory from './pages/modulehistory/index';
import Notice from './pages/notice/index';
import Operation from './pages/operation';
import Release from './pages/release/index';
import Role from './pages/role';
import Setting from './pages/setting/index';
import System from './pages/systemerror';
import User from './pages/user/index';

const routes = (isLoggedIn, isAdmin) => [
  {
    path: '/',
    element: !isLoggedIn ? (
      <MainLayout />
    ) : isAdmin ? (
      <Navigate to="/app/dashboard" />
    ) : (
      <Navigate to="/app/module" />
    ),
    children: [
      { path: 'login', element: <Login /> },
      { path: '/', element: <Navigate to="/login" /> },
      {
        path: '/404',
        element: <NotFound />,
        action: async ({ params, request }) => {
          console.log('a');
        },
      },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: 'app',
    element: isLoggedIn ? <ContentLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: 'dashboard',
        element: <ProtectedRoute element={<Dashboard />} auth="DASHBOARD_R" />,
      },
      {
        path: 'role',
        element: <ProtectedRoute element={<Role />} auth="ROLE_R" />,
      },
      {
        path: 'user',
        element: <ProtectedRoute element={<User />} auth="USER_R" />,
      },
      {
        path: 'module',
        element: <ProtectedRoute element={<Module />} auth="MODULE_R" />,
      },
      {
        path: 'customer',
        element: <ProtectedRoute element={<Customer />} auth="CUSTOMER_R" />,
      },
      {
        path: 'notice',
        element: <ProtectedRoute element={<Notice />} auth="NOTICE_R" />,
      },
      {
        path: 'setting',
        element: <ProtectedRoute element={<Setting />} auth="SETTING_R" />,
      },
      {
        path: 'guide',
        element: <ProtectedRoute element={<DevGuide />} auth="DEV_GUIDE_R" />,
      },
      {
        path: 'release',
        element: <ProtectedRoute element={<Release />} auth="RELEASE_R" />,
      },
      {
        path: 'module-history',
        element: (
          <ProtectedRoute element={<Modulehistory />} auth="MODULE_HIST_R" />
        ),
      },
      {
        path: 'mail-history',
        element: (
          <ProtectedRoute element={<MailHistory />} auth="MAIL_HIST_R" />
        ),
      },
      {
        path: 'system',
        element: <ProtectedRoute element={<System />} auth="SYSTEM_HIST_R" />,
      },
      {
        path: 'operation',
        element: (
          <ProtectedRoute element={<Operation />} auth="OPERATION_HIST_R" />
        ),
      },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  { path: '*', element: <NotFound /> },
];

export default routes;
