import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import DashboardListBody from './DashboardListBody';
import DashboardListHeader from './DashboardListHeader';

const Dashboard = () => {
  return (
    <>
      <MDBox component="main" my={3}>
        <Container maxWidth={false}>
          <DashboardListHeader />
          <MDBox sx={{ mt: 3 }}>
            <DashboardListBody />
          </MDBox>
        </Container>
      </MDBox>
    </>
  );
};

export default Dashboard;
