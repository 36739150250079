import AddIcon from '@mui/icons-material/Add';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDTypography from 'components/otis/MDTypography';
import { existAuthonrities } from 'lib/utils/auth';
import { changeForm, initialCustomer } from 'modules/customers';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const CustomerListHeader = () => {
  const dispatch = useDispatch();
  const existWriteAuth = existAuthonrities('CUSTOMER_RW');

  const handleFormOpen = () => {
    dispatch(changeForm({ status: true }));
  };

  const handleAddCustomer = () => {
    dispatch(initialCustomer());
    handleFormOpen();
  };

  return (
    <MDBox>
      <MDBox
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
        }}
      >
        <MDTypography
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'text.secondary' }}
          variant="h4"
        >
          {<FormattedMessage id="customer" />}
        </MDTypography>
        {existWriteAuth ? (
          <MDBox sx={{ m: 1 }}>
            <MDButton
              color="info"
              variant="gradient"
              startIcon={<AddIcon fontSize="small" />}
              onClick={handleAddCustomer}
            >
              {<FormattedMessage id="add-customer" />}
            </MDButton>
          </MDBox>
        ) : (
          <></>
        )}
      </MDBox>
    </MDBox>
  );
};

export default CustomerListHeader;
