import axios from 'axios';

export const getUserList = () => axios.get(`/api/users`);
export const getUser = (id) => axios.get(`/api/users/${id}`);
export const postUser = (user) => axios.post('/api/users', user);
export const putUser = (id, user) => axios.put(`/api/users/${id}`, user);
export const deleteUser = (id) => axios.delete(`/api/users/${id}`);
export const existEmail = (email) =>
  axios.get(`/api/users/exist/email?email=${email}`);
export const passwordUpdate = (id, password) =>
  axios.post(`/api/users/change/password/${id}`, { password: password });
export const existCustomer = (customerId) =>
  axios.get(`/api/users/exist/customer/${customerId}`);
export const existRole = (roleId) =>
  axios.get(`/api/users/exist/role/${roleId}`);
export const initialPassword = (id) =>
  axios.post(`/api/users/initial/password/${id}`);
export const initialOTP = (id) => axios.post(`/api/users/initial/otp/${id}`);
