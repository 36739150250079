/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from 'react';

// react-router-dom components
import { NavLink } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';

// Otis Admin PRO React components
import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';

// Otis Admin PRO React example components
import SidenavCollapse from './SidenavCollapse';

// Custom styles for the Sidenav
import CloseIcon from '@mui/icons-material/Close';
import SidenavRoot from './SidenavRoot';

// Otis Admin PRO React context
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from 'context';

import { FormattedMessage } from 'react-intl';
import sidenavLogoLabel from './styles/sidenav';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav,
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav,
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, transparentSidenav, whiteSidenav]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map((item, index) => {
    if (item.subItem) {
      return (
        <SidenavCollapse
          key={index}
          href={item.href}
          title={<FormattedMessage id={item.langId} />}
          icon={item.icon}
          subItem={item.subItem}
        />
      );
    } else {
      return (
        <NavLink
          key={index}
          rel="noreferrer"
          to={item.href}
          sx={{ textDecoration: 'none' }}
        >
          <SidenavCollapse
            key={index}
            href={item.href}
            title={<FormattedMessage id={item.langId} />}
            icon={item.icon}
          />
        </NavLink>
      );
    }
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={3} textAlign="center">
        <MDBox
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <MDTypography variant="h5" color="white">
            <CloseIcon />
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <MDBox
            component="img"
            src="/static/images/NS-White_symbol.png"
            alt="Brand"
            width="2rem"
          />
          <MDBox
            width={'100%'}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDBox
              mt={1}
              component="img"
              src="/static/images/NS-White_white.png"
              alt="Name"
              width="80%"
            />
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
};

export default Sidenav;
