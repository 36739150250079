import produce from 'immer';
import * as api from 'lib/api/customers';
import { createAction, handleActions } from 'redux-actions';
import * as dayjs from 'dayjs';

const PAGE_OUT = 'customers/PAGE_OUT';

const INITIAL_CUSTOMER = 'customers/INITIAL_CUSTOMER';

const CHANGE_FIELD = 'customers/CHANGE_FIELD';

const CHANGE_FORM = 'customers/CHANGE_FORM';

const GET_CUSTOMER_LIST = 'customers/GET_CUSTOMER_LIST';
const GET_CUSTOMER_LIST_SUCCESS = 'customers/GET_CUSTOMER_LIST_SUCCESS';
const GET_CUSTOMER_LIST_FAILURE = 'customers/GET_CUSTOMER_LIST_FAILURE';

const GET_CUSTOMER = 'customers/GET_CUSTOMER';
const GET_CUSTOMER_SUCCESS = 'customers/GET_CUSTOMER_SUCCESS';
const GET_CUSTOMER_FAILURE = 'customers/GET_CUSTOMER_FAILURE';

const POST_CUSTOMER = 'customers/POST_CUSTOMER';
const POST_CUSTOMER_SUCCESS = 'customers/POST_CUSTOMER_SUCCESS';
const POST_CUSTOMER_FAILURE = 'customers/POST_CUSTOMER_FAILURE';

const PUT_CUSTOMER = 'customers/PUT_CUSTOMER';
const PUT_CUSTOMER_SUCCESS = 'customers/PUT_CUSTOMER_SUCCESS';
const PUT_CUSTOMER_FAILURE = 'customers/PUT_CUSTOMER_FAILURE';

const DELETE_CUSTOMER = 'customers/DELETE_CUSTOMER';
const DELETE_CUSTOMER_SUCCESS = 'customers/DELETE_CUSTOMER_SUCCESS';
const DELETE_CUSTOMER_FAILURE = 'customers/DELETE_CUSTOMER_FAILURE';

export const pageOut = createAction(PAGE_OUT);

export const initialCustomer = createAction(INITIAL_CUSTOMER);

export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));

export const changeForm = createAction(CHANGE_FORM, ({ status }) => ({
  status,
}));

export const getCustomerList = () => async (dispatch) => {
  dispatch({ type: GET_CUSTOMER_LIST });
  try {
    const response = await api.getCustomerList();
    dispatch({ type: GET_CUSTOMER_LIST_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_CUSTOMER_LIST_FAILURE, error: e.response });
  }
};

export const getCustomer = (id) => async (dispatch) => {
  dispatch({ type: GET_CUSTOMER });
  try {
    const response = await api.getCustomer(id);
    dispatch({ type: GET_CUSTOMER_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_CUSTOMER_FAILURE, error: e.response });
  }
};

export const postCustomer = (customer) => async (dispatch) => {
  dispatch({ type: POST_CUSTOMER });
  try {
    const response = await api.postCustomer(customer);
    dispatch({
      type: POST_CUSTOMER_SUCCESS,
      payload: { id: response.data.data, name: customer.name },
    });
  } catch (e) {
    dispatch({ type: POST_CUSTOMER_FAILURE, error: e.response });
  }
};

export const putCustomer = (id, customer) => async (dispatch) => {
  dispatch({ type: PUT_CUSTOMER });
  try {
    const response = await api.putCustoemr(id, customer);
    dispatch({ type: PUT_CUSTOMER_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: PUT_CUSTOMER_FAILURE, error: e.response });
  }
};

export const deleteCustomer = (id) => async (dispatch) => {
  dispatch({ type: DELETE_CUSTOMER });
  try {
    const response = await api.deleteCustomer(id);
    dispatch({ type: DELETE_CUSTOMER_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: DELETE_CUSTOMER_FAILURE, error: e.response });
  }
};

const initialState = {
  customers: {
    loading: false,
    data: null,
    error: null,
  },
  customer: {
    loading: false,
    data: {
      name: null,
      moduleCount: 1,
      expiredStartDt: dayjs().format('YYYY-MM-DD'),
      expiredEndDt: dayjs().format('YYYY-MM-DD'),
    },
    error: null,
  },
  fetch: true,
  form: false,
  mode: 'create',
};

const customers = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [INITIAL_CUSTOMER]: (state) => ({
      ...state,
      customer: {
        data: initialState.customer.data,
      },
      mode: 'create',
    }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.customer.data[key] = value;
      }),
    [CHANGE_FORM]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.form = status;
      }),
    [GET_CUSTOMER_LIST]: (state) => ({
      ...state,
      customers: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_CUSTOMER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      customers: {
        loading: false,
        data: action.payload,
        error: null,
      },
      fetch: false,
    }),
    [GET_CUSTOMER_LIST_FAILURE]: (state, action) => ({
      ...state,
      customers: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [GET_CUSTOMER]: (state, action) => ({
      ...state,
      customer: {
        loading: true,
        data: state.customer.data,
        error: null,
      },
    }),
    [GET_CUSTOMER_SUCCESS]: (state, { payload: customer }) => ({
      ...state,
      customer: {
        loading: false,
        data: customer,
        error: null,
      },
      mode: 'update',
    }),
    [GET_CUSTOMER_FAILURE]: (state, action) => ({
      ...state,
      customer: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [POST_CUSTOMER]: (state) => ({
      ...state,
      customers: {
        loading: true,
        data: state.customers.data,
        error: null,
      },
    }),
    [POST_CUSTOMER_SUCCESS]: (state, action) => ({
      ...state,
      customers: {
        loading: false,
        data: state.customers.data.concat(action.payload),
        error: null,
      },
      fetch: true,
    }),
    [POST_CUSTOMER_FAILURE]: (state, action) => ({
      ...state,
      customers: {
        loading: false,
        data: state.customers.data,
        error: action.error,
      },
    }),
    [PUT_CUSTOMER]: (state, action) => ({
      ...state,
      customers: {
        loading: true,
        data: state.customers.data,
        error: null,
      },
    }),
    [PUT_CUSTOMER_SUCCESS]: (state, action) => ({
      ...state,
      customers: {
        loading: false,
        data: state.customers.data.map((customer) =>
          customer.id === action.payload.id ? { ...action.payload } : customer,
        ),
        error: null,
      },
      fetch: true,
    }),
    [PUT_CUSTOMER_FAILURE]: (state, action) => ({
      ...state,
      customers: {
        loading: false,
        data: state.customers.data,
        error: action.error,
      },
    }),
    [DELETE_CUSTOMER]: (state, action) => ({
      ...state,
      customers: {
        loading: false,
        data: state.customers.data,
        error: null,
      },
    }),
    [DELETE_CUSTOMER_SUCCESS]: (state, action) => ({
      ...state,
      customers: {
        loading: false,
        data: state.customers.data.filter(
          (customer) => customer.id !== action.payload,
        ),
        error: null,
      },
      fetch: true,
    }),
    [DELETE_CUSTOMER_FAILURE]: (state, action) => ({
      ...state,
      customers: {
        loading: false,
        data: state.customers.data,
        error: action.error,
      },
    }),
  },
  initialState,
);

export default customers;
