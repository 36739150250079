import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Bill from 'components/otis/Bill';
import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';
import { updateDetailAlertState } from 'modules/common';
import { useDispatch, useSelector } from 'react-redux';
import MDButton from '../otis/MDButton';
import { FormattedMessage } from 'react-intl';

const DetailAlert = (props) => {
  const { detailData, title, identity } = props;
  const dispatch = useDispatch();
  const detailAlertState = useSelector((state) => state.common.alert.detail);

  const detailAlertClose = () => {
    dispatch(updateDetailAlertState(false));
  };

  return (
    <Dialog open={detailAlertState}>
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="detail-information" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {title}
        </DialogContentText>
        {detailData ? (
          <Card id="delete-account">
            <MDBox pb={2} px={2}>
              <MDBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
              >
                <Bill keyValue={detailData} identity={identity} />
              </MDBox>
            </MDBox>
          </Card>
        ) : (
          <MDTypography
            variant="body2"
            fontWeight="medium"
            textTransform="capitalize"
          >
            <FormattedMessage id="fail-load-detail-data" /> + ' ' +
            <FormattedMessage id="valid-try-again" />
          </MDTypography>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton onClick={detailAlertClose}>
          <FormattedMessage id="check" />
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default DetailAlert;
