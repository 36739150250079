/* eslint-disable */
import { Card } from '@mui/material';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import { ed_date, st_date } from 'components/common/SearchDateControl';
import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import StatusCell from 'components/datacell/StatusCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import {
  changeSearchEndDate,
  changeSearchStartDate,
  getOperationList,
  pageOut,
} from 'modules/operations';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
const OperationBody = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.operations.operations,
  );
  const fetch = useSelector((state) => state.operations.fetch);

  const unLoadOperation = useCallback(() => dispatch(pageOut()), [dispatch]);

  const loadOperation = useCallback(() => {
    dispatch(getOperationList(st_date, ed_date));
  }, [dispatch]);

  useEffect(() => {
    dispatch(changeSearchStartDate({ startDt: st_date }));
    dispatch(changeSearchEndDate({ endDt: ed_date }));
    if (fetch) {
      loadOperation();
    }
    return () => {
      if (!fetch) unLoadOperation();
    };
  }, [loadOperation, unLoadOperation, fetch]);

  const parsingStatus = (status) => {
    switch (status) {
      case 'INSERT':
        return <FormattedMessage id="add" />;
      case 'UPDATE':
        return <FormattedMessage id="edit" />;
      case 'DELETE':
        return <FormattedMessage id="remove" />;
      case 'PASSWORD_INIT':
        return <FormattedMessage id="password-reset" />;
      case 'LOGIN':
        return <FormattedMessage id="login" />;
      default:
        return null;
    }
  };
  const parsingStatusColor = (status) => {
    switch (status) {
      case 'INSERT':
        return 'info';
      case 'UPDATE':
        return 'success';
      case 'DELETE':
        return 'error';
      case 'PASSWORD_INIT':
        return 'warning';
      case 'LOGIN':
        return 'secondary';
      default:
        return null;
    }
  };

  const OperationData = {
    columns: [
      {
        Header: <FormattedMessage id="occurrence-date" />,
        accessor: 'operationDt',
        sort: true,
        width: '10%',
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="user" />,
        accessor: 'userEmail',
        sort: true,
        width: '25%',
        Cell: ({ value }) => <DefaultCell value={value ? value : ''} />,
      },
      {
        Header: <FormattedMessage id="resource" />,
        accessor: 'resource',
        sort: true,
        width: '10%',
        Cell: ({ value }) => <DefaultCell value={value ? value : ''} />,
      },
      {
        Header: <FormattedMessage id="type" />,
        accessor: 'type',
        sort: true,
        width: '20%',
        Cell: ({ value }) => (
          <StatusCell
            value={parsingStatus(value)}
            color={parsingStatusColor(value)}
          />
        ),
      },
      {
        Header: <FormattedMessage id="contents" />,
        accessor: 'identificationValue',
        Cell: ({ value }) => <DefaultCell value={value ? value : ''} />,
      },
    ],
    rows: data,
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;
  return (
    <>
      <Card>
        <DataTable table={OperationData} entriesPerPage={false} canSearch />
      </Card>
    </>
  );
};

export default OperationBody;
