import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { updateDeleteAlertState } from 'modules/common';
import { useDispatch, useSelector } from 'react-redux';
import MDButton from '../otis/MDButton';
import { FormattedMessage } from 'react-intl';

const DeleteAlert = (props) => {
  const dispatch = useDispatch();
  const deleteAlertState = useSelector((state) => state.common.alert.delete);
  const { resource, handleDelete } = props;

  const deleteAlertClose = () => {
    dispatch(updateDeleteAlertState(false));
  };

  return (
    <Dialog open={deleteAlertState}>
      <DialogTitle id="alert-dialog-title">
        {resource} <FormattedMessage id="retry" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id="ask-delete" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton autoFocus onClick={handleDelete}>
          <FormattedMessage id="remove" />
        </MDButton>
        <MDButton onClick={deleteAlertClose}>
          <FormattedMessage id="cancel" />
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAlert;
