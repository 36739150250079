import SearchIcon from '@mui/icons-material/Search';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDInput from 'components/otis/MDInput';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';

let newDate = new Date();
export const st_date = `${newDate.getFullYear()}-${
  newDate.getMonth() + 1 < 10
    ? `0${newDate.getMonth() + 1}`
    : `${newDate.getMonth() + 1}`
}-${
  newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
}T00:00:00`;
export const ed_date = `${newDate.getFullYear()}-${
  newDate.getMonth() + 1 < 10
    ? `0${newDate.getMonth() + 1}`
    : `${newDate.getMonth() + 1}`
}-${
  newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
}T23:59:00`;

function SearchDateControl({
  onChangeSearchStartDate,
  onChangeSearchEndDate,
  onClickSearch,
  header,
  data,
  startDt,
  endDt,
  exportFileName,
}) {
  return (
    <>
      <MDBox
        sx={{
          paddingTop: 2,
        }}
      >
        <MDInput
          id="datetime-start"
          label={<FormattedMessage id="start-date" />}
          type="datetime-local"
          defaultValue={startDt}
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            onChangeSearchStartDate(e.target.value + ':00');
          }}
        />

        <MDInput
          id="datetime-end"
          label={<FormattedMessage id="end-date" />}
          type="datetime-local"
          defaultValue={endDt}
          sx={{ width: 250, marginLeft: 2, marginRight: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            onChangeSearchEndDate(e.target.value + ':59');
          }}
        />

        <MDButton
          color="info"
          variant="gradient"
          startIcon={<SearchIcon fontSize="small" />}
          sx={{ height: 43, width: 100 }}
          onClick={() => {
            onClickSearch();
          }}
        >
          <FormattedMessage id="search" />
        </MDButton>
        <CSVLink
          filename={
            exportFileName +
            '_' +
            moment().format('YYYY.MM.DD_hh.mm.ss') +
            '.csv'
          }
          data={data}
          headers={header}
        >
          <MDButton
            color="info"
            variant="gradient"
            sx={{ height: 43, width: 100, marginLeft: 2 }}
          >
            <FormattedMessage id="download" />
          </MDButton>
        </CSVLink>
      </MDBox>
    </>
  );
}

export default SearchDateControl;
