import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDInput from 'components/otis/MDInput';
import { changeSetting, putSetting } from 'modules/settings';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FormattedMessage, useIntl } from 'react-intl';

const ModuleBuild = (props) => {
  const { handleSettingChange } = props;
  const { data, error } = useSelector((state) => state.settings.settings);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [errors, setErrors] = useState({});

  const onChangedChecked = (e) => {
    dispatch(
      changeSetting({
        key: e.target.name,
        value: e.target.checked,
      }),
    );
  };

  const handleSettingSave = async () => {
    if (onValdation()) {
      dispatch(putSetting(data.retryCount));
      dispatch(putSetting(data.usedModuleCreate));
      dispatch(putSetting(data.usedModuleRetry));
      if (!error) {
        swal(
          intl.formatMessage({
            id: 'change-settings',
          }),
          intl.formatMessage({
            id: 'success-change-module-settings',
          }),
          'success',
          {
            buttons: false,
            timer: 1000,
          },
        );
      } else {
        swal(
          intl.formatMessage({
            id: 'change-settings',
          }),
          error.response.data,
          'error',
        );
      }
    }
  };

  const onValdation = () => {
    let temp = {};

    if (data.retryCount.value) {
      temp.retryCount =
        data.retryCount.value <= 10 ? (
          ''
        ) : (
          <FormattedMessage id="valid-retry-limit-count" />
        );
    } else {
      temp.retryCount = <FormattedMessage id="valid-retry-limit-input" />;
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  return (
    <Card>
      <CardHeader title={<FormattedMessage id="module-information" />} />
      <Divider />
      <CardContent>
        <MDBox display="flex" alignItems="center">
          <MDBox>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="usedModuleCreate"
                    checked={
                      data.usedModuleCreate
                        ? JSON.parse(data.usedModuleCreate.value)
                        : true
                    }
                    onChange={onChangedChecked}
                  />
                }
                label={<FormattedMessage id="module-create-function" />}
              />
            </FormGroup>
          </MDBox>
          <MDBox>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="usedModuleRetry"
                    checked={
                      data.usedModuleRetry
                        ? JSON.parse(data.usedModuleRetry.value)
                        : true
                    }
                    onChange={onChangedChecked}
                  />
                }
                label={<FormattedMessage id="module-retry-function" />}
              />
            </FormGroup>
          </MDBox>
        </MDBox>
        <MDInput
          mt={1}
          fullWidth
          type="number"
          margin="normal"
          name="retryCount"
          label={<FormattedMessage id="number-retry-limit" />}
          variant="standard"
          value={data.retryCount ? data.retryCount.value : 5}
          onChange={handleSettingChange}
          inputProps={{ min: 0, max: 10, maxLength: 2 }}
          required
          {...(errors.retryCount && {
            error: true,
            helperText: errors.retryCount,
            style: { minHeight: '1rem' },
          })}
        />
        <MDInput
          fullWidth
          label="Generate Path"
          margin="normal"
          name="generatorOutputPath"
          type="text"
          variant="standard"
          value={data.generatorOutputPath ? data.generatorOutputPath.value : ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <MDInput
          fullWidth
          label="Build Directory"
          margin="normal"
          name="buildDir"
          type="text"
          variant="standard"
          value={data.buildDir ? data.buildDir.value : ''}
          InputProps={{
            readOnly: true,
          }}
        ></MDInput>
        <MDInput
          fullWidth
          label="Release Directory"
          margin="normal"
          name="releaseDir"
          type="text"
          variant="standard"
          value={data.releaseDir ? data.releaseDir.value : ''}
          InputProps={{
            readOnly: true,
          }}
        />
      </CardContent>
      <Divider />
      <MDBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <MDButton variant="contained" onClick={handleSettingSave}>
          <FormattedMessage id="save" />
        </MDButton>
      </MDBox>
    </Card>
  );
};

export default ModuleBuild;
