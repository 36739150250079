/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from '@mui/material';
import {
  changeForm,
  changeView,
  deleteRelease,
  getRelease,
  getReleaseList,
  pageOut,
} from 'modules/releases';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteAlert from 'components/common/DeleteAlert';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import { updateDeleteAlertState } from 'modules/common';

import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import OperationCell from 'components/datacell/OperationCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import { existAuthonrities } from 'lib/utils/auth';
import { FormattedMessage } from 'react-intl';

const ReleaseBody = () => {
  const existWriteAuth = existAuthonrities('RELEASE_RW');

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.releases.releases,
  );
  const fetch = useSelector((state) => state.releases.fetch);

  const [selectedId, setSelectedId] = useState(null);

  //useEffect Function
  const unLoadRelease = useCallback(() => dispatch(pageOut()), [dispatch]);
  const loadRelease = useCallback(() => dispatch(getReleaseList()), [dispatch]);
  useEffect(() => {
    if (fetch) {
      loadRelease();
    }
    return () => {
      if (!fetch) unLoadRelease();
    };
  }, [loadRelease, unLoadRelease, fetch]);

  const handleDelete = () => {
    dispatch(deleteRelease(selectedId));
    dispatch(updateDeleteAlertState(false));
  };

  const handleFormOpen = () => {
    dispatch(changeForm({ status: true }));
  };

  const handleViewOpen = () => {
    dispatch(changeView({ status: true }));
  };

  const handleView = useCallback(
    (id) => () => {
      dispatch(getRelease(id));
      handleViewOpen();
    },
    [],
  );

  const handleUpdateFormOpen = useCallback(
    (id) => () => {
      dispatch(getRelease(id));
      handleFormOpen();
    },
    [],
  );

  const handleDeleteAlertOpen = useCallback(
    (id) => () => {
      setSelectedId(id);
      dispatch(updateDeleteAlertState(true));
    },
    [],
  );

  const ReleaseData = {
    columns: [
      {
        Header: <FormattedMessage id="title" />,
        accessor: 'title',
        width: '50%',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="created-date" />,
        accessor: 'createDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="modified-date" />,
        accessor: 'updateDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="operation" />,
        accessor: 'id',
        width: '20%',
        Cell: ({ value }) => (
          <OperationCell
            enableUpdate={existWriteAuth}
            enableDelete={existWriteAuth}
            enableView={true}
            handleUpdateFormOpen={handleUpdateFormOpen(value)}
            handleDeleteAlertOpen={handleDeleteAlertOpen(value)}
            handleView={handleView(value)}
          />
        ),
      },
    ],
    rows: data,
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <Card>
      <DataTable table={ReleaseData} entriesPerPage={false} canSearch />
      <DeleteAlert
        resource={<FormattedMessage id="release-note" />}
        handleDelete={handleDelete}
      />
    </Card>
  );
};

export default ReleaseBody;
