import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import ModulehistoryBody from './ModulehistoryBody';
import ModulehistoryHeader from './ModulehistoryHeader';

const ModuleHistory = () => {
  return (
    <MDBox component="main" my={3}>
      <Container maxWidth={false}>
        <ModulehistoryHeader />
        <MDBox sx={{ mt: 3 }}>
          <ModulehistoryBody />
        </MDBox>
      </Container>
    </MDBox>
  );
};

export default ModuleHistory;
