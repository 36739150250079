import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import * as api from '../lib/api/users';

const PAGE_OUT = 'users/PAGE_OUT';

const INITIAL_USER = 'users/INITIAL_USER';

const CHANGE_FIELD = 'users/CHANGE_FIELD';

const CHANGE_VALID = 'users/CHANGE_VALID';

const CHANGE_FORM = 'users/CHANGE_FORM';

const GET_USER_LIST = 'users/GET_USER_LIST';
const GET_USER_LIST_SUCCESS = 'users/GET_USER_LIST_SUCCESS';
const GET_USER_LIST_FAILURE = 'users/GET_USER_LIST_FAILURE';

const GET_USER = 'users/GET_USER';
const GET_USER_SUCCESS = 'users/GET_USER_SUCCESS';
const GET_USER_FAILURE = 'users/GET_USER_FAILURE';

const POST_USER = 'users/POST_USER';
const POST_USER_SUCCESS = 'users/POST_USER_SUCCESS';
const POST_USER_FAILURE = 'users/POST_USER';

const PUT_USER = 'users/PUT_USER';
const PUT_USER_SUCCESS = 'users/PUT_USER_SUCCESS';
const PUT_USER_FAILURE = 'users/PUT_USER_FAILURE';

const DELETE_USER = 'users/DELETE_USER';
const DELETE_USER_SUCCESS = 'users/DELETE_USER_SUCCESS';
const DELETE_USER_FAILURE = 'users/DELETE_USER_FAILURE';

export const pageOut = createAction(PAGE_OUT);

export const initialUser = createAction(INITIAL_USER);

export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));

export const changeValid = createAction(CHANGE_VALID, ({ key, value }) => ({
  key,
  value,
}));

export const changeForm = createAction(CHANGE_FORM, ({ status }) => ({
  status,
}));

export const getUserList = () => async (dispatch) => {
  dispatch({ type: GET_USER_LIST });
  try {
    const response = await api.getUserList();
    dispatch({ type: GET_USER_LIST_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_USER_LIST_FAILURE, error: e.response });
    throw e;
  }
};

export const getUser = (id) => async (dispatch) => {
  dispatch({ type: GET_USER });
  try {
    const response = await api.getUser(id);
    dispatch({ type: GET_USER_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_USER_FAILURE, error: e.response });
    throw e;
  }
};

export const postUser = (user) => async (dispatch) => {
  dispatch({ type: POST_USER });
  try {
    const response = await api.postUser(user);
    dispatch({ type: POST_USER_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: POST_USER_FAILURE, error: e.response });
    throw e;
  }
};

export const putUser = (id, user) => async (dispatch) => {
  dispatch({ type: PUT_USER });
  try {
    await api.putUser(id, user);
    dispatch({ type: PUT_USER_SUCCESS, payload: user });
  } catch (e) {
    dispatch({ type: PUT_USER_FAILURE, error: e.response });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  dispatch({ type: DELETE_USER });
  try {
    const response = await api.deleteUser(id);
    dispatch({ type: DELETE_USER_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: DELETE_USER_FAILURE, error: e.response });
    throw e;
  }
};

const initialState = {
  validation: {
    email: false,
  },
  users: {
    loading: false,
    data: null,
    error: null,
  },
  user: {
    loading: false,
    data: {
      email: null,
      password: null,
      isActivated: true,
      isLocked: false,
      name: null,
      roleId: null,
      roleName: null,
      customerId: null,
      customerName: null,
      department: null,
      position: null,
      phone: null,
    },
  },
  form: false,
  fetch: true,
  mode: 'create',
};

const users = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [INITIAL_USER]: (state) => ({
      ...state,
      user: {
        data: initialState.user.data,
      },
      mode: 'create',
    }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.user.data[key] = value;
      }),
    [CHANGE_VALID]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.validation[key] = value;
      }),
    [CHANGE_FORM]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.form = status;
      }),
    [GET_USER_LIST]: (state) => ({
      ...state,
      users: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_USER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      users: {
        loading: false,
        data: action.payload,
        error: null,
      },
      fetch: false,
    }),
    [GET_USER_LIST_FAILURE]: (state, action) => ({
      ...state,
      users: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [GET_USER]: (state, action) => ({
      ...state,
      user: {
        loading: true,
        data: state.user.data,
        error: null,
      },
    }),
    [GET_USER_SUCCESS]: (state, { payload: user }) => ({
      ...state,
      user: {
        loading: false,
        data: user,
        error: null,
      },
      mode: 'update',
    }),
    [GET_USER_FAILURE]: (state, action) => ({
      ...state,
      user: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [POST_USER]: (state, action) => ({
      ...state,
      users: {
        loading: true,
        data: state.users.data,
        error: null,
      },
    }),
    [POST_USER_SUCCESS]: (state, action) => ({
      ...state,
      users: {
        loading: false,
        data: state.users.data.concat(action.payload),
        error: null,
      },
      fetch: true,
    }),
    [POST_USER_FAILURE]: (state, action) => ({
      ...state,
      users: {
        loading: false,
        data: state.users.data,
        error: action.error,
      },
    }),
    [PUT_USER]: (state, action) => ({
      ...state,
      users: {
        loading: true,
        data: state.users.data,
        error: null,
      },
    }),
    [PUT_USER_SUCCESS]: (state, action) => ({
      ...state,
      users: {
        loading: false,
        data: state.users.data.map((user) =>
          user.id === action.payload.id ? { ...action.payload } : user,
        ),
        error: null,
      },
      fetch: true,
    }),
    [PUT_USER_FAILURE]: (state, action) => ({
      ...state,
      users: {
        loading: false,
        data: state.users.data,
        error: action.error,
      },
    }),
    [DELETE_USER]: (state, action) => ({
      ...state,
      users: {
        loading: false,
        data: state.users.data,
        error: null,
      },
    }),
    [DELETE_USER_SUCCESS]: (state, action) => ({
      ...state,
      users: {
        loading: false,
        data: state.users.data.filter((user) => user.id !== action.payload),
        error: null,
      },
      fetch: true,
    }),
    [DELETE_USER_FAILURE]: (state, action) => ({
      ...state,
      users: {
        loading: false,
        data: state.users.data,
        error: action.error,
      },
    }),
  },
  initialState,
);

export default users;
