import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDEditor from 'components/otis/MDEditor';
import MDInput from 'components/otis/MDInput';
import MDTypography from 'components/otis/MDTypography';
import {
  changeField,
  changeForm,
  postRelease,
  putRelease,
} from 'modules/releases';
import { FormattedMessage } from 'react-intl';

const ReleaseForm = () => {
  const dispatch = useDispatch();
  const release = useSelector((state) => state.releases.release.data);
  const { loading, error } = useSelector((state) => state.releases.release);
  const mode = useSelector((state) => state.releases.mode);
  const form = useSelector((state) => state.releases.form);
  const [errors, setErrors] = useState({});
  const handleAdd = () => {
    if (onValdation()) {
      dispatch(postRelease(release));
      handleFormClose();
    }
  };
  const handleUpdate = () => {
    if (onValdation()) {
      dispatch(putRelease(release.id, release));
      handleFormClose();
    }
  };
  const onChanged = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.value,
      }),
    );
  };
  const onChangedContent = (fieldkey, fieldvalue) => {
    dispatch(
      changeField({
        key: 'contentHtml',
        value: fieldvalue,
      }),
    );
    dispatch(
      changeField({
        key: fieldkey,
        value: window.btoa(unescape(encodeURIComponent(fieldvalue))),
      }),
    );
  };

  const handleFormClose = () => {
    dispatch(changeForm({ status: false }));
    setErrors({});
  };
  const onValdation = () => {
    let temp = {};

    temp.title = release.title ? (
      ''
    ) : (
      <FormattedMessage id="valid-subject-input" />
    );
    temp.content = release.content ? (
      ''
    ) : (
      <FormattedMessage id="valid-content-input" />
    );

    if (release.title && release.title.length > 50) {
      temp.title = <FormattedMessage id="valid-release-note-title-length" />;
    }

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Dialog open={form} maxWidth="md">
          <DialogTitle>
            {mode === 'create' ? (
              <FormattedMessage id="add-release-note" />
            ) : (
              <FormattedMessage id="edit-release-note" />
            )}
          </DialogTitle>
          <DialogContent>
            <MDBox>
              <FormControl>
                <MDInput
                  id="title"
                  name="title"
                  label={<FormattedMessage id="title" />}
                  type="text"
                  variant="standard"
                  sx={{ m: 1, width: '80ch' }}
                  value={release.title ? release.title : ''}
                  onChange={onChanged}
                  required
                  {...(errors.title && {
                    error: true,
                    helperText: errors.title,
                    style: { minHeight: '1rem' },
                  })}
                />
                <MDBox sx={{ m: 1 }}>
                  {
                    <div>
                      <MDEditor
                        value={release.contentHtml ? release.contentHtml : ''}
                        onChange={(data) => {
                          onChangedContent('content', data);
                        }}
                        placeholder=""
                        sx={{ height: '400px' }}
                      />
                      {errors.content && (
                        <MDTypography variant="caption">
                          {errors.content}
                        </MDTypography>
                      )}
                    </div>
                  }
                </MDBox>
              </FormControl>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={mode === 'create' ? handleAdd : handleUpdate}>
              <FormattedMessage id="save" />
            </MDButton>
            <MDButton onClick={handleFormClose}>
              <FormattedMessage id="cancel" />
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ReleaseForm;
