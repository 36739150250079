import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import * as api from '../lib/api/settings';

const CHANGE_SETTING = 'settings/CHANGE_SETTING';

const CHANGE_MAILBODY_HTML = 'settings/CHANGE_MAILBODY_HTML';

const GET_SETTING_LIST = 'settings/GET_SETTING_LIST';
const GET_SETTING_LIST_SUCCESS = 'settings/GET_SETTING_LIST_SUCCESS';
const GET_SETTING_LIST_FAILURE = 'settings/GET_SETTING_LIST_FAILURE';

const PUT_SETTING = 'settings/PUT_SETTING';
const PUT_SETTING_SUCCESS = 'settings/PUT_SETTING_SUCCESS';
const PUT_SETTING_FAILURE = 'settings/PUT_SETTING_FAILURE';

export const changeSetting = createAction(CHANGE_SETTING, ({ key, value }) => ({
  key,
  value,
}));

export const changeMailBodyHtml = createAction(
  CHANGE_MAILBODY_HTML,
  ({ value }) => ({
    value,
  }),
);

export const getSettingList = () => async (dispatch) => {
  dispatch({ type: GET_SETTING_LIST });
  try {
    const response = await api.getSettingList();
    dispatch({ type: GET_SETTING_LIST_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_SETTING_LIST_FAILURE, error: e.response });
    throw e;
  }
};

export const putSetting = (setting) => async (dispatch) => {
  dispatch({ type: PUT_SETTING });
  try {
    const response = await api.putSetting(setting);
    dispatch({ type: PUT_SETTING_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: PUT_SETTING_FAILURE, error: e.response });
    throw e;
  }
};

const initialState = {
  settings: {
    loading: false,
    data: {
      generatorOutputPath: null,
      buildDir: null,
      releaseDir: null,
      mailTitle: null,
      mailBody: null,
      mailBodyHtml: null,
      usedModuleCreate: null,
      usedSendModuleInfo: null,
      usedModuleRetry: null,
      retryCount: null,
    },
    error: null,
  },
};

const settings = handleActions(
  {
    [CHANGE_SETTING]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.settings.data[key].value = value;
      }),
    [CHANGE_MAILBODY_HTML]: (state, { payload: { value } }) =>
      produce(state, (draft) => {
        draft.settings.data.mailBodyHtml = value;
      }),
    [GET_SETTING_LIST]: (state) => ({
      ...state,
      settings: {
        loading: true,
        data: initialState.settings.data,
        error: null,
      },
    }),
    [GET_SETTING_LIST_SUCCESS]: (state, action) => ({
      ...state,
      settings: {
        loading: false,
        data: {
          generatorOutputPath: action.payload.filter(
            (s) => s.name === 'generatorOutputPath',
          )[0],
          buildDir: action.payload.filter((s) => s.name === 'buildDir')[0],
          releaseDir: action.payload.filter((s) => s.name === 'releaseDir')[0],
          mailTitle: action.payload.filter((s) => s.name === 'mailTitle')[0],
          mailBody: action.payload.filter((s) => s.name === 'mailBody')[0],
          mailBodyHtml: decodeURIComponent(
            escape(
              window.atob(
                action.payload.filter((s) => s.name === 'mailBody')[0].value,
              ),
            ),
          ),
          usedModuleCreate: action.payload.filter(
            (s) => s.name === 'usedModuleCreate',
          )[0],
          usedSendModuleInfo: action.payload.filter(
            (s) => s.name === 'usedSendModuleInfo',
          )[0],
          usedModuleRetry: action.payload.filter(
            (s) => s.name === 'usedModuleRetry',
          )[0],
          retryCount: action.payload.filter((s) => s.name === 'retryCount')[0],
        },
        error: null,
      },
    }),
    [GET_SETTING_LIST_FAILURE]: (state, action) => ({
      ...state,
      settings: {
        loading: false,
        data: state.settings.data,
        error: action.error,
      },
    }),
    [PUT_SETTING]: (state) => ({
      ...state,
      settings: {
        loading: true,
        data: state.settings.data,
        error: null,
      },
    }),
    [PUT_SETTING_SUCCESS]: (state, action) => ({
      ...state,
      settings: {
        loading: false,
        data: state.settings.data,
        error: null,
      },
    }),
    [PUT_SETTING_FAILURE]: (state, action) => ({
      ...state,
      settings: {
        loading: false,
        data: state.settings.data,
        error: action.error,
      },
    }),
  },
  initialState,
);

export default settings;
