import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import DevGuideForm from './DevGuideForm';
import DevGuideListBody from './DevGuideListBody';
import DevGuideListHeader from './DevGuideListHeader';

const DevGuide = () => {
  return (
    <>
      <MDBox component="main" my={3}>
        <Container maxWidth={false}>
          <DevGuideListHeader />
          <MDBox sx={{ mt: 3 }}>
            <DevGuideListBody />
          </MDBox>
        </Container>
      </MDBox>
      <DevGuideForm />
    </>
  );
};

export default DevGuide;
