import axios from 'axios';

export const getDevGuideList = () => axios.get(`/api/guides`);

export const getDevGuide = (id) => axios.get(`/api/guides/${id}`);

export const getDevGuideFileDownLoad = (fileName, id) =>
  axios({
    url: '/api/guides/download/' + id + '?fileName=' + fileName,
    method: 'GET',
    responseType: 'blob',
  });

export const deleteDevGuide = (id) => axios.delete(`/api/guides/${id}`);

export const putDevGuide = (id, formData) =>
  axios.put(`/api/guides/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const deleteDevGuideFile = (fileName, id) =>
  axios.delete(`/api/guides/attachFile/${id}?fileName=${fileName}`);

export const postDevGuide = (formData) =>
  axios.post(`/api/guides`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const postDevGuideUpload = (guildFile) =>
  axios.post(`/api/guides/upload`, guildFile, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const downloadDevGuideZip = (id) =>
  axios({
    url: '/api/guides/download/' + id + '/all',
    method: 'GET',
    responseType: 'blob',
  });
