import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import React from 'react';
import NoticeForm from './NoticeForm';
import NoticeView from './NoticeView';
import NoticeListBody from './NoticeListBody';
import NoticeListHeader from './NoticeListHeader';

const notice = () => {
  return (
    <>
      <MDBox component="main" my={3}>
        <Container maxWidth={false}>
          <NoticeListHeader />
          <MDBox sx={{ mt: 3 }}>
            <NoticeListBody />
          </MDBox>
        </Container>
      </MDBox>
      <NoticeForm />
      <NoticeView />
    </>
  );
};

export default notice;
