import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
} from '@mui/material';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import MDButton from 'components/otis/MDButton';
import MDInput from 'components/otis/MDInput';
import { existEmail, initialOTP, initialPassword } from 'lib/api/users';
import { validPasswordRule } from 'lib/utils/password';
import {
  changeField,
  changeForm,
  changeValid,
  postUser,
  putUser,
} from 'modules/users';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FormattedMessage, useIntl } from 'react-intl';

const UserForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector((state) => state.users.user.data);
  const { loading, error } = useSelector((state) => state.users.user);
  const mode = useSelector((state) => state.users.mode);
  const form = useSelector((state) => state.users.form);
  const customers = useSelector((state) => state.customers.customers.data);
  const roles = useSelector((state) => state.roles.roles.data);
  const validEmail = useSelector((state) => state.users.validation.email);
  const passwordRef = useRef();
  const otpEnabled = useSelector((status) => status.common.otpEnabled);

  const [errors, setErrors] = useState({});
  const [passwordConfirmValue, setPasswordConfirmValue] = useState('');

  const handleFormClose = () => {
    dispatch(changeForm({ status: false }));
    setPasswordConfirmValue('');
    setErrors({});
  };

  const handleAdd = (e) => {
    if (onValdation()) {
      dispatch(postUser(user));
      handleFormClose();
    }
  };

  const handleUpdate = (e) => {
    if (onValdation()) {
      dispatch(putUser(user.id, user));
      handleFormClose();
    }
  };

  const onChanged = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.value,
      }),
    );
  };

  const onChangedEmail = (e) => {
    setErrors({ ...errors, email: '' });
    dispatch(changeValid({ key: 'email', value: false }));
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.value,
      }),
    );
  };

  const onChangedChenckMDBox = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.checked,
      }),
    );
  };

  const onChangedPasswordConfirm = (e) => {
    setPasswordConfirmValue(e.target.value);
  };

  const onPasswordExpressionValidation = () => {
    setErrors({ ...errors, password: validPasswordRule(user.password) });
  };

  const onPasswordValidation = (temp) => {
    if (mode !== 'update') {
      if (!user.password) {
        temp.password = <FormattedMessage id="valid-password-input" />;
      }
      if (!passwordConfirmValue) {
        temp.passwordConfirm = <FormattedMessage id="valid-password-input" />;
      } else {
        if (user.password !== passwordConfirmValue) {
          temp.passwordConfirm = (
            <FormattedMessage id="valid-password-different" />
          );
        } else {
          temp.password = validPasswordRule(user.password);
          temp.passwordConfirm = '';
        }
      }
    }
    return temp;
  };

  const handleInitializedPassword = async () => {
    try {
      await initialPassword(user.id);
      swal(
        intl.formatMessage({
          id: 'success-initialized',
        }),
        intl.formatMessage({
          id: 'success-issue-temporary-password',
        }),
        'success',
        {
          buttons: false,
          timer: 2000,
        },
      );
      handleFormClose();
    } catch (error) {
      swal('Failed', error.response.data, 'error');
    }
  };

  const handleInitializedOTP = async () => {
    try {
      await initialOTP(user.id);
      swal(
        intl.formatMessage({
          id: 'success-initialized',
        }),
        intl.formatMessage({
          id: 'success-issue-new-otp-code',
        }),
        'success',
        {
          buttons: false,
          timer: 2000,
        },
      );
      handleFormClose();
    } catch (error) {
      swal('Failed', error.response.data, 'error');
    }
  };

  const handleExistEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!user.email) {
      setErrors({
        ...errors,
        email: <FormattedMessage id="valid-email-input" />,
      });
    } else if (!regex.test(user.email)) {
      setErrors({
        ...errors,
        email: <FormattedMessage id="valid-email-expression" />,
      });
    } else if (!validEmail) {
      existEmail(user.email).then((res) => {
        if (!res.data.data) {
          swal(
            intl.formatMessage({
              id: 'valid-email',
            }),
            intl.formatMessage({
              id: 'valid-email-available',
            }),
            'success',
            {
              buttons: false,
              timer: 2000,
            },
          ).then((value) => {
            setErrors({ ...errors, email: '' });
            dispatch(changeValid({ key: 'email', value: true }));
          });
        } else {
          setErrors({
            ...errors,
            email: <FormattedMessage id="valid-email-usage" />,
          });
          dispatch(changeValid({ key: 'email', value: false }));
        }
      });
    }
  };

  const onEmailValidation = (temp) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!user.email) {
      temp.email = <FormattedMessage id="valid-email-input" />;
      return temp;
    } else if (!regex.test(user.email)) {
      temp.email = <FormattedMessage id="valid-email-expression" />;
      return temp;
    } else if (mode !== 'update' && !validEmail) {
      temp.email = <FormattedMessage id="valid-email-duplication" />;
    }

    return temp;
  };

  const onValdation = () => {
    let temp = {};

    temp = onEmailValidation(temp);

    temp = onPasswordValidation(temp);

    temp.customerId = user.customerId ? (
      ''
    ) : (
      <FormattedMessage id="valid-customer-select" />
    );
    temp.roleId = user.roleId ? (
      ''
    ) : (
      <FormattedMessage id="valid-role-select" />
    );

    temp.name = user.name ? '' : <FormattedMessage id="valid-name-input" />;

    if (user.name && user.name.length > 15) {
      temp.name = <FormattedMessage id="valid-user-name-length" />;
    }

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Dialog open={form} maxWidth="md" fullWidth>
          <DialogTitle>
            {mode === 'create' ? (
              <FormattedMessage id="add-user" />
            ) : (
              <FormattedMessage id="edit-user" />
            )}
          </DialogTitle>
          <DialogContent>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <form>
                    <FormControl sx={{ width: '40ch' }}>
                      <FormLabel>
                        <FormattedMessage id="account-information" />
                      </FormLabel>
                      <FormGroup>
                        <Box>
                          <MDInput
                            sx={{ width: '23ch', mt: 1 }}
                            id="email"
                            name="email"
                            label={<FormattedMessage id="email" />}
                            type="email"
                            variant="standard"
                            value={user.email ? user.email : ''}
                            onChange={onChangedEmail}
                            required
                            inputProps={{
                              readOnly: mode !== 'create' ? true : false,
                            }}
                            {...(errors.email && {
                              error: true,
                              helperText: errors.email,
                              style: { minHeight: '1rem' },
                            })}
                          />
                          {mode === 'create' && (
                            <MDButton
                              color="info"
                              variant="contained"
                              sx={{ mt: 2, ml: 1 }}
                              onClick={handleExistEmail}
                              size="small"
                            >
                              <FormattedMessage id="valid-email" />
                            </MDButton>
                          )}
                        </Box>
                        {mode === 'create' && (
                          <FormControl sx={{ width: '300px' }}>
                            <MDInput
                              id="password"
                              name="password"
                              label={<FormattedMessage id="password" />}
                              type="password"
                              variant="standard"
                              sx={{ mt: 1 }}
                              autoComplete="off"
                              value={user.password ? user.password : ''}
                              inputRef={passwordRef}
                              onChange={onChanged}
                              onKeyUp={onPasswordExpressionValidation}
                              required
                              {...(errors.password && {
                                error: true,
                                helperText: errors.password,
                                style: { minHeight: '1rem' },
                              })}
                            />
                            <MDInput
                              id="password_confirm"
                              name="password_confirm"
                              label={<FormattedMessage id="password-confirm" />}
                              type="password"
                              autoComplete="off"
                              variant="standard"
                              sx={{ mt: 1 }}
                              value={passwordConfirmValue}
                              onChange={onChangedPasswordConfirm}
                              InputLabelProps={{
                                style: { lineHeight: 2 },
                              }}
                              InputProps={{
                                readOnly: !user.password,
                              }}
                              required
                              {...(errors.passwordConfirm && {
                                error: true,
                                helperText: errors.passwordConfirm,
                                style: { minHeight: '1rem' },
                              })}
                            />
                          </FormControl>
                        )}
                        <Box sx={{ mt: 1, ml: 1 }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isActivated"
                                  checked={user.isActivated}
                                  onChange={onChangedChenckMDBox}
                                />
                              }
                              label={<FormattedMessage id="activated" />}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isLocked"
                                  checked={user.isLocked}
                                  onChange={onChangedChenckMDBox}
                                />
                              }
                              label={<FormattedMessage id="locked" />}
                            />
                          </FormGroup>
                        </Box>
                        {mode === 'update' && (
                          <Box sx={{ mt: 1 }}>
                            <MDButton
                              color="info"
                              variant="contained"
                              onClick={handleInitializedPassword}
                              size="small"
                              sx={{ width: '130px' }}
                            >
                              <FormattedMessage id="password-reset" />
                            </MDButton>
                          </Box>
                        )}
                        {mode === 'update' && otpEnabled && (
                          <Box sx={{ mt: 1 }}>
                            <MDButton
                              color="info"
                              variant="contained"
                              onClick={handleInitializedOTP}
                              size="small"
                              sx={{ width: '130px' }}
                            >
                              <FormattedMessage id="otp-reset" />
                            </MDButton>
                          </Box>
                        )}
                      </FormGroup>
                    </FormControl>
                  </form>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <FormLabel component="legend">
                      <FormattedMessage id="basic-information" />
                    </FormLabel>
                    <FormGroup>
                      <FormControl>
                        <MDInput
                          id="name"
                          name="name"
                          label={<FormattedMessage id="name" />}
                          type="text"
                          variant="standard"
                          sx={{ mt: 1 }}
                          value={user.name ? user.name : ''}
                          onChange={onChanged}
                          required
                          {...(errors.name && {
                            error: true,
                            helperText: errors.name,
                            style: { minHeight: '1rem' },
                          })}
                        />
                      </FormControl>
                      <FormControl>
                        <MDInput
                          id="department"
                          name="department"
                          label={<FormattedMessage id="department" />}
                          type="text"
                          variant="standard"
                          sx={{ mt: 1 }}
                          value={user.department ? user.department : ''}
                          onChange={onChanged}
                        />
                      </FormControl>
                      <FormControl>
                        <MDInput
                          id="position"
                          name="position"
                          label={<FormattedMessage id="position" />}
                          type="text"
                          variant="standard"
                          sx={{ mt: 1 }}
                          value={user.position ? user.position : ''}
                          onChange={onChanged}
                        />
                      </FormControl>
                      <FormControl>
                        <MDInput
                          id="phone"
                          name="phone"
                          label={<FormattedMessage id="phone" />}
                          type="tel"
                          variant="standard"
                          sx={{ mt: 1 }}
                          value={user.phone ? user.phone : ''}
                          onChange={onChanged}
                        />
                      </FormControl>
                      <FormControl>
                        <MDInput
                          id="customerId"
                          name="customerId"
                          label={<FormattedMessage id="customer" />}
                          type="text"
                          variant="standard"
                          select
                          sx={{ mt: 1 }}
                          value={user.customerId ? user.customerId : ''}
                          onChange={onChanged}
                          {...(errors.customerId && {
                            helperText: errors.customerId,
                            style: { minHeight: '1rem' },
                          })}
                        >
                          {customers
                            ? customers.map((c) => (
                                <MenuItem key={c.id} value={c.id}>
                                  {c.name}
                                </MenuItem>
                              ))
                            : []}
                        </MDInput>
                      </FormControl>
                      <FormControl>
                        <MDInput
                          id="roleId"
                          name="roleId"
                          label={<FormattedMessage id="role" />}
                          type="text"
                          variant="standard"
                          sx={{ mt: 1 }}
                          value={user.roleId ? user.roleId : ''}
                          onChange={onChanged}
                          select
                          {...(errors.roleId && {
                            helperText: errors.roleId,
                            style: { minHeight: '1rem' },
                          })}
                        >
                          {roles
                            ? roles.map((c) => (
                                <MenuItem key={c.id} value={c.id}>
                                  {c.name}
                                </MenuItem>
                              ))
                            : []}
                        </MDInput>
                      </FormControl>
                    </FormGroup>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={mode === 'create' ? handleAdd : handleUpdate}>
              <FormattedMessage id="save" />
            </MDButton>
            <MDButton onClick={handleFormClose}>
              <FormattedMessage id="cancel" />
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UserForm;
