import axios from 'axios';

export const getCustomerList = () => axios.get(`/api/customers`);
export const getCustomer = (id) => axios.get(`/api/customers/${id}`);
export const postCustomer = (customer) =>
  axios.post('/api/customers', customer);
export const putCustoemr = (id, customer) =>
  axios.put(`/api/customers/${id}`, customer);
export const deleteCustomer = (id) => axios.delete(`/api/customers/${id}`);

export const getCountByCustomerAll = (status) =>
  axios.get(`/api/customers/count/all`);

export const getCountByCustomerExpired = (status) =>
  axios.get(`/api/customers/count/expired`);

export const getCountByCustomerIncrease = () =>
  axios.get(`/api/customers/count/increase`);
