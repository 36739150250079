import { combineReducers } from 'redux';
import auth from './auth';
import common from './common';
import customers from './customers';
import dashboard from './dashboard';
import devguide from './devguides';
import mailhistory from './mailhistorys';
import modulehistories from './modulehistories';
import modules from './modules';
import notices from './notices';
import operations from './operations';
import releases from './releases';
import roles from './roles';
import settings from './settings';
import systemerrors from './systemerrors';
import users from './users';

const rootReducer = combineReducers({
  users,
  common,
  modules,
  customers,
  notices,
  settings,
  devguide,
  releases,
  modulehistories,
  mailhistory,
  systemerrors,
  operations,
  roles,
  auth,
  dashboard,
});

export default rootReducer;
