import produce from 'immer';
import * as api from 'lib/api/releases';
import { createAction, handleActions } from 'redux-actions';

const PAGE_OUT = 'releases/PAGE_OUT';

const INITIAL_RELEASE = 'releases/INITIAL_RELEASE';

const CHANGE_FIELD = 'releases/CHANGE_FIELD';
const CHANGE_FORM = 'releases/CHANGE_FORM';
const CHANGE_VIEW = 'releases/CHANGE_VIEW';
const GET_RELEASE_LIST = 'releases/GET_RELEASE_LIST';
const GET_RELEASE_LIST_SUCCESS = 'releases/GET_RELEASE_LIST_SUCCESS';
const GET_RELEASE_LIST_FAILURE = 'releases/GET_RELEASE_LIST_FAILURE';

const GET_RELEASE = 'releases/GET_RELEASE';
const GET_RELEASE_SUCCESS = 'releases/GET_RELEASE_SUCCESS';
const GET_RELEASE_FAILURE = 'releases/GET_RELEASE_FAILURE';

const POST_RELEASE = 'releases/POST_RELEASE';
const POST_RELEASE_SUCCESS = 'releases/POST_RELEASE_SUCCESS';
const POST_RELEASE_FAILURE = 'releases/POST_RELEASE_FAILURE';

const PUT_RELEASE = 'releases/PUT_RELEASE';
const PUT_RELEASE_SUCCESS = 'releases/PUT_RELEASE_SUCCESS';
const PUT_RELEASE_FAILURE = 'releases/PUT_RELEASE_FAILURE';

const DELETE_RELEASE = 'releases/DELETE_RELEASE';
const DELETE_RELEASE_SUCCESS = 'releases/DELETE_RELEASE_SUCCESS';
const DELETE_RELEASE_FAILURE = 'releases/DELETE_RELEASE_FAILURE';

export const pageOut = createAction(PAGE_OUT);

export const initialRelease = createAction(INITIAL_RELEASE);

export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));

export const changeForm = createAction(CHANGE_FORM, ({ status }) => ({
  status,
}));

export const changeView = createAction(CHANGE_VIEW, ({ status }) => ({
  status,
}));

export const getReleaseList = () => async (dispatch) => {
  dispatch({ type: GET_RELEASE_LIST });
  try {
    const response = await api.getReleaseList();
    dispatch({ type: GET_RELEASE_LIST_SUCCESS, payload: response.data.data });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_RELEASE_LIST_FAILURE, error: e.response });
  }
};

export const getRelease = (id) => async (dispatch) => {
  dispatch({ type: GET_RELEASE });
  try {
    const response = await api.getRelease(id);
    dispatch({ type: GET_RELEASE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_RELEASE_FAILURE, error: e.response });
  }
};

export const postRelease = (release) => async (dispatch) => {
  dispatch({ type: POST_RELEASE });
  try {
    const response = await api.postRelease(release);
    dispatch({
      type: POST_RELEASE_SUCCESS,
      payload: { id: response.data.data },
    });
  } catch (e) {
    dispatch({ type: POST_RELEASE_FAILURE, error: e.response });
  }
};

export const putRelease = (id, release) => async (dispatch) => {
  dispatch({ type: PUT_RELEASE });
  try {
    const response = await api.putRelease(id, release);
    dispatch({ type: PUT_RELEASE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: PUT_RELEASE_FAILURE, error: e });
  }
};

export const deleteRelease = (id) => async (dispatch) => {
  dispatch({ type: DELETE_RELEASE });
  try {
    const response = await api.deleteRelease(id);
    dispatch({ type: DELETE_RELEASE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: DELETE_RELEASE_FAILURE, error: e });
  }
};

const initialState = {
  releases: {
    loading: false,
    data: null,
    error: null,
  },
  release: {
    loading: false,
    data: {
      id: null,
      userId: null,
      title: null,
      content: null,
      contentHtml: null,
    },
    error: null,
  },
  fetch: true,
  form: false,
  view: false,
  mode: 'create',
};

const releases = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [INITIAL_RELEASE]: (state) => ({
      ...state,
      release: {
        data: initialState.release.data,
      },
      mode: 'create',
    }),
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.release.data[key] = value;
      }),
    [CHANGE_FORM]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.form = status;
      }),
    [CHANGE_VIEW]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.view = status;
      }),
    [GET_RELEASE_LIST]: (state) => ({
      ...state,
      releases: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_RELEASE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      releases: {
        loading: false,
        data: action.payload,
        error: null,
      },
      fetch: false,
    }),
    [GET_RELEASE_LIST_FAILURE]: (state, action) => ({
      ...state,
      releases: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [GET_RELEASE]: (state, action) => ({
      ...state,
      release: {
        loading: true,
        data: state.release.data,
        error: null,
      },
    }),
    [GET_RELEASE_SUCCESS]: (state, { payload: release }) => ({
      ...state,
      release: {
        loading: false,
        data: {
          ...release,
          contentHtml: decodeURIComponent(escape(window.atob(release.content))),
        },
        error: null,
      },
      mode: 'update',
    }),
    [GET_RELEASE_FAILURE]: (state, action) => ({
      ...state,
      release: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [POST_RELEASE]: (state) => ({
      ...state,
      releases: {
        loading: true,
        data: state.releases.data,
        error: null,
      },
    }),
    [POST_RELEASE_SUCCESS]: (state, action) => ({
      ...state,
      releases: {
        loading: false,
        data: state.releases.data.concat(action.payload),
        error: null,
      },
      totalCount: state.totalCount + 1,
      fetch: true,
    }),
    [POST_RELEASE_FAILURE]: (state, action) => ({
      ...state,
      releases: {
        loading: false,
        data: state.releases.data,
        error: action.payload,
      },
    }),
    [PUT_RELEASE]: (state, action) => ({
      ...state,
      releases: {
        loading: true,
        data: state.releases.data,
        error: null,
      },
    }),
    [PUT_RELEASE_SUCCESS]: (state, action) => ({
      ...state,
      releases: {
        loading: false,
        data: state.releases.data.map((release) =>
          release.id === action.payload.id ? { ...action.payload } : release,
        ),
        error: null,
      },
      fetch: true,
    }),
    [PUT_RELEASE_FAILURE]: (state, action) => ({
      ...state,
      releases: {
        loading: false,
        data: state.releases.data,
        error: action.error,
      },
    }),
    [DELETE_RELEASE]: (state, action) => ({
      ...state,
      releases: {
        loading: false,
        data: state.releases.data,
        error: null,
      },
    }),
    [DELETE_RELEASE_SUCCESS]: (state, action) => ({
      ...state,
      releases: {
        loading: false,
        data: state.releases.data.filter(
          (release) => release.id !== action.payload,
        ),
        error: null,
      },
      fetch: true,
    }),
    [DELETE_RELEASE_FAILURE]: (state, action) => ({
      ...state,
      releases: {
        loading: false,
        data: state.releases.data,
        error: action.error,
      },
    }),
  },

  initialState,
);

export default releases;
