import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton } from '@mui/material';
import MDBox from 'components/otis/MDBox';

function DownloadCell({
  enableDownload,
  enableDelete,
  handleDownloadAlertOpen,
  handleDeleteAlertOpen,
}) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox ml={1}>
        {enableDownload && (
          <IconButton
            aria-label="download"
            size="small"
            onClick={handleDownloadAlertOpen}
          >
            <FileDownloadIcon fontSize="small" />
          </IconButton>
        )}
        &nbsp;
        {enableDelete && (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleDeleteAlertOpen}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </MDBox>
    </MDBox>
  );
}

export default DownloadCell;
