import produce from 'immer';
import * as privilege from 'lib/api/privileges';
import * as api from 'lib/api/roles';
import { createAction, handleActions } from 'redux-actions';

const PAGE_OUT = 'roles/PAGE_OUT';

const GET_ROLE_LIST = 'roles/GET_ROLE_LIST';
const GET_ROLE_LIST_SUCCESS = 'roles/GET_ROLE_LIST_SUCCESS';
const GET_ROLE_LIST_FAILURE = 'roles/GET_ROLE_LIST_FAILURE';

const POST_ROLE = 'roles/POST_ROLE';
const POST_ROLE_SUCCESS = 'roles/POST_ROLE_SUCCESS';
const POST_ROLE_FAILURE = 'roles/POST_ROLE_FAILURE';

const GET_ROLE = 'roles/GET_ROLE';
const GET_ROLE_SUCCESS = 'roles/GET_ROLE_SUCCESS';
const GET_ROLE_FAILURE = 'roles/GET_ROLE_FAILURE';

const PUT_ROLE = 'roles/PUT_ROLE';
const PUT_ROLE_SUCCESS = 'roles/PUT_ROLE_SUCCESS';
const PUT_ROLE_FAILURE = 'roles/PUT_ROLE_FAILURE';

const DELETE_ROLE = 'roles/DELETE_ROLE';
const DELETE_ROLE_SUCCESS = 'roles/DELETE_ROLE_SUCCESS';
const DELETE_ROLE_FAILURE = 'roles/DELETE_ROLE_FAILURE';

const INITIAL_ROLE = 'roles/INITIAL_ROLE';

const CHANGE_FORM = 'roles/CHANGE_FORM';

const CHANGE_FIELD = 'roles/CHANGE_FIELD';

const CHANGE_TRANSFERLIST = 'roles/CHANGE_TRANSFERLIST';

const CHANGE_PRIVILEGE = 'roles/CHANGE_PRIVILEGE';

const CHANGE_STATE = 'roles/CHANGE_STATE';

const GET_PRIVILEGE_LIST = 'roles/GET_PRIVILEGE_LIST';
const GET_PRIVILEGE_LIST_SUCCESS = 'roles/GET_PRIVILEGE_LIST_SUCCESS';
const GET_PRIVILEGE_LIST_FAILURE = 'roles/GET_PRIVILEGE_LIST_FAILURE';

export const pageOut = createAction(PAGE_OUT);

export const initialRole = createAction(INITIAL_ROLE);

export const changeForm = createAction(CHANGE_FORM, ({ status }) => ({
  status,
}));

export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));

export const changeTransferList = createAction(
  CHANGE_TRANSFERLIST,
  ({ right, left }) => ({
    right,
    left,
  }),
);

export const changePrivilege = createAction(CHANGE_PRIVILEGE, ({ value }) => ({
  value,
}));

export const changeState = createAction(CHANGE_STATE, ({ key, value }) => ({
  key,
  value,
}));

export const getRoleList = () => async (dispatch) => {
  dispatch({ type: GET_ROLE_LIST });
  try {
    const response = await api.getRoleList();
    dispatch({ type: GET_ROLE_LIST_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_ROLE_LIST_FAILURE, error: e.response });
  }
};

export const getRole = (id) => async (dispatch) => {
  dispatch({ type: GET_ROLE });
  try {
    const response = await api.getRole(id);
    dispatch({ type: GET_ROLE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_ROLE_FAILURE, error: e.response });
  }
};

export const postRole = (role) => async (dispatch) => {
  dispatch({ type: POST_ROLE });
  try {
    const response = await api.postRole(role);
    dispatch({
      type: POST_ROLE_SUCCESS,
      payload: { id: response.data.data, name: role.name },
    });
  } catch (e) {
    dispatch({ type: POST_ROLE_FAILURE, error: e });
  }
};

export const putRole = (id, role) => async (dispatch) => {
  dispatch({ type: PUT_ROLE });
  try {
    await api.putRole(id, role);
    dispatch({
      type: PUT_ROLE_SUCCESS,
      payload: { role },
    });
  } catch (e) {
    dispatch({ type: PUT_ROLE_FAILURE, error: e });
  }
};

export const deleteRole = (id) => async (dispatch) => {
  dispatch({ type: DELETE_ROLE });
  try {
    const response = await api.deleteRole(id);
    dispatch({ type: DELETE_ROLE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: DELETE_ROLE_FAILURE, error: e });
  }
};

export const getPrivilegeList = () => async (dispatch) => {
  dispatch({ type: GET_PRIVILEGE_LIST });
  try {
    const response = await privilege.getPrivilegeList();
    dispatch({ type: GET_PRIVILEGE_LIST_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_PRIVILEGE_LIST_FAILURE, error: e.response });
  }
};

const initialState = {
  roles: {
    loading: false,
    data: null,
    error: null,
  },
  role: {
    loading: false,
    data: {
      name: null,
      isAdmin: false,
      rolePrivileges: [],
    },
    error: null,
  },
  privileges: {
    loading: false,
    data: null,
    error: null,
  },
  transferList: {
    left: [],
    right: [],
  },
  fetch: true,
  form: false,
  mode: 'create',
};

const roles = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [INITIAL_ROLE]: (state) => ({
      ...state,
      role: initialState.role,
      transferList: {
        left: state.privileges.data,
        right: [],
      },
      mode: 'create',
    }),
    [CHANGE_FORM]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.form = status;
      }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.role.data[key] = value;
      }),
    [CHANGE_PRIVILEGE]: (state, { payload: { value } }) =>
      produce(state, (draft) => {
        draft.role.data.rolePrivileges = [];
        value.map((list, index) => {
          return (draft.role.data.rolePrivileges[index] = {
            privilege: list,
          });
        });
      }),
    [CHANGE_TRANSFERLIST]: (state, { payload: { right, left } }) =>
      produce(state, (draft) => {
        draft.transferList.right = right;
        draft.transferList.left = left;
      }),
    [CHANGE_STATE]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft[key] = value;
      }),
    [GET_ROLE_LIST]: (state) => ({
      ...state,
      roles: {
        loading: true,
        data: null,
        error: null,
      },
      fetch: false,
    }),
    [GET_ROLE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      roles: {
        loading: false,
        data: action.payload,
        error: null,
      },
      fetch: false,
    }),
    [GET_ROLE_LIST_FAILURE]: (state, action) => ({
      ...state,
      roles: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [GET_ROLE]: (state) => ({
      ...state,
      role: {
        loading: true,
        data: state.role.data,
        error: null,
      },
    }),
    [GET_ROLE_SUCCESS]: (state, action) => ({
      ...state,
      role: {
        loading: false,
        data: action.payload,
        error: null,
      },
      transferList: {
        right: action.payload.rolePrivileges.map(
          (rolePrivileges) => rolePrivileges.privilege,
        ),
        left: state.privileges.data.filter(
          (left) =>
            !action.payload.rolePrivileges
              .map((rolePrivilege) => rolePrivilege.privilege.id)
              .includes(left.id),
        ),
      },
      mode: 'update',
    }),
    [GET_ROLE_FAILURE]: (state, action) => ({
      ...state,
      role: {
        loading: false,
        data: state.role.data,
        error: action.error,
      },
    }),
    [POST_ROLE]: (state) => ({
      ...state,
      roles: {
        loading: true,
        data: state.roles.data,
        error: null,
      },
    }),
    [POST_ROLE_SUCCESS]: (state, action) => ({
      ...state,
      roles: {
        loading: false,
        data: state.roles.data.concat(action.payload),
        error: null,
      },
      fetch: true,
    }),
    [POST_ROLE_FAILURE]: (state, action) => ({
      ...state,
      roles: {
        loading: false,
        data: state.roles.data,
        error: action.error,
      },
    }),
    [PUT_ROLE]: (state) => ({
      ...state,
      roles: {
        loading: true,
        data: state.roles.data,
        error: null,
      },
    }),
    [PUT_ROLE_SUCCESS]: (state, action) => ({
      ...state,
      roles: {
        loading: false,
        data: state.roles.data.map((role) =>
          role.id === action.payload.id ? { ...action.payload } : role,
        ),
        error: null,
      },
      fetch: true,
    }),
    [PUT_ROLE_FAILURE]: (state, action) => ({
      ...state,
      roles: {
        loading: false,
        data: state.roles.data,
        error: action.error,
      },
    }),
    [DELETE_ROLE]: (state) => ({
      ...state,
      roles: {
        loading: true,
        data: state.roles.data,
        error: null,
      },
    }),
    [DELETE_ROLE_SUCCESS]: (state, action) => ({
      ...state,
      roles: {
        loading: false,
        data: state.roles.data.filter((role) => role.id !== action.payload),
        error: null,
      },
      fetch: true,
    }),
    [DELETE_ROLE_FAILURE]: (state, action) => ({
      ...state,
      roles: {
        loading: false,
        data: state.roles.data,
        error: action.error,
      },
    }),
    [GET_PRIVILEGE_LIST]: (state) => ({
      ...state,
      privileges: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_PRIVILEGE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      privileges: {
        loading: true,
        data: action.payload,
        error: null,
      },
      fetch: false,
    }),
    [GET_PRIVILEGE_LIST_FAILURE]: (state, action) => ({
      ...state,
      privileges: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
  },
  initialState,
);

export default roles;
