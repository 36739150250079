import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container, Link } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDTypography from 'components/otis/MDTypography';

const NotFound = () => {
  return (
    <>
      <MDBox
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%',
          mt: 5,
        }}
      >
        <Container maxWidth="md">
          <MDBox
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MDTypography align="center" variant="h2">
              404: The page you are looking for isn’t here
            </MDTypography>
            <MDTypography align="center" variant="subtitle2">
              You either tried some shady route or you came here by mistake.
              Whichever it is, try using the navigation
            </MDTypography>
            <MDBox sx={{ textAlign: 'center' }}>
              <img
                alt="Under development"
                src="/static/images/not_found.png"
                style={{
                  marginTop: 50,
                  display: 'inline-block',
                  maxWidth: '100%',
                  width: 450,
                }}
              />
            </MDBox>
            <Link href="/">
              <MDButton
                startIcon={<ArrowBackIcon fontSize="small" />}
                sx={{ mt: 3 }}
                variant="contained"
                color="info"
              >
                Go back to Main
              </MDButton>
            </Link>
          </MDBox>
        </Container>
      </MDBox>
    </>
  );
};

export default NotFound;
