import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Card,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import bgImage from 'assets/images/bg-default.jpeg';
import Footer from 'components/footer';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDInput from 'components/otis/MDInput';
import MDTypography from 'components/otis/MDTypography';
import { useFormik } from 'formik';
import { getSettingOTP, getSettingTokenSilent } from 'lib/api/settings';
import {
  getIsAdmin,
  setAccessToken,
  setExpiresAt,
  setRefreshToken,
} from 'lib/utils/auth';
import { updateCommonState } from 'modules/common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import * as Yup from 'yup';
import moment from '../../node_modules/moment/moment';
import { loginUser } from '../lib/api/auth';
import { FormattedMessage, useIntl } from 'react-intl';

const Login = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const otpEnabled = useSelector((state) => state.common.otpEnabled);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      verificationCode: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(
          intl.formatMessage({
            id: 'valid-email-expression',
          }),
        )
        .max(255)
        .required(
          intl.formatMessage({
            id: 'valid-email-input',
          }),
        ),
      password: Yup.string()
        .max(255)
        .required(
          intl.formatMessage({
            id: 'valid-password-input',
          }),
        ),
      verificationCode: (() => {
        let validation = Yup.string();
        if (otpEnabled) {
          validation = validation
            .max(
              6,
              intl.formatMessage({
                id: 'valid-authentication-number-length',
              }),
            )
            .min(
              6,
              intl.formatMessage({
                id: 'valid-authentication-number-length',
              }),
            )
            .required(
              intl.formatMessage({
                id: 'valid-otp-input',
              }),
            );
        }
        return validation;
      })(),
    }),
    onSubmit: async (values) => {
      try {
        const response = await loginUser(values);
        swal(
          intl.formatMessage({
            id: 'success',
          }),
          intl.formatMessage({
            id: 'login-success',
          }),
          'success',
          {
            buttons: false,
            timer: 2000,
          },
        ).then((value) => {
          console.clear();
          setAccessToken(response.data.data.accessToken);
          setRefreshToken(response.data.data.refreshToken);
          setExpiresAt(moment().add(10, 'minutes').unix());
          if (getIsAdmin()) {
            navigate('/app/dashboard', { replace: true });
          } else {
            navigate('/app/module', { replace: true });
          }
        });
      } catch (error) {
        if (error.response.status === 504) {
          swal(
            intl.formatMessage({
              id: 'fail-communication-server',
            }),
            intl.formatMessage({
              id: 'fail-response-server',
            }) +
              '\n' +
              intl.formatMessage({
                id: 'valid-contact-admin',
              }),
            'error',
          );
        } else {
          swal(
            intl.formatMessage({
              id: 'failure',
            }),
            error.response.data,
            'error',
          );
        }
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getUsedOTP = useCallback(async () => {
    try {
      const response = await getSettingOTP();
      dispatch(
        updateCommonState({ key: 'otpEnabled', value: response.data.data }),
      );
    } catch (error) {
      throw error;
    }
  }, [dispatch]);

  const getUsedTokenSilent = useCallback(async () => {
    try {
      const response = await getSettingTokenSilent();
      dispatch(
        updateCommonState({ key: 'tokenSilent', value: response.data.data }),
      );
    } catch (error) {
      throw error;
    }
  }, [dispatch]);

  useEffect(() => {
    getUsedOTP();
    getUsedTokenSilent();
  }, [getUsedOTP, getUsedTokenSilent]);

  return (
    <>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6),
            )}, url(${bgImage})`,
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto" pr={0} pl={0}>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <form onSubmit={formik.handleSubmit}>
                  <MDBox sx={{ my: 1 }}>
                    <MDTypography variant="h4" align="center" gutterBottom>
                      NSHC Key Box
                    </MDTypography>
                    <MDTypography gutterBottom variant="body2" align="center">
                      Sign in on the keybox management system
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={1}>
                    <MDInput
                      error={Boolean(
                        formik.touched.email && formik.errors.email,
                      )}
                      fullWidth
                      helperText={formik.touched.email && formik.errors.email}
                      label={<FormattedMessage id="email" />}
                      margin="normal"
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="email"
                      value={formik.values.email}
                      variant="outlined"
                    />
                  </MDBox>
                  <MDBox mb={1}>
                    <MDInput
                      error={Boolean(
                        formik.touched.password && formik.errors.password,
                      )}
                      fullWidth
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      label={<FormattedMessage id="password" />}
                      margin="normal"
                      name="password"
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="password"
                      value={formik.values.password}
                      variant="outlined"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    {otpEnabled && (
                      <FormControl
                        sx={{ width: '100%', mt: 1 }}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          <FormattedMessage id="package-name" />
                        </InputLabel>
                        <OutlinedInput
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label={
                            <FormattedMessage id="otp-authentication-code" />
                          }
                          name="verificationCode"
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type={showPassword ? 'text' : 'password'}
                          value={formik.values.verificationCode}
                          variant="outlined"
                          error={Boolean(
                            formik.touched.verificationCode &&
                              formik.errors.verificationCode,
                          )}
                        />
                        {formik.touched.verificationCode &&
                          formik.errors.verificationCode && (
                            <FormHelperText error id="accountId-error">
                              {formik.errors.verificationCode}
                            </FormHelperText>
                          )}
                      </FormControl>
                    )}
                  </MDBox>
                  <MDBox mt={3} mb={1}>
                    <MDButton
                      fullWidth
                      color="info"
                      size="large"
                      variant="gradient"
                      type="submit"
                    >
                      <FormattedMessage id="login" />
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Footer />
      </MDBox>
    </>
  );
};

export default Login;
