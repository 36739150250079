import produce from 'immer';
import * as api from 'lib/api/modulehistories';
import { createAction, handleActions } from 'redux-actions';

const PAGE_OUT = 'modulehistory/PAGE_OUT';

const INITIAL_MODULEHISOTRY = 'modulehistory/INITIAL_MODULEHISOTRY';

const CHANGE_FIELD = 'modulehistory/CHANGE_FIELD';
const CHANGE_VIEW = 'modulehistory/CHANGE_VIEW';

const CHANGE_SEARCH_FIELD_STARTDATE =
  'modulehistory/CHANGE_SEARCH_FIELD_STARTDATE';
const CHANGE_SEARCH_FIELD_ENDDATE = 'modulehistory/CHANGE_SEARCH_FIELD_ENDDATE';

const GET_MODULEHISOTRY_LIST = 'modulehistory/GET_MODULEHISOTRY_LIST';
const GET_MODULEHISOTRY_LIST_SUCCESS =
  'modulehistory/GET_MODULEHISOTRY_LIST_SUCCESS';
const GET_MODULEHISOTRY_LIST_FAILURE =
  'modulehistory/GET_MODULEHISOTRY_LIST_FAILURE';

const GET_MODULEHISOTRY = 'modulehistory/GET_MODULEHISOTRY';
const GET_MODULEHISOTRY_SUCCESS = 'modulehistory/GET_MODULEHISOTRY_SUCCESS';
const GET_MODULEHISOTRY_FAILURE = 'modulehistory/GET_MODULEHISOTRY_FAILURE';

export const pageOut = createAction(PAGE_OUT);

export const initialModuleHisotry = createAction(INITIAL_MODULEHISOTRY);

export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));

export const changeView = createAction(CHANGE_VIEW, ({ status }) => ({
  status,
}));

export const changeSearchStartDate = createAction(
  CHANGE_SEARCH_FIELD_STARTDATE,
  ({ startDt }) => ({
    startDt,
  }),
);

export const changeSearchEndDate = createAction(
  CHANGE_SEARCH_FIELD_ENDDATE,
  ({ endDt }) => ({
    endDt,
  }),
);

export const getModulehistories = (startDt, endDt) => async (dispatch) => {
  dispatch({ type: GET_MODULEHISOTRY_LIST });
  try {
    const response = await api.getModulehistories(startDt, endDt);
    dispatch({
      type: GET_MODULEHISOTRY_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({ type: GET_MODULEHISOTRY_LIST_FAILURE, error: e.response });
  }
};

export const getModulehistoryByModuleId = (moduleId) => async (dispatch) => {
  dispatch({ type: GET_MODULEHISOTRY });
  try {
    const response = await api.getModulehistoryByModuleId(moduleId);
    dispatch({
      type: GET_MODULEHISOTRY_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({ type: GET_MODULEHISOTRY_FAILURE, error: e.response });
  }
};

const initialState = {
  search: {
    startDt: null,
    endDt: null,
  },
  modulehistories: {
    loading: false,
    data: null,
    error: null,
  },
  modulehistory: {
    loading: false,
    data: {
      id: null,
      moduleId: null,
      createUserName: null,
      algorithm: null,
      operationMode: null,
      createOs: null,
      // createOperation: null,
      elementOperation: null,
      status: null,
      packageName: null,
      retry: null,
      createDt: null,
      updateDt: null,
    },
    error: null,
  },
  fetch: true,
  form: false,
  view: false,
  mode: 'create',
};

const modulehistories = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [INITIAL_MODULEHISOTRY]: (state) => ({
      ...state,
      modulehistory: {
        data: initialState.modulehistory.data,
      },
      mode: 'create',
    }),
    [CHANGE_SEARCH_FIELD_STARTDATE]: (state, { payload: { startDt } }) =>
      produce(state, (draft) => {
        draft.search.startDt = startDt;
      }),
    [CHANGE_SEARCH_FIELD_ENDDATE]: (state, { payload: { endDt } }) =>
      produce(state, (draft) => {
        draft.search.endDt = endDt;
      }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.modulehistory.data[key] = value;
      }),
    [CHANGE_VIEW]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.view = status;
      }),
    [GET_MODULEHISOTRY_LIST]: (state) => ({
      ...state,
      modulehistories: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_MODULEHISOTRY_LIST_SUCCESS]: (state, action) => ({
      ...state,
      modulehistories: {
        loadin: false,
        data: action.payload.map((history) => {
          return {
            ...history,
            retry: {
              id: history.id,
              status: history.status,
            },
          };
        }),
        error: null,
      },
      fetch: false,
    }),
    [GET_MODULEHISOTRY_LIST_FAILURE]: (state, action) => ({
      ...state,
      modulehistories: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [GET_MODULEHISOTRY]: (state) => ({
      ...state,
      modulehistory: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_MODULEHISOTRY_SUCCESS]: (state, { payload: moduleHist }) => ({
      ...state,
      modulehistory: {
        loading: false,
        data: moduleHist,
        error: null,
      },
      fetch: false,
    }),
    [GET_MODULEHISOTRY_FAILURE]: (state, action) => ({
      ...state,
      modulehistory: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
  },

  initialState,
);

export default modulehistories;
