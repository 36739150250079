import { Card } from '@mui/material';
import DeleteAlert from 'components/common/DeleteAlert';
import DownloadAlert from 'components/common/DownloadAlert';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import DownloadCell from 'components/datacell/DownloadCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import { getModulehistoryByModuleId } from 'lib/api/modulehistories';
import { downloadModule } from 'lib/api/modules';
import { existAuthonrities } from 'lib/utils/auth';
import {
  parseAlgorithm,
  parseCreateOperation,
  parseCreateOs,
  parseElementOperation,
  parseModuleStatus,
} from 'lib/utils/parse/module';
import {
  updateDeleteAlertState,
  updateDownloadAlertState,
} from 'modules/common';
import { deleteModule, getModuleList, pageOut } from 'modules/modules';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FormattedMessage, useIntl } from 'react-intl';

const ModuleListBody = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { data, loading, error } = useSelector(
    (state) => state.modules.modules,
  );
  const fetch = useSelector((state) => state.modules.fetch);
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const existWriteAuth = existAuthonrities('MODULE_RW');

  const unLoadModule = useCallback(() => dispatch(pageOut()), [dispatch]);

  const loadModule = useCallback(() => dispatch(getModuleList()), [dispatch]);

  useEffect(() => {
    if (fetch) {
      loadModule();
    }
    return () => {
      if (!fetch) unLoadModule();
    };
  }, [loadModule, unLoadModule, fetch]);

  const handleDeleteAlertOpen = useCallback(
    (id) => () => {
      setSelectedModuleId(id);
      dispatch(updateDeleteAlertState(true));
    },
    [dispatch],
  );

  const handleDelete = () => {
    dispatch(deleteModule(selectedModuleId));
    dispatch(updateDeleteAlertState(false));
  };

  const handleDownloadAlertOpen = useCallback(
    (id) => () => {
      setSelectedModuleId(id);
      dispatch(updateDownloadAlertState(true));
    },
    [dispatch],
  );

  const handleDownload = () => {
    getModulehistoryByModuleId(selectedModuleId)
      .then((response) => {
        const data = response.data.data;
        if (data.status === 'SUCCESS') {
          downloadModule(selectedModuleId)
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', data.packageName + '.zip');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              dispatch(updateDownloadAlertState(false));
            })
            .catch((e) => {
              swal(
                intl.formatMessage({
                  id: 'download-failure',
                }),
                `${new TextDecoder().decode(e.response.data)} \n` +
                  intl.formatMessage({ id: 'valid-contact-admin' }),
                'error',
              ).then((value) => {
                dispatch(updateDownloadAlertState(false));
              });
            });
        } else {
          if (data.status === 'FAILURE') {
            swal(
              intl.formatMessage({ id: 'valid-module-fail-created' }),
              intl.formatMessage({ id: 'valid-module-fail-message' }),
            ).then((value) => {
              dispatch(updateDownloadAlertState(false));
            });
          } else {
            swal(
              intl.formatMessage({ id: 'valid-module-created-progress' }),
              intl.formatMessage({ id: 'status-module' }) +
                ` : ${parseModuleStatus(data.status)} \n` +
                intl.formatMessage({ id: 'valid-try-again' }),
            ).then((value) => {
              dispatch(updateDownloadAlertState(false));
            });
          }
        }
      })
      .catch((e) => {
        swal(
          intl.formatMessage({ id: 'fail-module-import' }),
          e.response.data,
          'error',
          {
            buttons: false,
            timer: 2000,
          },
        ).then((value) => {
          dispatch(updateDownloadAlertState(false));
        });
      });
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const ModuleData = {
    columns: [
      {
        Header: <FormattedMessage id="package-name" />,
        accessor: 'packageName',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="algorithm" />,
        accessor: 'algorithm',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={parseAlgorithm(value)} />,
      },
      {
        Header: <FormattedMessage id="operation-mode" />,
        accessor: 'operationMode',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: 'OS',
        accessor: 'createOs',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={parseCreateOs(value)} />,
      },
      // {
      //   Header: <FormattedMessage id="encrypt-decrypt" />,
      //   accessor: 'createOperation',
      //   sort: true,
      //   Cell: ({ value }) => (
      //     <DefaultCell value={parseCreateOperation(value)} />
      //   ),
      // },
      {
        Header: <FormattedMessage id="element-operation" />,
        accessor: 'elementOperation',
        sort: true,
        Cell: ({ value }) => (
          <DefaultCell value={parseElementOperation(value)} />
        ),
      },
      {
        Header: <FormattedMessage id="created-date" />,
        accessor: 'createDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="user" />,
        accessor: 'userName',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="operation" />,
        accessor: 'id',
        sort: false,
        Cell: ({ value }) => (
          <DownloadCell
            enableDownload={existWriteAuth}
            enableDelete={existWriteAuth}
            handleDownloadAlertOpen={handleDownloadAlertOpen(value)}
            handleDeleteAlertOpen={handleDeleteAlertOpen(value)}
          />
        ),
      },
    ],
    rows: data,
  };

  return (
    <Card>
      <DataTable table={ModuleData} entriesPerPage={false} canSearch />
      <DeleteAlert
        resource={<FormattedMessage id="module" />}
        handleDelete={handleDelete}
      />
      <DownloadAlert handleDownload={handleDownload} />
    </Card>
  );
};

export default ModuleListBody;
