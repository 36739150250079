import DashboardIcon from '@mui/icons-material/Dashboard';
import ArchiveIcon from '@mui/icons-material/Archive';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StoreIcon from '@mui/icons-material/Store';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BookIcon from '@mui/icons-material/Book';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import HistoryIcon from '@mui/icons-material/History';
import MailIcon from '@mui/icons-material/Mail';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

export const MenuItemData = [
  {
    href: '/app/dashboard',
    title: 'Dashboard',
    langId: 'dashboard',
    auth: 'DASHBOARD_R',
    icon: <DashboardIcon />,
  },
  {
    title: '모듈',
    auth: 'MODULE_R',
    icon: <ArchiveIcon />,
    langId: 'module',
    subItem: [
      {
        href: '/app/customer',
        title: '고객사 관리',
        auth: 'CUSTOMER_R',
        icon: <StoreIcon />,
        langId: 'customer',
      },
      {
        href: '/app/role',
        title: '역할 관리',
        auth: 'ROLE_R',
        icon: <ManageAccountsIcon />,
        langId: 'role',
      },
      {
        href: '/app/user',
        title: '사용자 관리',
        auth: 'USER_R',
        icon: <PersonIcon />,
        langId: 'user',
      },
      {
        href: '/app/module',
        title: '모듈 관리',
        auth: 'MODULE_R',
        icon: <ArchiveIcon />,
        langId: 'module',
      },
    ],
  },

  {
    title: '게시판',
    auth: 'NOTICE_R',
    langId: 'board',
    icon: <SpeakerNotesIcon />,
    subItem: [
      {
        href: '/app/notice',
        title: '공지사항',
        auth: 'NOTICE_R',
        langId: 'notice',
        icon: <SpeakerNotesIcon />,
      },
      {
        href: '/app/release',
        title: '릴리즈 노트',
        auth: 'RELEASE_R',
        langId: 'release-note',
        icon: <BookIcon />,
      },
      {
        href: '/app/guide',
        title: '개발 가이드',
        auth: 'DEV_GUIDE_R',
        langId: 'dev-guide',
        icon: <MenuBookIcon />,
      },
    ],
  },

  {
    title: '이력',
    auth: 'MODULE_HIST_R',
    langId: 'history',
    icon: <HistoryEduIcon />,
    subItem: [
      {
        href: '/app/module-history',
        title: '모듈 이력',
        auth: 'MODULE_HIST_R',
        langId: 'module-hist',
        icon: <HistoryEduIcon />,
      },
      {
        href: '/app/mail-history',
        title: '메일 이력',
        auth: 'MAIL_HIST_R',
        langId: 'mail-hist',
        icon: <MailIcon />,
      },
      {
        href: '/app/operation',
        title: '운영 이력',
        auth: 'OPERATION_HIST_R',
        langId: 'operation-hist',
        icon: <HistoryIcon />,
      },
      {
        href: '/app/system',
        title: '시스템 이력',
        auth: 'SYSTEM_HIST_R',
        langId: 'system-hist',
        icon: <AssignmentIcon />,
      },
    ],
  },
  {
    href: '/app/setting',
    title: '환경설정',
    auth: 'SETTING_R',
    langId: 'setting',
    icon: <SettingsIcon />,
  },
];
