/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from '@mui/material';
import DeleteAlert from 'components/common/DeleteAlert';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import OperationCell from 'components/datacell/OperationCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import { existRole } from 'lib/api/users';
import { existAuthonrities } from 'lib/utils/auth';
import { updateDeleteAlertState } from 'modules/common';
import {
  changeForm,
  deleteRole,
  getPrivilegeList,
  getRole,
  getRoleList,
  pageOut,
} from 'modules/roles';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FormattedMessage, useIntl } from 'react-intl';

const RoleListBody = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { data, loading, error } = useSelector((state) => state.roles.roles);
  const fetch = useSelector((state) => state.roles.fetch);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const existWriteAuth = existAuthonrities('ROLE_RW');

  const unLoadRole = useCallback(() => dispatch(pageOut()), [dispatch]);

  const loadRole = useCallback(() => dispatch(getRoleList()), [dispatch]);

  const loadPrivilege = useCallback(() => dispatch(getPrivilegeList()));

  const handleFormOpen = () => {
    dispatch(changeForm({ status: true }));
  };

  const handleUpdateFormOpen = useCallback(
    (id) => () => {
      dispatch(getRole(id));
      handleFormOpen();
    },
    [],
  );

  const handleDelete = (event, id) => {
    dispatch(deleteRole(selectedUserId));
    dispatch(updateDeleteAlertState(false));
  };

  const handleDeleteAlertOpen = useCallback(
    (id) => async () => {
      setSelectedUserId(id);
      try {
        const response = await existRole(id);
        if (!response.data.data) {
          dispatch(updateDeleteAlertState(true));
        } else {
          swal(
            'Failed',
            intl.formatMessage({
              id: 'valid-fail-delete-usage-role',
            }),
            'error',
          );
        }
      } catch (error) {
        swal('Failed', error.response.data, 'error');
      }
    },
    [],
  );

  useEffect(() => {
    if (fetch) {
      loadPrivilege();
      loadRole();
    }
    return () => {
      if (!fetch) unLoadRole();
    };
  }, [loadRole, unLoadRole, fetch]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const RoleData = {
    columns: [
      {
        Header: <FormattedMessage id="name" />,
        accessor: 'name',
        sort: false,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="created-date" />,
        accessor: 'createDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="modified-date" />,
        accessor: 'updateDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="edit-remove" />,
        accessor: 'id',
        sort: false,
        Cell: ({ value }) => (
          <OperationCell
            enableUpdate={existWriteAuth}
            enableDelete={existWriteAuth}
            handleUpdateFormOpen={handleUpdateFormOpen(value)}
            handleDeleteAlertOpen={handleDeleteAlertOpen(value)}
          />
        ),
      },
    ],
    rows: data,
  };
  return (
    <Card>
      <DataTable table={RoleData} entriesPerPage={false} canSearch />
      <DeleteAlert
        resource={<FormattedMessage id="role" />}
        handleDelete={handleDelete}
      />
    </Card>
  );
};

export default RoleListBody;
