import produce from 'immer';
import * as api from 'lib/api/notices';
import { createAction, handleActions } from 'redux-actions';

const PAGE_OUT = 'notices/PAGE_OUT';

const INITIAL_NOTICE = 'notices/INITIAL_NOTICE';

const CHANGE_FIELD = 'notices/CHANGE_FIELD';
const CHANGE_FORM = 'notices/CHANGE_FORM';
const CHANGE_VIEW = 'notices/CHANGE_VIEW';

const GET_NOTICE_LIST = 'notices/GET_NOTICE_LIST';
const GET_NOTICE_LIST_SUCCESS = 'notices/GET_NOTICE_LIST_SUCCESS';
const GET_NOTICE_LIST_FAILURE = 'notices/GET_NOTICE_LIST_FAILURE';

const GET_NOTICE = 'notices/GET_NOTICE';
const GET_NOTICE_SUCCESS = 'notices/GET_NOTICE_SUCCESS';
const GET_NOTICE_FAILURE = 'notices/GET_NOTICE_FAILURE';

const POST_NOTICE = 'notices/POST_NOTICE';
const POST_NOTICE_SUCCESS = 'notices/POST_NOTICE_SUCCESS';
const POST_NOTICE_FAILURE = 'notices/POST_NOTICE_FAILURE';

const PUT_NOTICE = 'notices/PUT_NOTICE';
const PUT_NOTICE_SUCCESS = 'notices/PUT_NOTICE_SUCCESS';
const PUT_NOTICE_FAILURE = 'notices/PUT_NOTICE_FAILURE';

const DELETE_NOTICE = 'notices/DELETE_NOTICE';
const DELETE_NOTICE_SUCCESS = 'notices/DELETE_NOTICE_SUCCESS';
const DELETE_NOTICE_FAILURE = 'notices/DELETE_NOTICE_FAILURE';

export const pageOut = createAction(PAGE_OUT);

export const initialNotice = createAction(INITIAL_NOTICE);

export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));

export const changeForm = createAction(CHANGE_FORM, ({ status }) => ({
  status,
}));

export const changeView = createAction(CHANGE_VIEW, ({ status }) => ({
  status,
}));

export const getNoticeList = () => async (dispatch) => {
  dispatch({ type: GET_NOTICE_LIST });
  try {
    const response = await api.getNoticeList();
    dispatch({ type: GET_NOTICE_LIST_SUCCESS, payload: response.data.data });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_NOTICE_LIST_FAILURE, error: e.response });
  }
};

export const getNotice = (id) => async (dispatch) => {
  dispatch({ type: GET_NOTICE });
  try {
    const response = await api.getNotice(id);
    dispatch({ type: GET_NOTICE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_NOTICE_FAILURE, error: e.response });
  }
};

export const postNotice = (notice) => async (dispatch) => {
  dispatch({ type: POST_NOTICE });
  try {
    const response = await api.postNotice(notice);
    dispatch({
      type: POST_NOTICE_SUCCESS,
      payload: { id: response.data.data },
    });
  } catch (e) {
    dispatch({ type: POST_NOTICE_FAILURE, error: e.response });
  }
};

export const putNotice = (id, notice) => async (dispatch) => {
  dispatch({ type: PUT_NOTICE });
  try {
    const response = await api.putNotice(id, notice);
    dispatch({ type: PUT_NOTICE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: PUT_NOTICE_FAILURE, error: e });
  }
};

export const deleteNotice = (id) => async (dispatch) => {
  dispatch({ type: DELETE_NOTICE });
  try {
    const response = await api.deleteNotice(id);
    dispatch({ type: DELETE_NOTICE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: DELETE_NOTICE_FAILURE, error: e });
  }
};

const initialState = {
  notices: {
    loading: false,
    data: null,
    error: null,
  },
  notice: {
    loading: false,
    data: {
      id: null,
      userId: null,
      title: null,
      content: null,
      contentHtml: null,
      isEmergency: false,
      alertStartDt: null,
      alertEndDt: null,
    },
    error: null,
  },
  fetch: true,
  form: false,
  view: false,
  mode: 'create',
};

const notices = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [INITIAL_NOTICE]: (state) => ({
      ...state,
      notice: {
        data: initialState.notice.data,
      },
      mode: 'create',
    }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.notice.data[key] = value;
      }),
    [CHANGE_FORM]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.form = status;
      }),
    [CHANGE_VIEW]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.view = status;
      }),
    [GET_NOTICE_LIST]: (state) => ({
      ...state,
      notices: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_NOTICE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      notices: {
        loading: false,
        data: action.payload,
        error: null,
      },
      fetch: false,
    }),
    [GET_NOTICE_LIST_FAILURE]: (state, action) => ({
      ...state,
      notices: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [GET_NOTICE]: (state, action) => ({
      ...state,
      notice: {
        loading: true,
        data: state.notice.data,
        error: null,
      },
    }),
    [GET_NOTICE_SUCCESS]: (state, { payload: notice }) => ({
      ...state,
      notice: {
        loading: false,
        data: {
          ...notice,
          contentHtml: decodeURIComponent(escape(window.atob(notice.content))),
        },
        error: null,
      },
      mode: 'update',
    }),
    [GET_NOTICE_FAILURE]: (state, action) => ({
      ...state,
      notice: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [POST_NOTICE]: (state) => ({
      ...state,
      notices: {
        loading: true,
        data: state.notices.data,
        error: null,
      },
    }),
    [POST_NOTICE_SUCCESS]: (state, action) => ({
      ...state,
      notices: {
        loading: false,
        data: state.notices.data.concat(action.payload),
        error: null,
      },
      fetch: true,
    }),
    [POST_NOTICE_FAILURE]: (state, action) => ({
      ...state,
      notices: {
        loading: false,
        data: state.notices.data,
        error: action.payload,
      },
    }),
    [PUT_NOTICE]: (state, action) => ({
      ...state,
      notices: {
        loading: true,
        data: state.notices.data,
        error: null,
      },
    }),
    [PUT_NOTICE_SUCCESS]: (state, action) => ({
      ...state,
      notices: {
        loading: false,
        data: state.notices.data.map((notice) =>
          notice.id === action.payload.id ? { ...action.payload } : notice,
        ),
        error: null,
      },
      fetch: true,
    }),
    [PUT_NOTICE_FAILURE]: (state, action) => ({
      ...state,
      notices: {
        loading: false,
        data: state.notices.data,
        error: action.error,
      },
    }),
    [DELETE_NOTICE]: (state, action) => ({
      ...state,
      notices: {
        loading: false,
        data: state.notices.data,
        error: null,
      },
    }),
    [DELETE_NOTICE_SUCCESS]: (state, action) => ({
      ...state,
      notices: {
        loading: false,
        data: state.notices.data.filter(
          (notice) => notice.id !== action.payload,
        ),
        error: null,
      },
      fetch: true,
    }),
    [DELETE_NOTICE_FAILURE]: (state, action) => ({
      ...state,
      notices: {
        loading: false,
        data: state.notices.data,
        error: action.error,
      },
    }),
  },

  initialState,
);

export default notices;
