import jwt_decode from 'jwt-decode';
import { getAccessToken } from 'lib/utils/auth';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { useIntl } from 'react-intl';

const ProtectedRoute = (props) => {
  const intl = useIntl();
  const { element, auth } = props;
  const token = getAccessToken();
  const navigate = useNavigate();

  if (!token) {
    swal(
      'Failed',
      intl.formatMessage({
        id: 'valid-credential-empty',
      }) +
        '\n' +
        intl.formatMessage({
          id: 'valid-login-again',
        }),
      'error',
    ).then((value) => {
      navigate('/');
    });
  } else {
    let roleString = jwt_decode(token).AUTHORITIES.split(',');
    if (roleString.includes(auth)) {
      return <>{element}</>;
    } else {
      swal(
        'Failed',
        intl.formatMessage({
          id: 'valid-authorization-empty',
        }),
        'error',
      ).then((value) => {
        navigate(-1);
      });
      return <></>;
    }
  }
};

export default ProtectedRoute;
