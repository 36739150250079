import SearchDateControl from 'components/common/SearchDateControl';
import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';
import {
  changeSearchEndDate,
  changeSearchStartDate,
  getSystemerrors,
} from 'modules/systemerrors';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const SystemerrorHeader = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.systemerrors.systemerrors);
  const { startDt, endDt } = useSelector((state) => state.systemerrors.search);

  if (!data) return null;

  const onClickSearch = () => {
    dispatch(getSystemerrors(startDt, endDt));
  };

  const onChangeSearchStartDate = (startdt) => {
    dispatch(changeSearchStartDate({ startDt: startdt }));
  };

  const onChangeSearchEndDate = (enddt) => {
    dispatch(changeSearchEndDate({ endDt: enddt }));
  };

  const CSVheader = [
    {
      label: 'Number',
      key: 'id',
    },
    {
      label: 'OccurrenceDate',
      key: 'errorDt',
    },
    {
      label: 'Error',
      key: 'error',
    },
    {
      label: 'Contents',
      key: 'message',
    },
  ];

  return (
    <MDBox>
      <MDBox
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
        }}
      >
        <MDTypography
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'text.secondary' }}
          variant="h4"
        >
          <FormattedMessage id="system-hist" />
        </MDTypography>
      </MDBox>
      <MDBox
        sx={{
          paddingTop: 2,
        }}
      >
        <SearchDateControl
          onChangeSearchStartDate={onChangeSearchStartDate}
          onChangeSearchEndDate={onChangeSearchEndDate}
          onClickSearch={onClickSearch}
          header={CSVheader}
          data={data}
          startDt={startDt}
          endDt={endDt}
          exportFileName={'NS-White_systemLog'}
        />
      </MDBox>
    </MDBox>
  );
};

export default SystemerrorHeader;
