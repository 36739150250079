import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import * as api from '../lib/api/systemerrors';

const PAGE_OUT = 'systemerror/PAGE_OUT';

const INITIAL_SYSTEMERROR = 'systemerror/INITIAL_SYSTEMERROR';

const CHANGE_FIELD = 'systemerror/CHANGE_FIELD';
const CHANGE_VIEW = 'systemerror/CHANGE_VIEW';

const CHANGE_SEARCH_FIELD_STARTDATE =
  'systemerror/CHANGE_SEARCH_FIELD_STARTDATE';
const CHANGE_SEARCH_FIELD_ENDDATE = 'systemerror/CHANGE_SEARCH_FIELD_ENDDATE';

const GET_SYSTEMERROR_LIST = 'systemerror/GET_SYSTEMERROR_LIST';
const GET_SYSTEMERROR_LIST_SUCCESS = 'systemerror/GET_SYSTEMERROR_LIST_SUCCESS';
const GET_SYSTEMERROR_LIST_FAILURE = 'systemerror/GET_SYSTEMERROR_LIST_FAILURE';

export const pageOut = createAction(PAGE_OUT);

export const initialSystemerror = createAction(INITIAL_SYSTEMERROR);

export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));

export const changeView = createAction(CHANGE_VIEW, ({ status }) => ({
  status,
}));

export const changeSearchStartDate = createAction(
  CHANGE_SEARCH_FIELD_STARTDATE,
  ({ startDt }) => ({
    startDt,
  }),
);

export const changeSearchEndDate = createAction(
  CHANGE_SEARCH_FIELD_ENDDATE,
  ({ endDt }) => ({
    endDt,
  }),
);

export const getSystemerrors = (startDt, endDt) => async (dispatch) => {
  dispatch({ type: GET_SYSTEMERROR_LIST });
  try {
    const response = await api.getSystemerrors(startDt, endDt);
    dispatch({
      type: GET_SYSTEMERROR_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({ type: GET_SYSTEMERROR_LIST_FAILURE, error: e.response });
  }
};

const initialState = {
  search: {
    startDt: null,
    endDt: null,
  },
  systemerrors: {
    loading: false,
    data: null,
    error: null,
  },
  systemerror: {
    loading: false,
    data: {
      id: null,
      error: null,
      error_dt: null,
      message: null,
    },
    error: null,
  },
  fetch: true,
  form: false,
  view: false,
  mode: 'create',
};

const systemerrors = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [INITIAL_SYSTEMERROR]: (state) => ({
      ...state,
      systemerror: {
        data: initialState.systemerror.data,
      },
      mode: 'create',
    }),
    [CHANGE_SEARCH_FIELD_STARTDATE]: (state, { payload: { startDt } }) =>
      produce(state, (draft) => {
        draft.search.startDt = startDt;
      }),
    [CHANGE_SEARCH_FIELD_ENDDATE]: (state, { payload: { endDt } }) =>
      produce(state, (draft) => {
        draft.search.endDt = endDt;
      }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.systemerror.data[key] = value;
      }),
    [CHANGE_VIEW]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.view = status;
      }),
    [GET_SYSTEMERROR_LIST]: (state) => ({
      ...state,
      systemerrors: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_SYSTEMERROR_LIST_SUCCESS]: (state, action) => ({
      ...state,
      systemerrors: {
        loading: false,
        data: action.payload,
        error: null,
      },
      fetch: false,
    }),
    [GET_SYSTEMERROR_LIST_FAILURE]: (state, action) => ({
      ...state,
      systemerrors: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
  },

  initialState,
);

export default systemerrors;
