import { getAuthonrities } from 'lib/utils/auth';
import { handleActions } from 'redux-actions';
import { MenuItemData } from '__mocks__/menuItemData';

const AUTHENTICATION_MENU = 'auth/MENU';

export const authencationMenu = () => (dispatch) => {
  const auth = getAuthonrities();

  if (auth) {
    var mainMenus = MenuItemData.filter((menu) => auth.includes(menu.auth));

    mainMenus.map((mainMenu) => {
      if (mainMenu.subItem) {
        mainMenu.subItem = mainMenu.subItem.filter((subMenu) =>
          auth.includes(subMenu.auth),
        );
      }
      return mainMenu;
    });

    dispatch({ type: AUTHENTICATION_MENU, payload: mainMenus });
  }
};

const initialState = {
  isAuthentication: false,
  authonrities: [],
};

const auth = handleActions(
  {
    [AUTHENTICATION_MENU]: (state, action) => ({
      ...state,
      isAuthentication: true,
      authonrities: action.payload,
    }),
  },
  initialState,
);

export default auth;
