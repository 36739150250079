import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import UserForm from './UserForm';
import UserListBody from './UserListBody';
import UserListHeader from './UserListHeader';

const User = () => {
  return (
    <>
      <MDBox component="main" my={3}>
        <Container maxWidth={false}>
          <UserListHeader />
          <MDBox sx={{ mt: 3 }}>
            <UserListBody />
          </MDBox>
        </Container>
      </MDBox>
      <UserForm />
    </>
  );
};

export default User;
