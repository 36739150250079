/* eslint-disable */
import SearchDateControl from 'components/common/SearchDateControl';
import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';
import {
  changeSearchEndDate,
  changeSearchStartDate,
  getMailHistoryList,
} from 'modules/mailhistorys';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const MailHistoryHeader = () => {
  const dispatch = useDispatch();
  const { startDt, endDt } = useSelector((state) => state.mailhistory.search);
  const { data } = useSelector((state) => state.mailhistory.mailhistorys);

  if (!data) return null;

  const onClickSearch = () => {
    dispatch(getMailHistoryList(startDt, endDt));
  };

  const onChangeSearchStartDate = (startdt) => {
    dispatch(changeSearchStartDate({ startDt: startdt }));
  };

  const onChangeSearchEndDate = (enddt) => {
    dispatch(changeSearchEndDate({ endDt: enddt }));
  };

  const CSVheader = [
    {
      label: 'Recipient',
      key: 'receiveUser',
    },
    {
      label: 'Sender',
      key: 'sendUser',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: 'CreatedDate',
      key: 'createDt',
    },
    {
      label: 'ModifiedDate',
      key: 'updateDt',
    },
  ];

  return (
    <MDBox>
      <MDBox
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
        }}
      >
        <MDTypography
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'text.secondary' }}
          variant="h4"
        >
          <FormattedMessage id="mail-hist" />
        </MDTypography>
      </MDBox>
      <MDBox
        sx={{
          paddingTop: 2,
        }}
      >
        <SearchDateControl
          onChangeSearchStartDate={onChangeSearchStartDate}
          onChangeSearchEndDate={onChangeSearchEndDate}
          onClickSearch={onClickSearch}
          header={CSVheader}
          data={data}
          startDt={startDt}
          endDt={endDt}
          exportFileName={'NS-White_mailLog'}
        />
      </MDBox>
    </MDBox>
  );
};
export default MailHistoryHeader;
