import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import SystemerrorBody from './SystemerrorBody';
import SystemerrorHeader from './SystemerrorHeader';

const Systemerror = () => {
  return (
    <MDBox component="main" my={3}>
      <Container maxWidth={false}>
        <SystemerrorHeader />
        <MDBox sx={{ mt: 3 }}>
          <SystemerrorBody />
        </MDBox>
      </Container>
    </MDBox>
  );
};

export default Systemerror;
