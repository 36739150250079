import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import { changeSetting, getSettingList } from 'modules/settings';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Mailing from './contents/Mailing';
import ModuleBuild from './contents/ModuleBuild';

const SettingListBody = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.settings.settings,
  );
  const [value, setValue] = React.useState('2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSettingChange = (e) => {
    dispatch(changeSetting({ key: e.target.name, value: e.target.value }));
  };

  useEffect(() => {
    dispatch(getSettingList());
  }, [dispatch]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {/* <Tab label={<FormattedMessage id="basic" />} value="1" /> */}
            <Tab label={<FormattedMessage id="mailing" />} value="2" />
            <Tab label={<FormattedMessage id="module" />} value="3" />
          </TabList>
        </Box>
        {/* <TabPanel value="1">
          <Base />
        </TabPanel> */}
        <TabPanel value="2">
          <Mailing handleSettingChange={handleSettingChange} />
        </TabPanel>
        <TabPanel value="3">
          <ModuleBuild handleSettingChange={handleSettingChange} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default SettingListBody;
