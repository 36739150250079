import { FormattedMessage } from 'react-intl';

export const parseModuleStatus = (status) => {
  switch (status) {
    case 'WAIT':
      return <FormattedMessage id="wait" />;
    case 'PROGRESS':
      return <FormattedMessage id="progress" />;
    case 'BUILD':
      return <FormattedMessage id="build" />;
    case 'RELEASE':
      return <FormattedMessage id="release" />;
    case 'SUCCESS':
      return <FormattedMessage id="success" />;
    case 'DELETE':
      return <FormattedMessage id="remove" />;
    default:
      return <FormattedMessage id="failure" />;
  }
};

export const parsingStatusColor = (status) => {
  switch (status) {
    case 'WAIT':
      return 'primary';
    case 'PROGRESS':
      return 'warning';
    case 'BUILD':
      return 'secondary';
    case 'RELEASE':
      return 'info';
    case 'SUCCESS':
      return 'success';
    case 'DELETE':
      return 'error';
    default:
      return 'error';
  }
};

export const parseAlgorithm = (algorithm) => {
  switch (algorithm) {
    case 'AES128':
      return 'AES';
    case 'ARIA_32_128':
      return 'ARIA';
    case 'LEA128':
      return 'LEA';
    case 'PIPO128':
      return 'PIPO-128';
    case 'PIPO256':
      return 'PIPO-256';
    default:
      return 'NONE';
  }
};

export const parseElementOperation = (elementOperation) => {
  switch (elementOperation) {
    case 'IV_IO':
      return 'IV/IO';
    default:
      return elementOperation;
  }
};

export const parseCreateOs = (os) => {
  switch (os) {
    case 'ANDROID':
      return 'Android';
    case 'IOS':
      return 'IOS';
    default:
      return os;
  }
};

export const parseCreateOperation = (operation) => {
  switch (operation) {
    case 'ENCRYPT':
      return <FormattedMessage id="encrypt" />;
    case 'DECRYPT':
      return <FormattedMessage id="decrypt" />;
    default:
      return operation;
  }
};

export const parseModuleHistoryMessage = (code) => {
  // obfuscation, preprocess, iosbuild, androidbuild, releasefile, retry
  switch (code) {
    case 'obfuscation':
      return <FormattedMessage id="status-create-module-obfuscation" />;
    case 'preprocess':
      return <FormattedMessage id="status-create-module-preprocess" />;
    case 'iosbuild':
      return <FormattedMessage id="status-create-module-iosbuild" />;
    case 'androidbuild':
      return <FormattedMessage id="status-create-module-androidbuild" />;
    case 'releasefile':
      return <FormattedMessage id="status-create-module-releasefile" />;
    case 'retry':
      return <FormattedMessage id="status-create-module-retry" />;
    default:
      return code;
  }
};
