import axios from 'axios';

export const getModulehistories = (startDt, endDt) =>
  axios.get(`/api/module-histories?startDt=${startDt}&endDt=${endDt}`);

export const getModulehistoryByModuleId = (moduleId) =>
  axios.get(`/api/module-histories/${moduleId}`);

export const getModuleHistByWaitCountAndTime = () =>
  axios.get('/api/module-histories/status/wait/countAndTime');

export const retryCreateModule = (id) =>
  axios.put(`/api/module-histories/retry/${id}`);
