import { Container } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import ModuleForm from './ModuleForm';
import ModuleListBody from './ModuleListBody';
import ModuleListHeader from './ModuleListHeader';

const Module = () => {
  return (
    <>
      <MDBox component="main" my={3}>
        <Container maxWidth={false}>
          <ModuleListHeader />
          <MDBox sx={{ mt: 3 }}>
            <ModuleListBody />
          </MDBox>
        </Container>
      </MDBox>
      <ModuleForm />
    </>
  );
};

export default Module;
