import MDBox from 'components/otis/MDBox';
import React from 'react';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
  return (
    <>
      <MDBox
        width="100vw"
        height="100%"
        minHeight="100vh"
        sx={{ overflowX: 'hidden' }}
      >
        <Outlet />
      </MDBox>
    </>
  );
};

export default MainLayout;
