/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from '@mui/material';
import DeleteAlert from 'components/common/DeleteAlert';
import Loading from 'components/common/Loading';
import { updateDeleteAlertState } from 'modules/common';
import {
  changeForm,
  changeView,
  deleteNotice,
  getNotice,
  getNoticeList,
  pageOut,
} from 'modules/notices';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'components/common/error/Error';
import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import OperationCell from 'components/datacell/OperationCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import { existAuthonrities } from 'lib/utils/auth';
import { FormattedMessage } from 'react-intl';

const NoticeListBody = () => {
  const existWriteAuth = existAuthonrities('NOTICE_RW');

  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.notices.notices,
  );
  const fetch = useSelector((state) => state.notices.fetch);
  const [selectedNoticeId, setSelectedNoticeId] = useState(null);

  const unLoadNotice = useCallback(() => dispatch(pageOut()), [dispatch]);

  const loadNotice = useCallback(() => dispatch(getNoticeList()), [dispatch]);

  useEffect(() => {
    if (fetch) {
      loadNotice();
    }
    return () => {
      if (!fetch) unLoadNotice();
    };
  }, [loadNotice, unLoadNotice, fetch]);

  const handleDelete = () => {
    dispatch(deleteNotice(selectedNoticeId));
    dispatch(updateDeleteAlertState(false));
  };

  const handleFormOpen = () => {
    dispatch(changeForm({ status: true }));
  };

  const handleViewOpen = () => {
    dispatch(changeView({ status: true }));
  };

  const handleView = useCallback(
    (id) => () => {
      dispatch(getNotice(id));
      handleViewOpen();
    },
    [],
  );

  const handleUpdateFormOpen = useCallback(
    (id) => () => {
      dispatch(getNotice(id));
      handleFormOpen();
    },
    [],
  );

  const handleDeleteAlertOpen = useCallback(
    (id) => () => {
      setSelectedNoticeId(id);
      dispatch(updateDeleteAlertState(true));
    },
    [],
  );

  const NoticeListData = {
    columns: [
      {
        Header: <FormattedMessage id="title" />,
        accessor: 'title',
        width: '50%',
        sort: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="created-date" />,
        accessor: 'createDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="modified-date" />,
        accessor: 'updateDt',
        sort: true,
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="operation" />,
        accessor: 'id',
        width: '20%',
        Cell: ({ value }) => (
          <OperationCell
            enableUpdate={existWriteAuth}
            enableDelete={existWriteAuth}
            enableView={true}
            handleUpdateFormOpen={handleUpdateFormOpen(value)}
            handleDeleteAlertOpen={handleDeleteAlertOpen(value)}
            handleView={handleView(value)}
          />
        ),
      },
    ],
    rows: data,
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <Card>
      <DataTable table={NoticeListData} entriesPerPage={false} canSearch />
      <DeleteAlert
        resource={<FormattedMessage id="notice" />}
        handleDelete={handleDelete}
      />
    </Card>
  );
};

export default NoticeListBody;
