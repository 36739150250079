import jwt_decode from 'jwt-decode';

export const getAccessToken = () => {
  const token = sessionStorage.getItem('AccessToken');
  return token ? token : null;
};

export const setAccessToken = (token) => {
  sessionStorage.setItem('AccessToken', token);
};

export const getRefreshToken = () => {
  const token = sessionStorage.getItem('RefreshToken');
  return token ? token : null;
};

export const setRefreshToken = (token) => {
  sessionStorage.setItem('RefreshToken', token);
};

export const getExpiresAt = () => {
  const expiresAt = sessionStorage.getItem('ExpiresAt');
  return expiresAt ? expiresAt : null;
};

export const setExpiresAt = (expiresAt) => {
  sessionStorage.setItem('ExpiresAt', expiresAt);
};

export const getLocale = () => {
  return localStorage.getItem('locale') ?? 'ko';
};

export const setLocale = (locale) => {
  localStorage.setItem('locale', locale);
};

export const getAuthonrities = () => {
  const token = getAccessToken();

  return token ? jwt_decode(token).AUTHORITIES.split(',') : null;
};

export const existAuthonrities = (privilege) => {
  const authonrities = getAuthonrities();

  return authonrities ? authonrities.includes(privilege) : false;
};

export const getIsAdmin = () => {
  const token = getAccessToken();

  return token ? jwt_decode(token).ADMIN : false;
};

export const getUserCustomer = () => {
  const token = getAccessToken();
  return token ? jwt_decode(token).CUSTOMER : null;
};

export const getUser = () => {
  const token = getAccessToken();

  return token ? jwt_decode(token).USER : null;
};

export const getUserId = () => {
  const token = getAccessToken();

  return token ? jwt_decode(token).sub : null;
};

export const removeToken = () => {
  sessionStorage.removeItem('AccessToken');
  sessionStorage.removeItem('RefreshToken');
  sessionStorage.removeItem('ExpiresAt');
};
