import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDInput from 'components/otis/MDInput';
import MDTypography from 'components/otis/MDTypography';
import * as dayjs from 'dayjs';
import {
  changeField,
  changeForm,
  postCustomer,
  putCustomer,
} from 'modules/customers';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const CustomerForm = () => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customers.customer.data);
  const mode = useSelector((state) => state.customers.mode);
  const form = useSelector((state) => state.customers.form);
  const { loading, error } = useSelector((state) => state.customers.customer);
  const [errors, setErrors] = useState({});

  const handleFormClose = () => {
    dispatch(changeForm({ status: false }));
    setErrors({});
  };

  const handleAdd = (e) => {
    if (onValdation()) {
      dispatch(postCustomer(customer));
      handleFormClose();
    }
  };

  const handleUpdate = (e) => {
    if (onValdation()) {
      dispatch(putCustomer(customer.id, customer));
      handleFormClose();
    }
  };

  const onChanged = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.value,
      }),
    );
  };

  const onChangedDatePicker = (e) => {
    let startDt = e[0];
    let endDt = e[1];

    if (startDt !== null && startDt !== customer.expiredStartDt) {
      dispatch(
        changeField({
          key: 'expiredStartDt',
          value: dayjs(startDt).format('YYYY-MM-DD'),
        }),
      );
    }
    if (endDt !== null && endDt !== customer.expiredEndDt) {
      dispatch(
        changeField({
          key: 'expiredEndDt',
          value: dayjs(endDt).format('YYYY-MM-DD'),
        }),
      );
    }
  };

  const onValdation = () => {
    let temp = {};

    if (customer.name) {
      temp.name =
        customer.name.length < 50 ? (
          ''
        ) : (
          <FormattedMessage id="valid-customer-name-length" />
        );
    } else {
      temp.name = <FormattedMessage id="valid-name-input" />;
    }

    temp.moduleCount =
      customer.moduleCount <= 999 ? (
        ''
      ) : (
        <FormattedMessage id="valid-module-maximum-count" />
      );

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Dialog open={form} maxWidth="md">
          <DialogTitle>
            {mode === 'create' ? (
              <FormattedMessage id="add-customer" />
            ) : (
              <FormattedMessage id="edit-customer" />
            )}
          </DialogTitle>
          <DialogContent>
            <MDBox>
              <FormGroup>
                <FormControl variant="standard" sx={{ m: 1, width: '30inch' }}>
                  <MDInput
                    id="name"
                    name="name"
                    label={<FormattedMessage id="name" />}
                    type="text"
                    variant="standard"
                    value={customer.name ? customer.name : ''}
                    onChange={onChanged}
                    required
                    {...(errors.name && {
                      error: true,
                      helperText: errors.name,
                      style: { minHeight: '1rem' },
                    })}
                  />
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, width: '30inch' }}>
                  <MDInput
                    type="number"
                    name="moduleCount"
                    label={<FormattedMessage id="module-count" />}
                    variant="standard"
                    value={customer.moduleCount ? customer.moduleCount : 1}
                    onChange={onChanged}
                    inputProps={{ min: 0, max: 999, maxLength: 3 }}
                    required
                    {...(errors.moduleCount && {
                      error: true,
                      helperText: errors.moduleCount,
                      style: { minHeight: '1rem' },
                    })}
                  />
                </FormControl>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{
                    start: <FormattedMessage id="start-date" />,
                    end: <FormattedMessage id="end-date" />,
                  }}
                >
                  <div>
                    <MDTypography variant="h6" sx={{ mt: 2, mb: 1 }}>
                      <FormattedMessage id="expiry-date" />
                    </MDTypography>
                    <DateRangePicker
                      inputFormat={'YYYY-MM-DD'}
                      value={[
                        customer.expiredStartDt
                          ? customer.expiredStartDt
                          : null,
                        customer.expiredEndDt ? customer.expiredEndDt : null,
                      ]}
                      onChange={onChangedDatePicker}
                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <MDInput {...startProps} />
                          <Box sx={{ mx: 1 }}>~</Box>
                          <MDInput {...endProps} />
                        </React.Fragment>
                      )}
                    />
                  </div>
                </LocalizationProvider>
              </FormGroup>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={mode === 'create' ? handleAdd : handleUpdate}>
              <FormattedMessage id="save" />
            </MDButton>
            <MDButton onClick={handleFormClose}>
              <FormattedMessage id="cancel" />
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CustomerForm;
