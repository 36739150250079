import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { Client } from '@stomp/stompjs';
import axios from 'axios';
import { parseModuleHistoryMessage } from 'lib/utils/parse/module';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router-dom';
import swal from 'sweetalert';
import moment from '../node_modules/moment/moment';
import theme from './assets/theme';
import * as auth from './lib/api/auth';
import {
  getAccessToken,
  getExpiresAt,
  getIsAdmin,
  getRefreshToken,
  removeToken,
  setAccessToken,
  setExpiresAt,
  setRefreshToken,
} from './lib/utils/auth';
import routes from './routes';
const App = () => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const expiresAt = getExpiresAt();
  const tokenSilent = useSelector((state) => state.common.tokenSilent);
  const intl = useIntl();
  const isAdmin = getIsAdmin();
  const routing = useRoutes(routes(accessToken ? true : false, isAdmin));
  const navigate = useNavigate();
  const client = useRef({});
  const { enqueueSnackbar } = useSnackbar();

  LicenseInfo.setLicenseKey(
    'b75307d374d82c4ea86c9176e4dfc1c6Tz01NjM1MSxFPTE3MDMwMzY2MjE5MzksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  );

  let isTokenRefreshing = false;
  let refreshSubscribers = [];

  const connect = useCallback(() => {
    client.current = new Client({
      brokerURL: 'ws://localhost:8080/websocket',
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: () => {
        client.current.subscribe('/sub/module/result', (message) => {
          enqueueSnackbar(
            intl.formatMessage({
              id: 'valid-module-fail-created',
            }) +
              ' : ' +
              JSON.parse(message.body).packageName +
              ', ' +
              parseModuleHistoryMessage(JSON.parse(message.body).message),
            {
              variant: 'error',
              autoHideDuration: 3000,
              hideIconVariant: true,
            },
          );
        });
      },
    });

    client.current.activate();
  }, [enqueueSnackbar, intl]);

  const disconnect = useCallback(() => {
    client.current.deactivate();
  }, []);

  const onTokenRefreshed = (accessToken) => {
    refreshSubscribers.map((callback) => callback(accessToken));
  };

  const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  const silentToken = async () => {
    delete axios.defaults.headers.common['Authorization'];
    const response = await auth.getRefreshToken();
    setAccessToken(response.data.data.accessToken);
    setRefreshToken(response.data.data.refreshToken);
    isTokenRefreshing = true;
  };

  axios.defaults.withCredentials = true;

  if (accessToken) {
    axios.defaults.headers.common.RefreshToken = `Bearer ${refreshToken}`;
    if (JSON.parse(tokenSilent)) {
      if (expiresAt > moment().unix()) {
        silentToken();
      } else {
        isTokenRefreshing = false;
      }
    }
    setExpiresAt(moment().add(10, 'minutes').unix());
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  axios.interceptors.response.use(undefined, async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    if (status === 401) {
      swal(
        'Failed',
        intl.formatMessage({
          id: 'valid-credential-empty',
        }) +
          '\n ' +
          intl.formatMessage({
            id: 'valid-login-again',
          }),
        'error',
        {
          buttons: false,
          timer: 1500,
        },
      );
      removeToken();
      navigate('/');
    } else if (status === 403) {
      if (!isTokenRefreshing) {
        // isTokenRefreshing이 false인 경우에만 token refresh 요청
        isTokenRefreshing = true;
        if (
          swal({
            title: intl.formatMessage({
              id: 'valid-credential-expired',
            }),
            text: intl.formatMessage({
              id: 'ask-credentials-renew',
            }),
            buttons: {
              update: {
                text: intl.formatMessage({
                  id: 'check',
                }),
                value: 'true',
              },
              cancel: intl.formatMessage({
                id: 'cancel',
              }),
            },
          }).then(async (btn) => {
            if (btn) {
              try {
                delete axios.defaults.headers.common['Authorization'];
                const response = await auth.getRefreshToken();
                swal(
                  'Success',
                  intl.formatMessage({
                    id: 'success-credentials-renew',
                  }),
                  'success',
                  {
                    buttons: false,
                    timer: 2000,
                  },
                ).then((value) => {
                  console.clear();
                  setAccessToken(response.data.data.accessToken);
                  setRefreshToken(response.data.data.refreshToken);
                  navigate(window.location.pathname);

                  isTokenRefreshing = false;
                  onTokenRefreshed(response.data.data.accessToken);
                });
              } catch (error) {
                swal('Failed', error.response.data, 'error');
                removeToken();
                navigate('/');
              }
            } else {
              removeToken();
              navigate('/');
            }
          })
        ) {
        }
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber((accessToken) => {
          originalRequest.headers.Authorization = 'Bearer ' + accessToken;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    } else if (status === 504) {
      swal({
        title: intl.formatMessage({
          id: 'fail-communication-server',
        }),
        text:
          intl.formatMessage({
            id: 'fail-response-server',
          }) +
          '\n' +
          intl.formatMessage({
            id: 'valid-contact-admin',
          })(
            (config.url !== '/api/settings/otp') &
              (config.url !== '/api/settings/token/silent')
              ? '\n' +
                  intl.formatMessage({
                    id: 'move-login-page',
                  })
              : '',
          ),
        buttons: intl.formatMessage({
          id: 'check',
        }),
      }).then((value) => {
        if (
          (config.url !== '/api/settings/otp') &
          (config.url !== '/api/settings/token/silent')
        ) {
          removeToken();
          navigate('/', { replace: false });
        }
      });
    }
    return Promise.reject(error);
  });

  useEffect(() => {
    connect();

    return () => disconnect();
  }, [connect, disconnect]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routing}
      </ThemeProvider>
    </>
  );
};

export default App;
