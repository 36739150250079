import MDTypography from 'components/otis/MDTypography';

function DefaultCell({ value }) {
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {value ? value : ''}
    </MDTypography>
  );
}

export default DefaultCell;
