import produce from 'immer';
import * as api from 'lib/api/modules';
import { getUserId } from 'lib/utils/auth';
import { RandomHexString } from 'lib/utils/initialized';
import { createAction, handleActions } from 'redux-actions';
import { getUser } from './users';

const PAGE_OUT = 'modules/PAGE_OUT';

const INITIAL_MODULE = 'modules/INITIAL_MODULE';

const CHANGE_FIELD = 'modules/CHANGE_FIELD';

const CHANGE_FORM = 'modules/CHANGE_FORM';

const GET_MODULE_LIST = 'modules/GET_MODULE_LIST';
const GET_MODULE_LIST_SUCCESS = 'modules/GET_MODULE_LIST_SUCCESS';
const GET_MODULE_LIST_FAILURE = 'modules/GET_MODULE_LIST_FAILURE';

const POST_MODULE = 'modules/POST_MODULE';
const POST_MODULE_SUCCESS = 'modules/POST_MODULE_SUCCESS';
const POST_MODULE_FAILURE = 'modules/POST_MODULE_FAILURE';

const DELETE_MODULE = 'modules/DELETE_MODULE';
const DELETE_MODULE_SUCCESS = 'modules/DELETE_MODULE_SUCCESS';
const DELETE_MODULE_FAILURE = 'modules/DELETE_MODULE_FAILURE';

export const pageOut = createAction(PAGE_OUT);

export const initialModule = createAction(INITIAL_MODULE);

export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));

export const changeForm = createAction(CHANGE_FORM, ({ status }) => ({
  status,
}));

export const getModuleList = () => async (dispatch) => {
  dispatch({ type: GET_MODULE_LIST });
  try {
    const response = await api.getModuleList();
    dispatch({ type: GET_MODULE_LIST_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_MODULE_LIST_FAILURE, error: e.response });
  }
};

export const postModule = (module) => async (dispatch) => {
  dispatch({ type: POST_MODULE });
  try {
    const response = await api.postModule(module);
    dispatch({
      type: POST_MODULE_SUCCESS,
      payload: { ...module, id: response.data.data },
    });
  } catch (e) {
    dispatch({ type: POST_MODULE_FAILURE, error: e.response });
  }
};

export const deleteModule = (id) => async (dispatch) => {
  dispatch({ type: DELETE_MODULE });
  try {
    const response = await api.deleteModule(id);
    dispatch({ type: DELETE_MODULE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: DELETE_MODULE_FAILURE, error: e.response });
  }
};

const initialState = {
  modules: {
    loading: false,
    data: null,
    error: null,
  },
  module: {
    loading: false,
    data: {
      createUserName: null,
      userId: null,
      userName: null,
      algorithm: null,
      operationMode: null,
      createOs: null,
      // createOperation: null,
      seed: null,
      masterKey: null,
      encDecKey: null,
      counter: null,
      elementOperation: null,
      elementIo: null,
      elementIv: null,
      packageName: null,
      path: null,
    },
    error: null,
  },
  fetch: true,
  form: false,
  mode: 'create',
};

const modules = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [INITIAL_MODULE]: (state) => ({
      ...state,
      module: {
        data: {
          ...initialState.module.data,
          createUserName: getUser(),
          userId: getUserId(),
          algorithm: 4,
          operationMode: 1,
          createOs: 1,
          // createOperation: 1,
          encDecKey: RandomHexString(),
          counter: RandomHexString(),
          elementOperation: 1,
          elementIo: 'NO',
          elementIv: 'NO',
        },
      },
      mode: 'create',
    }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.module.data[key] = value;
      }),
    [CHANGE_FORM]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.form = status;
      }),
    [GET_MODULE_LIST]: (state) => ({
      ...state,
      modules: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_MODULE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      modules: {
        loading: false,
        data: action.payload,
        error: null,
      },
      fetch: false,
    }),
    [GET_MODULE_LIST_FAILURE]: (state, action) => ({
      ...state,
      modules: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
    [POST_MODULE]: (state) => ({
      ...state,
      modules: {
        loading: true,
        data: state.modules.data,
        error: null,
      },
    }),
    [POST_MODULE_SUCCESS]: (state, action) => ({
      ...state,
      modules: {
        loading: false,
        data: state.modules.data.concat(action.payload),
        error: null,
      },
      fetch: true,
    }),
    [POST_MODULE_FAILURE]: (state, action) => ({
      ...state,
      modules: {
        loading: false,
        data: state.modules.data,
        error: action.error,
      },
    }),
    [DELETE_MODULE]: (state, action) => ({
      ...state,
      modules: {
        loading: false,
        data: state.modules.data,
        error: null,
      },
    }),
    [DELETE_MODULE_SUCCESS]: (state, action) => ({
      ...state,
      modules: {
        loading: false,
        data: state.modules.data.filter(
          (module) => module.id !== action.payload,
        ),
        error: null,
      },
      fetch: true,
    }),
    [DELETE_MODULE_FAILURE]: (state, action) => ({
      ...state,
      modules: {
        loading: false,
        data: state.modules.data,
        error: action.error,
      },
    }),
  },
  initialState,
);

export default modules;
