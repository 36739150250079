import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDEditor from 'components/otis/MDEditor';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import MDInput from 'components/otis/MDInput';
import MDTypography from 'components/otis/MDTypography';
import * as dayjs from 'dayjs';
import {
  changeField,
  changeForm,
  postNotice,
  putNotice,
} from 'modules/notices';
import { FormattedMessage } from 'react-intl';

const NoticeForm = () => {
  const dispatch = useDispatch();
  const notice = useSelector((state) => state.notices.notice.data);
  const { loading, error } = useSelector((state) => state.notices.notice);
  const mode = useSelector((state) => state.notices.mode);
  const form = useSelector((state) => state.notices.form);
  const [errors, setErrors] = useState({});
  const [detail, setDetail] = useState(false);

  const handleOpenDetail = () => setDetail(!detail);

  const handleFormClose = () => {
    dispatch(changeForm({ status: false }));
    setErrors({});
    setDetail(false);
  };

  const handleAdd = (e) => {
    if (onValdation()) {
      dispatch(postNotice(notice));
      handleFormClose();
    }
  };

  const handleUpdate = (e) => {
    if (onValdation()) {
      dispatch(putNotice(notice.id, notice));
      handleFormClose();
    }
  };

  const onChanged = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.value,
      }),
    );
  };

  const onChangedContent = (fieldkey, fieldvalue) => {
    dispatch(
      changeField({
        key: 'contentHtml',
        value: fieldvalue,
      }),
    );
    dispatch(
      changeField({
        key: fieldkey,
        value: window.btoa(unescape(encodeURIComponent(fieldvalue))),
      }),
    );
  };

  const onChangedIsEmergency = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.checked,
      }),
    );
  };

  const onChangedDatePicker = (e) => {
    let startDt = e[0];
    let endDt = e[1];

    if (startDt !== null && startDt !== notice.alertStartDt) {
      dispatch(
        changeField({
          key: 'alertStartDt',
          value: dayjs(startDt).format('YYYY-MM-DD'),
        }),
      );
    }
    if (endDt !== null && endDt !== notice.alertEndDt) {
      dispatch(
        changeField({
          key: 'alertEndDt',
          value: dayjs(endDt).format('YYYY-MM-DD'),
        }),
      );
    }
  };

  const onValdation = () => {
    let temp = {};

    temp.title = notice.title ? (
      ''
    ) : (
      <FormattedMessage id="valid-subject-input" />
    );
    temp.content = notice.content ? (
      ''
    ) : (
      <FormattedMessage id="valid-content-input" />
    );

    if (notice.title && notice.title.length > 50) {
      temp.title = <FormattedMessage id="valid-notice-title-length" />;
    }

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Dialog open={form} maxWidth="md">
          <DialogTitle>
            {mode === 'create' ? (
              <FormattedMessage id="add-notice" />
            ) : (
              <FormattedMessage id="edit-notice" />
            )}
          </DialogTitle>
          <DialogContent>
            <MDBox>
              <MDTypography
                variant="body2"
                fontWeight="medium"
                textTransform="capitalize"
              >
                <FormattedMessage id="notice" />
              </MDTypography>
              <MDBox>
                <FormControl>
                  <MDInput
                    id="title"
                    name="title"
                    label={<FormattedMessage id="title" />}
                    type="text"
                    variant="standard"
                    sx={{ m: 1, width: '80ch' }}
                    value={notice.title ? notice.title : ''}
                    onChange={onChanged}
                    required
                    {...(errors.title && {
                      error: true,
                      helperText: errors.title,
                      style: { minHeight: '1rem' },
                    })}
                  />
                  <MDBox sx={{ m: 1 }}>
                    {
                      <div>
                        <MDEditor
                          value={notice.contentHtml ? notice.contentHtml : ''}
                          onChange={(data) => {
                            onChangedContent('content', data);
                          }}
                          placeholder=""
                          sx={{ height: '400px' }}
                        />
                        {errors.content && (
                          <MDTypography variant="caption">
                            {errors.content}
                          </MDTypography>
                        )}
                      </div>
                    }
                  </MDBox>
                </FormControl>
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={2}
              px={1}
            >
              <MDTypography
                variant="body2"
                fontWeight="medium"
                textTransform="capitalize"
              >
                <FormattedMessage id="notification-options" />
              </MDTypography>
              <MDTypography variant="body2" color="secondary">
                <Tooltip
                  title={
                    detail ? (
                      <FormattedMessage id="arrow-up" />
                    ) : (
                      <FormattedMessage id="arrow-down" />
                    )
                  }
                  placement="right"
                >
                  <IconButton
                    size="large"
                    disableRipple
                    color="inherit"
                    variant="contained"
                    onClick={handleOpenDetail}
                  >
                    {detail ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </MDTypography>
            </MDBox>
            {detail ? (
              <FormControl variant="standard" sx={{ m: 1, width: '80ch' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isEmergency"
                      checked={notice.isEmergency}
                      onChange={onChangedIsEmergency}
                    />
                  }
                  label={<FormattedMessage id="activated" />}
                />
                {notice.isEmergency ? (
                  <MDBox>
                    <MDTypography variant="h6" sx={{ m: 1 }}>
                      <FormattedMessage id="period" />
                    </MDTypography>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={{
                        start: <FormattedMessage id="start-date" />,
                        end: <FormattedMessage id="end-date" />,
                      }}
                    >
                      <MDBox>
                        <DateRangePicker
                          inputFormat={'YYYY-MM-DD'}
                          value={[
                            notice.alertStartDt ? notice.alertStartDt : null,
                            notice.alertEndDt ? notice.alertEndDt : null,
                          ]}
                          onChange={onChangedDatePicker}
                          renderInput={(startProps, endProps) => (
                            <React.Fragment>
                              <MDInput {...startProps} />
                              <Box sx={{ mx: 1 }}>~</Box>
                              <MDInput {...endProps} />
                            </React.Fragment>
                          )}
                        />
                      </MDBox>
                    </LocalizationProvider>
                  </MDBox>
                ) : (
                  <></>
                )}
              </FormControl>
            ) : (
              <></>
            )}
          </DialogContent>
          <DialogActions>
            <MDButton onClick={mode === 'create' ? handleAdd : handleUpdate}>
              <FormattedMessage id="save" />
            </MDButton>
            <MDButton onClick={handleFormClose}>
              <FormattedMessage id="cancel" />
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default NoticeForm;
