import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PageviewIcon from '@mui/icons-material/Pageview';
import { IconButton } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function OperationCell({
  enableUpdate,
  enableDelete,
  enableView,
  enableDownload,
  handleUpdateFormOpen,
  handleDeleteAlertOpen,
  handleView,
  handleDownloadAlertOpen,
}) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox>
        {enableUpdate && (
          <IconButton
            aria-label="update"
            size="small"
            onClick={handleUpdateFormOpen}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        &nbsp;
        {enableDownload && (
          <IconButton
            aria-label="download"
            size="small"
            onClick={handleDownloadAlertOpen}
          >
            <FileDownloadIcon fontSize="small" />
          </IconButton>
        )}
        &nbsp;
        {enableView && (
          <IconButton aria-label="view" size="small" onClick={handleView}>
            <PageviewIcon fontSize="small" />
          </IconButton>
        )}
        &nbsp;
        {enableDelete && (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleDeleteAlertOpen}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </MDBox>
    </MDBox>
  );
}

export default OperationCell;
