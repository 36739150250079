import produce from 'immer';
import * as api from 'lib/api/operations';
import { createAction, handleActions } from 'redux-actions';

const PAGE_OUT = 'operations/PAGE_OUT';

const GET_OPERATION_LIST = 'operations/GET_OPERATION_LIST';
const GET_OPERATION_LIST_SUCCESS = 'operations/GET_OPERATION_LIST_SUCCESS';
const GET_OPERATION_LIST_FAILURE = 'operations/GET_OPERATION_LIST_FAILURE';
const CHANGE_SEARCH_FIELD_STARTDATE =
  'operations/CHANGE_SEARCH_FIELD_STARTDATE';
const CHANGE_SEARCH_FIELD_ENDDATE = 'operations/CHANGE_SEARCH_FIELD_ENDDATE';

const initialState = {
  search: {
    startDt: null,
    endDt: null,
  },
  operations: {
    loading: false,
    data: null,
    error: null,
  },
  fetch: true,
};

export const pageOut = createAction(PAGE_OUT);

export const changeSearchStartDate = createAction(
  CHANGE_SEARCH_FIELD_STARTDATE,
  ({ startDt }) => ({
    startDt,
  }),
);

export const changeSearchEndDate = createAction(
  CHANGE_SEARCH_FIELD_ENDDATE,
  ({ endDt }) => ({
    endDt,
  }),
);

export const getOperationList = (startDt, endDt) => async (dispatch) => {
  dispatch({ type: GET_OPERATION_LIST });
  try {
    const response = await api.getOperationList(startDt, endDt);
    dispatch({
      type: GET_OPERATION_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({ type: GET_OPERATION_LIST_FAILURE, error: e.response });
    throw e;
  }
};

const operations = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [CHANGE_SEARCH_FIELD_STARTDATE]: (state, { payload: { startDt } }) =>
      produce(state, (draft) => {
        draft.search.startDt = startDt;
      }),
    [CHANGE_SEARCH_FIELD_ENDDATE]: (state, { payload: { endDt } }) =>
      produce(state, (draft) => {
        draft.search.endDt = endDt;
      }),
    [GET_OPERATION_LIST]: (state) => ({
      ...state,
      operations: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_OPERATION_LIST_SUCCESS]: (state, action) => ({
      ...state,
      operations: {
        loading: false,
        data: action.payload,
        error: null,
      },
      fetch: false,
    }),
    [GET_OPERATION_LIST_FAILURE]: (state = initialState, action) => ({
      ...state,
      operations: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),
  },
  initialState,
);

export default operations;
