import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';

const UPDATE_DELETE_ALERT_STATE = 'common/UPDATE_DELETE_ALERT_STATE';

const UPDATE_DOWNLOAD_ALERT_STATE = 'common/UPDATE_DOWNLOAD_ALERT_STATE';

const UPDATE_PASSWORD_UPDATE_ALERT_STATE =
  'common/UPDATE_PASSWORD_UPDATE_ALERT_STATE';

const UPDATE_DETAIL_ALERT_STATE = 'common/UPDATE_DETAIL_ALERT_STATE';

const UPDATE_RETRY_ALERT_STATE = 'common/UPDATE_RETRY_ALERT_STATE';

const UPDATE_COMMON_STATE = 'common/UPDATE_COMMON_STATE';

const UPDATE_LANGUAGE_ALERT_STATE = 'common/UPDATE_LANGUAGE_ALERT_STATE';

export const updateDeleteAlertState = createAction(
  UPDATE_DELETE_ALERT_STATE,
  (status) => status,
);

export const updateDownloadAlertState = createAction(
  UPDATE_DOWNLOAD_ALERT_STATE,
  (status) => status,
);

export const updatePasswordUpdateAlertState = createAction(
  UPDATE_PASSWORD_UPDATE_ALERT_STATE,
  (status) => status,
);

export const updateDetailAlertState = createAction(
  UPDATE_DETAIL_ALERT_STATE,
  (status) => status,
);

export const updateLanguageAlertState = createAction(
  UPDATE_LANGUAGE_ALERT_STATE,
  (status) => status,
);

export const updateRetryAlertState = createAction(
  UPDATE_RETRY_ALERT_STATE,
  (status) => status,
);

export const updateCommonState = createAction(
  UPDATE_COMMON_STATE,
  ({ key, value }) => ({
    key,
    value,
  }),
);

const intialState = {
  alert: {
    delete: false,
    download: false,
    pwdUpdate: false,
    detail: false,
    retry: false,
    language: false,
  },
  otpEnabled: false,
  tokenSilent: false,
};

const common = handleActions(
  {
    [UPDATE_DELETE_ALERT_STATE]: (state, { payload: status }) => ({
      ...state,
      alert: {
        delete: status,
        download: state.alert.download,
        pwdUpdate: state.alert.pwdUpdate,
        detail: state.alert.detail,
        retry: state.alert.retry,
        language: state.alert.language,
      },
    }),
    [UPDATE_DOWNLOAD_ALERT_STATE]: (state, { payload: status }) => ({
      ...state,
      alert: {
        delete: state.alert.delete,
        download: status,
        pwdUpdate: state.alert.pwdUpdate,
        detail: state.alert.detail,
        retry: state.alert.retry,
        language: state.alert.language,
      },
    }),
    [UPDATE_PASSWORD_UPDATE_ALERT_STATE]: (state, { payload: status }) => ({
      ...state,
      alert: {
        delete: state.alert.delete,
        download: state.alert.download,
        pwdUpdate: status,
        detail: state.alert.detail,
        retry: state.alert.retry,
        language: state.alert.language,
      },
    }),
    [UPDATE_DETAIL_ALERT_STATE]: (state, { payload: status }) => ({
      ...state,
      alert: {
        delete: state.alert.delete,
        download: state.alert.download,
        pwdUpdate: state.alert.pwdUpdate,
        detail: status,
        retry: state.alert.retry,
        language: state.alert.language,
      },
    }),
    [UPDATE_LANGUAGE_ALERT_STATE]: (state, { payload: status }) => ({
      ...state,
      alert: {
        delete: state.alert.delete,
        download: state.alert.download,
        pwdUpdate: state.alert.pwdUpdate,
        detail: state.alert.detail,
        retry: state.alert.retry,
        language: status,
      },
    }),
    [UPDATE_RETRY_ALERT_STATE]: (state, { payload: status }) => ({
      ...state,
      alert: {
        delete: state.alert.delete,
        download: state.alert.download,
        pwdUpdate: state.alert.pwdUpdate,
        detail: state.alert.detail,
        retry: status,
        language: state.alert.language,
      },
    }),
    [UPDATE_COMMON_STATE]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft[key] = value;
      }),
  },
  intialState,
);

export default common;
