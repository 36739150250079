import MDBadge from 'components/otis/MDBadge';
import MDBox from 'components/otis/MDBox';

function StatusCell({ value, color }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox ml={1}>
        <MDBadge
          variant="contained"
          color={color ? color : 'dark'}
          badgeContent={value ? value : ''}
          container
        />
      </MDBox>
    </MDBox>
  );
}

export default StatusCell;
