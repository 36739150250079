import { Container, Link } from '@mui/material';
import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
  return (
    <MDBox position="absolute" width="100%" bottom={0} py={4}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: 'column', lg: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          px={1.5}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color="white"
            fontSize="small"
          >
            COPYRIGHT &copy;{new Date().getFullYear()},
            <Link
              href="http://isonesoft.com/"
              target="_blank"
              sx={{ m: 0, p: 0 }}
            >
              <MDTypography variant="button" fontWeight="medium" color="white">
                &nbsp;ISONESOFT&nbsp;
              </MDTypography>
            </Link>
            ALL RIGHTS RESERVED.
          </MDBox>
          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              listStyle: 'none',
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up('lg')]: {
                mt: 0,
              },
            })}
          >
            <MDBox component="li" px={2} lineHeight={1}>
              <MDTypography variant="button" fontWeight="regular" color="white">
                <FormattedMessage id="ask" /> : tech@isonesoft.com
              </MDTypography>
            </MDBox>
            {/* <MDBox component="li" pl={2} lineHeight={1}>
              <MDTypography variant="button" fontWeight="regular" color="white">
                License
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
};

export default Footer;
