import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
} from '@mui/material';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDEditor from 'components/otis/MDEditor';
import MDTypography from 'components/otis/MDTypography';
import { changeView, getDevGuideFileDownLoad } from 'modules/devguides';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const DevGuideView = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.devguide.devguide);
  const devguide = useSelector((state) => state.devguide.devguide.data);
  const view = useSelector((state) => state.devguide.view);
  const handleFormClose = () => {
    dispatch(changeView({ status: false }));
  };
  const onClickFileDownload = (fileName) => {
    dispatch(getDevGuideFileDownLoad(fileName, devguide.id));
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Dialog open={view} maxWidth="md">
          <DialogTitle>
            <FormattedMessage id="view-dev-guide" />
          </DialogTitle>
          <DialogContent>
            <MDBox>
              <FormControl
                component="fieldset"
                variant="standard"
                sx={{ m: 1, width: '80ch' }}
              >
                <FormGroup>
                  <MDBox>
                    <MDTypography> {devguide.title}</MDTypography>
                    <MDEditor
                      placeholder="Contents"
                      readOnly={true}
                      style={{ border: 'none' }}
                      value={devguide.contentHtml ? devguide.contentHtml : ''}
                      onChange={(data) => {
                        //onChangedContent('content', data);
                      }}
                      modules={{
                        toolbar: false,
                      }}
                    />
                  </MDBox>

                  <FileUploadView onClickFileDownload={onClickFileDownload} />
                </FormGroup>
              </FormControl>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleFormClose}>취소</MDButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export function FileUploadView({ onClickFileDownload }) {
  const devguide = useSelector((state) => state.devguide.devguide.data);
  const fileList = devguide === null ? null : devguide.attachFileList;

  const useStyles = {
    textDecoration: 'underline black',
  };
  return (
    <>
      <MDTypography
        sx={{ color: 'text.secondary', margin: 1 }}
        variant="button"
        verticalAlign="bottom"
        fontWeight="bold"
      >
        {' '}
        <FormattedMessage id="attach-file" />{' '}
      </MDTypography>
      {fileList &&
        fileList.map((item) => (
          <Container key={item.id} style={useStyles}>
            {item.fileName && (
              <MDTypography
                variant="button"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onClickFileDownload(item.fileName);
                }}
              >
                {item.fileName}
              </MDTypography>
            )}
          </Container>
        ))}
    </>
  );
}

export default DevGuideView;
