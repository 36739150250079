import produce from 'immer';
import * as api from 'lib/api/devguides';
import { getUserId } from 'lib/utils/auth';
import { createAction, handleActions } from 'redux-actions';

const PAGE_OUT = 'devguides/PAGE_OUT';

const GET_DEVGUIDE_LIST = 'devguides/GET_DEVGUIDE_LIST';
const GET_DEVGUIDE_LIST_SUCCESS = 'devguides/GET_DEVGUIDE_LIST_SUCCESS';
const GET_DEVGUIDE_LIST_FAILURE = 'devguides/GET_DEVGUIDE_LIST_FAILURE';

const GET_DEVGUIDE = 'devguides/GET_DEVGUIDE';
const GET_DEVGUIDE_SUCCESS = 'devguides/GET_DEVGUIDE_SUCCESS';
const GET_DEVGUIDE_FAILURE = 'devguides/GET_DEVGUIDE_FAILURE';

const GET_DEVGUIDE_DETAIL = 'devguides/GET_DEVGUIDE_DETAIL';
const GET_DEVGUIDE_DETAIL_SUCCESS = 'devguides/GET_DEVGUIDE_DETAIL_SUCCESS';
const GET_DEVGUIDE_DETAIL_FAILURE = 'devguides/GET_DEVGUIDE_DETAIL_FAILURE';

const POST_DEVGUIDE = 'devguides/POST_DEVGUIDE';
const POST_DEVGUIDE_SUCCESS = 'devguides/POST_DEVGUIDE_SUCCESS';
const POST_DEVGUIDE_FAILURE = 'devguides/POST_DEVGUIDE_FAILURE';

const PUT_DEVGUIDE = 'devguides/PUT_DEVGUIDE';
const PUT_DEVGUIDE_SUCCESS = 'devguides/PUT_DEVGUIDE_SUCCESS';
const PUT_DEVGUIDE_FAILURE = 'devguides/PUT_DEVGUIDE_FAILURE';

const DELETE_DEVGUIDE = 'devguides/DELETE_DEVGUIDE';
const DELETE_DEVGUIDE_SUCCESS = 'devguides/DELETE_DEVGUIDE_SUCCESS';
const DELETE_DEVGUIDE_FAILURE = 'devguides/DELETE_DEVGUIDE_FAILURE';

const CHANGE_SEARCH_FIELD = 'devguides/CHANGE_SEARCH_FIELD';
const CHANGE_FORM = 'devguides/CHANGE_FORM';
const INITIAL_DEVGUIDE = 'devguides/INITIAL_DEVGUIDE';
const CHANGE_FIELD = 'devguides/CHANGE_FIELD';
const POST_DEVGUIDE_UPLOAD = 'devguides/POST_DEVGUIDE_UPLOAD';
const POST_DEVGUIDE_UPLOAD_SUCCESS = 'devguides/POST_DEVGUIDE_UPLOAD_SUCCESS';
const POST_DEVGUIDE_UPLOAD_FAILURE = 'devguides/POST_DEVGUIDE_UPLOAD_FAILURE';
const DELETE_DEVGUIDE_FILE = 'devguides/DELETE_DEVGUIDEFILE';
const DELETE_DEVGUIDE_FILE_SUCCESS = 'devguides/DELETE_DEVGUIDEFILE_SUCCESS';
const DELETE_DEVGUIDE_FILE_FAILURE = 'devguides/DELETE_DEVGUIDEFILE_FAILURE';
const GET_DEVGUIDE_FILE_DOWNLOAD = 'devguides/GET_DEVGUIDE_FILE_DOWNLOAD';
const GET_DEVGUIDE_FILE_DOWNLOAD_FAILURE =
  'devguides/GET_DEVGUIDE_FILE_DOWNLOAD_FAILURE';
const GET_DEVGUIDE_FILE_DOWNLOAD_SUCCESS =
  'devguides/GET_DEVGUIDE_FILE_DOWNLOAD_SUCCESS';
const CHANGE_VIEW = 'devguides/CHANGE_VIEW';
const GET_DEVGUIDE_ZIP_DOWNLOAD = 'devguides/GET_DEVGUIDE_ZIP_DOWNLOAD';
const GET_DEVGUIDE_ZIP_DOWNLOAD_SUCCESS =
  'devguides/GET_DEVGUIDE_ZIP_DOWNLOAD_SUCCESS';
const GET_DEVGUIDE_ZIP_DOWNLOAD_FAILURE =
  'devguides/GET_DEVGUIDE_ZIP_DOWNLOAD_FAILURE';

const initialState = {
  devguides: {
    loading: false,
    data: null,
    error: null,
  },
  devguide: {
    loading: false,
    data: {
      id: null,
      title: null,
      content: null,
      userId: getUserId(),
      userName: null,
      createDt: null,
      updateDt: null,
      contentHtml: null,
      attachFileList: [],
    },
  },
  guildFile: [],
  form: false,
  fetch: true,
  view: false,
  mode: 'create',
};

export const pageOut = createAction(PAGE_OUT);

export const changeSearchField = createAction(
  CHANGE_SEARCH_FIELD,
  ({ name }) => ({
    name,
  }),
);
export const changeForm = createAction(CHANGE_FORM, ({ status }) => ({
  status,
}));

export const changeView = createAction(CHANGE_VIEW, ({ status }) => ({
  status,
}));
export const initialDevGuide = createAction(INITIAL_DEVGUIDE);

export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));

//#endregion

// #region CRUD
export const getDevGuideList = () => async (dispatch) => {
  dispatch({ type: GET_DEVGUIDE_LIST });
  try {
    const response = await api.getDevGuideList();
    dispatch({ type: GET_DEVGUIDE_LIST_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_DEVGUIDE_LIST_FAILURE, error: e.response });
    throw e;
  }
};

export const getDevGuide = (id) => async (dispatch) => {
  dispatch({ type: GET_DEVGUIDE });
  try {
    const response = await api.getDevGuide(id);
    dispatch({ type: GET_DEVGUIDE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: GET_DEVGUIDE_FAILURE, error: e.response });
    throw e;
  }
};

export const getDevGuideFileDownLoad = (fileName, id) => async (dispatch) => {
  dispatch({ type: GET_DEVGUIDE_FILE_DOWNLOAD });
  try {
    const response = await api.getDevGuideFileDownLoad(fileName, id);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    //fileDownload(await (await new Response(response.data)).blob(), fileName);
    dispatch({ type: GET_DEVGUIDE_FILE_DOWNLOAD_SUCCESS });
  } catch (e) {
    dispatch({ type: GET_DEVGUIDE_FILE_DOWNLOAD_FAILURE, error: e.response });
    throw e;
  }
};

export const getDevGuideDetail = (id) => async (dispatch) => {
  dispatch({ type: GET_DEVGUIDE_DETAIL });
  try {
    const response = await api.getDevGuide(id);
    dispatch({
      type: GET_DEVGUIDE_DETAIL_SUCCESS,
      payload: response.data.data,
    });
  } catch (e) {
    dispatch({ type: GET_DEVGUIDE_DETAIL_FAILURE, error: e.response });
    throw e;
  }
};

export const postDevGuide = (formData) => async (dispatch) => {
  dispatch({ type: POST_DEVGUIDE });
  try {
    const response = await api.postDevGuide(formData);
    dispatch({ type: POST_DEVGUIDE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: POST_DEVGUIDE_FAILURE, error: e.response });
    throw e;
  }
};

export const postDevGuideUpload = (files) => async (dispatch) => {
  dispatch({ type: POST_DEVGUIDE_UPLOAD });
  try {
    await api.postDevGuideUpload(files);
    dispatch({ type: POST_DEVGUIDE_UPLOAD_SUCCESS });
  } catch (e) {
    dispatch({ type: POST_DEVGUIDE_UPLOAD_FAILURE, error: e.response });
    throw e;
  }
};

export const putDevGuide = (id, devguide) => async (dispatch) => {
  dispatch({ type: PUT_DEVGUIDE });
  try {
    await api.putDevGuide(id, devguide);
    dispatch({ type: PUT_DEVGUIDE_SUCCESS, payload: devguide });
  } catch (e) {
    dispatch({ type: PUT_DEVGUIDE_FAILURE, error: e.response });
  }
};

export const deleteDevGuide = (id) => async (dispatch) => {
  dispatch({ type: DELETE_DEVGUIDE });
  try {
    const response = await api.deleteDevGuide(id);
    dispatch({ type: DELETE_DEVGUIDE_SUCCESS, payload: response.data.data });
  } catch (e) {
    dispatch({ type: DELETE_DEVGUIDE_FAILURE, error: e.response });
    throw e;
  }
};

export const deleteDevGuideFile = (fileName, id) => async (dispatch) => {
  dispatch({ type: DELETE_DEVGUIDE_FILE });
  try {
    await api.deleteDevGuideFile(fileName, id);
    dispatch({ type: DELETE_DEVGUIDE_FILE_SUCCESS });
  } catch (e) {
    dispatch({ type: DELETE_DEVGUIDE_FILE_FAILURE, error: e.response });
    throw e;
  }
};

export const getDevGuideZipDownLoad = (id) => async (dispatch) => {
  dispatch({ type: GET_DEVGUIDE_ZIP_DOWNLOAD });
  try {
    const response = await api.downloadDevGuideZip(id);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'GUIDE_' + id + '.zip');
    document.body.appendChild(link);
    link.click();
    dispatch({ type: GET_DEVGUIDE_ZIP_DOWNLOAD_SUCCESS });
  } catch (e) {
    dispatch({ type: GET_DEVGUIDE_ZIP_DOWNLOAD_FAILURE, error: e.response });
    throw e;
  }
};

//#endregion
//deleteDevGuideFile
const devguide = handleActions(
  {
    [PAGE_OUT]: (state) => ({
      ...state,
      fetch: true,
    }),
    [INITIAL_DEVGUIDE]: (state) => ({
      ...state,
      devguide: {
        data: initialState.devguide.data,
      },
      mode: 'create',
    }),

    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft.devguide.data[key] = value;
      }),
    [CHANGE_FORM]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.form = status;
      }),
    [CHANGE_VIEW]: (state, { payload: { status } }) =>
      produce(state, (draft) => {
        draft.view = status;
      }),
    [CHANGE_SEARCH_FIELD]: (state, { payload: { name } }) =>
      produce(state, (draft) => {
        draft.search.name = name;
        draft.fetch = true;
      }),

    [GET_DEVGUIDE_LIST]: (state) => ({
      ...state,
      devguides: {
        loading: true,
        data: null,
        error: null,
      },
    }),
    [GET_DEVGUIDE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      devguides: {
        loading: false,
        data: action.payload,
        error: null,
      },
      totalCount: action.payload.totalElements,
      fetch: false,
    }),
    [GET_DEVGUIDE_LIST_FAILURE]: (state = initialState, action) => ({
      ...state,
      devguides: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),

    [GET_DEVGUIDE_FILE_DOWNLOAD]: (state, action) => ({
      ...state,
    }),
    [GET_DEVGUIDE_FILE_DOWNLOAD_SUCCESS]: (state, { payload: devguide }) => ({
      ...state,
    }),
    [GET_DEVGUIDE_FILE_DOWNLOAD_FAILURE]: (state, action) => ({
      ...state,
    }),
    [GET_DEVGUIDE_ZIP_DOWNLOAD]: (state, action) => ({
      ...state,
    }),
    [GET_DEVGUIDE_ZIP_DOWNLOAD_SUCCESS]: (state, { payload: devguide }) => ({
      ...state,
    }),
    [GET_DEVGUIDE_ZIP_DOWNLOAD_FAILURE]: (state, action) => ({
      ...state,
    }),
    //
    [GET_DEVGUIDE_DETAIL]: (state, action) => ({
      ...state,
      devguide: {
        loading: true,
        data: state.devguides.data,
        error: null,
      },
    }),
    [GET_DEVGUIDE_DETAIL_SUCCESS]: (state, { payload: devguide }) => ({
      ...state,
      devguide: {
        loading: false,
        data: {
          ...devguide,
          contentHtml: decodeURIComponent(
            escape(window.atob(devguide.content)),
          ),
        },
        error: null,
      },
      mode: 'view',
    }),
    [GET_DEVGUIDE_DETAIL_FAILURE]: (state, action) => ({
      ...state,
      devguide: {
        loading: false,
        data: null,
        error: action.error,
      },
    }),

    [GET_DEVGUIDE]: (state, action) => ({
      ...state,
      devguide: {
        loading: true,
        data: state.devguides.data,
        error: null,
      },
    }),
    [GET_DEVGUIDE_SUCCESS]: (state, { payload: devguide }) => ({
      ...state,
      devguide: {
        loading: false,
        data: {
          ...devguide,
          contentHtml: decodeURIComponent(
            escape(window.atob(devguide.content)),
          ),
        },
        error: null,
      },
      mode: 'update',
    }),
    [GET_DEVGUIDE_FAILURE]: (state, action) => ({
      ...state,
      devguide: {
        loading: false,
        data: state.devguides.data,
        error: action.error,
      },
    }),
    [POST_DEVGUIDE]: (state, action) => ({
      ...state,
      devguide: {
        loading: true,
        data: state.devguides.data,
        error: null,
      },
    }),
    [POST_DEVGUIDE_SUCCESS]: (state, action) => ({
      ...state,
      devguide: {
        loading: false,
        data: state.devguides.data.concat(action.payload),

        error: null,
      },
      totalCount: state.totalCount + 1,
      fetch: true,
    }),
    [POST_DEVGUIDE_FAILURE]: (state, action) => ({
      ...state,
      devguide: {
        loading: false,
        data: state.devguide.data,
        error: action.error,
      },
    }),

    [INITIAL_DEVGUIDE]: (state) => ({
      ...state,
      devguide: {
        data: initialState.devguide.data,
      },
      mode: 'create',
    }),

    [POST_DEVGUIDE_UPLOAD]: (state, action) => ({
      ...state,
    }),
    [POST_DEVGUIDE_UPLOAD_SUCCESS]: (state, action) => ({
      ...state,
      guildFile: null,
    }),
    [POST_DEVGUIDE_UPLOAD_FAILURE]: (state, action) => ({
      ...state,
      guildFile: null,
    }),

    [PUT_DEVGUIDE]: (state, action) => ({
      ...state,
      devguide: {
        loading: true,
        data: state.devguide.data,
        error: null,
      },
    }),
    [PUT_DEVGUIDE_SUCCESS]: (state, action) => ({
      ...state,
      devguide: {
        loading: false,
        data: state.devguides.data.map((devguide) =>
          devguide.id === action.payload.id ? { ...action.payload } : devguide,
        ),
        error: null,
      },
      fetch: true,
    }),
    [PUT_DEVGUIDE_FAILURE]: (state, action) => ({
      ...state,
      devguide: {
        loading: false,
        data: state.devguide.data,
        error: action.error,
      },
    }),
    [DELETE_DEVGUIDE]: (state, action) => ({
      ...state,
      devguides: {
        loading: false,
        data: state.devguides.data,
        error: null,
      },
    }),
    [DELETE_DEVGUIDE_SUCCESS]: (state, action) => ({
      ...state,
      devguides: {
        loading: false,
        data: state.devguides.data.filter(
          (devguide) => devguide.id !== action.payload,
        ),
        error: null,
      },
      fetch: true,
    }),
    [DELETE_DEVGUIDE_FAILURE]: (state, action) => ({
      ...state,
      devguides: {
        loading: false,
        data: state.devguides.data,
        error: action.error,
      },
    }),
    [DELETE_DEVGUIDE_FILE]: (state, action) => ({
      ...state,
      devguides: {
        loading: false,
        data: state.devguides.data,
        error: null,
      },
    }),
    [DELETE_DEVGUIDE_FILE_SUCCESS]: (state) => ({
      ...state,
    }),
    [DELETE_DEVGUIDE_FILE_FAILURE]: (state, action) => ({
      ...state,
      devguides: {
        loading: false,
        data: state.devguides.data,
        error: action.error,
      },
    }),
  },
  initialState,
);

export default devguide;
