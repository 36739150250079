import { FormattedMessage } from 'react-intl';

export const validPasswordRule = (password) => {
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;
  const maxLengthRegExp = /.{16}$/;
  const passwordLength = password.length;
  const uppercasePassword = uppercaseRegExp.test(password);
  const lowercasePassword = lowercaseRegExp.test(password);
  const digitsPassword = digitsRegExp.test(password);
  const specialCharPassword = specialCharRegExp.test(password);
  const minLengthPassword = minLengthRegExp.test(password);
  const maxLengthPassword = maxLengthRegExp.test(password);
  let errMsg = '';

  if (passwordLength === 0) {
    errMsg = <FormattedMessage id="valid-password-input" />;
  } else if (!uppercasePassword) {
    errMsg = <FormattedMessage id="valid-password-uppercase" />;
  } else if (!lowercasePassword) {
    errMsg = <FormattedMessage id="valid-password-lowercase" />;
  } else if (!digitsPassword) {
    errMsg = <FormattedMessage id="valid-password-digits" />;
  } else if (!specialCharPassword) {
    errMsg = <FormattedMessage id="valid-password-specialchar" />;
  } else if (!minLengthPassword) {
    errMsg = <FormattedMessage id="valid-password-min-Length" />;
  } else if (maxLengthPassword) {
    errMsg = <FormattedMessage id="valid-password-max-Length" />;
  } else {
    errMsg = '';
  }
  return errMsg;
};
