import PageviewIcon from '@mui/icons-material/Pageview';
import { IconButton } from '@mui/material';
import MDBox from 'components/otis/MDBox';

function DetailCell({ handleDetailAlertOpen }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox>
        <IconButton
          aria-label="detail"
          size="small"
          onClick={handleDetailAlertOpen}
        >
          <PageviewIcon fontSize="small" />
        </IconButton>
      </MDBox>
    </MDBox>
  );
}

export default DetailCell;
