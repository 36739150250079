import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDInput from 'components/otis/MDInput';
import MDTypography from 'components/otis/MDTypography';
import { getModuleHistByWaitCountAndTime } from 'lib/api/modulehistories';
import { existSameModule } from 'lib/api/modules';
import {
  Algorithm,
  CreateOperation,
  CreateOs,
  ElementOperation,
  OperationMode,
  PeriodicTable,
} from 'lib/constants/ModuleElements';
import { RandomHexString } from 'lib/utils/initialized';
import { changeField, changeForm, postModule } from 'modules/modules';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FormattedMessage, useIntl } from 'react-intl';

const ModuleForm = () => {
  const elementIvRef = useRef();
  const elementIoRef = useRef();
  const dispatch = useDispatch();
  const intl = useIntl();
  let module = useSelector((state) => state.modules.module.data);
  const { loading, error } = useSelector((state) => state.modules.module);
  const mode = useSelector((state) => state.modules.mode);
  const form = useSelector((state) => state.modules.form);
  const [errors, setErrors] = useState({});
  const [detail, setDetail] = useState(false);

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  const handleOpenDetail = () => setDetail(!detail);

  const handleFormClose = () => {
    dispatch(changeForm({ status: false }));
    setErrors({});
    setDetail(false);
  };

  const createModule = () => {
    // 모듈 예정 완료 시간, 남은 대기 작업 수 표출
    getModuleHistByWaitCountAndTime()
      .then((res) => {
        let completeTime = moment(res.data.data.completeTime);
        if (module.algorithm) {
          switch (module.algorithm) {
            case 1:
            case 2:
              completeTime = moment(res.data.data.completeTime).add('2', 'h');
              break;
            case 3:
            case 4:
            case 5:
              completeTime = moment(res.data.data.completeTime).add('20', 'm');
              break;
            default:
              break;
          }
        }

        swal({
          title: intl.formatMessage({
            id: 'estimated-completion-time',
          }),
          text:
            intl.formatMessage({
              id: 'pending-module-creation-count',
            }) +
            res.data.data.countModule +
            intl.formatMessage({
              id: 'estimated-module-complete-time',
            }) +
            ' : ' +
            completeTime.format('YYYY-MM-DD HH:mm:ss'),
          buttons: intl.formatMessage({
            id: 'check',
          }),
        }).then((value) => {
          if (!module.masterKey) {
            module = {
              ...module,
              masterKey: RandomHexString(),
            };
          }
          dispatch(postModule(module));
          handleFormClose();
        });
      })
      .catch((error) => {
        swal(
          intl.formatMessage({
            id: 'server-error',
          }),
          error.response.data,
          'error',
        );
      });
  };

  const handleAdd = () => {
    if (onValdation()) {
      existSameModule(module)
        .then((res) => {
          if (!res.data.data) {
            createModule();
          } else {
            swal({
              title: intl.formatMessage({
                id: 'fail-module-exists-same',
              }),
              text: intl.formatMessage({
                id: 'valid-module-created-same-user',
              }),
              buttons: {
                cancel: intl.formatMessage({ id: 'cancel' }),
                update: {
                  text: intl.formatMessage({ id: 'retry' }),
                  value: 'true',
                },
              },
            }).then((btn) => {
              if (btn) {
                createModule();
              }
            });
          }
        })
        .catch((error) => {
          swal(
            intl.formatMessage({ id: 'server-error' }),
            error.response.data,
            'error',
          );
        });
    }
  };

  const onChanged = (e) => {
    dispatch(
      changeField({
        key: e.target.name,
        value: e.target.value,
      }),
    );
  };

  const handleInitialEncDenKey = (e) => {
    dispatch(
      changeField({
        key: 'encDecKey',
        value: RandomHexString(),
      }),
    );
  };

  const handleInitialCounter = () => {
    dispatch(
      changeField({
        key: 'counter',
        value: RandomHexString(),
      }),
    );
  };

  const handleChangeElementIV = (e, value) => {
    dispatch(
      changeField({
        key: elementIvRef.current.getAttribute('name'),
        value: value,
      }),
    );
  };

  const handleChangeElementIO = (e, value) => {
    dispatch(
      changeField({
        key: elementIoRef.current.getAttribute('name'),
        value: value,
      }),
    );
  };

  const onChangedElementOperation = (e) => {
    switch (e.target.value) {
      case 1:
        dispatch(changeField({ key: 'elementIv', value: 'NO' }));
        dispatch(changeField({ key: 'elementIo', value: 'NO' }));
        break;
      case 4:
        dispatch(changeField({ key: 'elementIv', value: '' }));
        dispatch(changeField({ key: 'elementIo', value: '' }));
        break;
      case 2:
        dispatch(changeField({ key: 'elementIv', value: '' }));
        dispatch(changeField({ key: 'elementIo', value: 'NO' }));
        break;
      case 3:
        dispatch(changeField({ key: 'elementIv', value: 'NO' }));
        dispatch(changeField({ key: 'elementIo', value: '' }));
        break;
      default:
        break;
    }
    onChanged(e);
  };

  const onValdation = () => {
    let temp = {};
    temp.userId = module.userId ? (
      ''
    ) : (
      <FormattedMessage id="valid-user-select" />
    );
    temp.algorithm = module.algorithm ? (
      ''
    ) : (
      <FormattedMessage id="valid-algorithm-select" />
    );
    temp.packageName = module.packageName ? (
      ''
    ) : (
      <FormattedMessage id="valid-package-name-input" />
    );
    temp.createOs = module.createOs ? (
      ''
    ) : (
      <FormattedMessage id="valid-os-select" />
    );
    // temp.createOperation = module.createOperation ? (
    //   ''
    // ) : (
    //   <FormattedMessage id="valid-encdec-select" />
    // );

    if (module.packageName && module.packageName.length > 30) {
      temp.packageName = <FormattedMessage id="valid-package-name-length" />;
    }

    if (module.encDecKey) {
      temp.encDecKey =
        module.encDecKey.length === 32 ? (
          ''
        ) : (
          <FormattedMessage id="valid-encdeckey-length" />
        );
    } else {
      temp.encDecKey = <FormattedMessage id="valid-encdeckey-created" />;
    }

    if (module.counter) {
      temp.counter =
        module.counter.length === 32 ? (
          ''
        ) : (
          <FormattedMessage id="valid-counter-length" />
        );
    } else {
      temp.counter = <FormattedMessage id="valid-counter-created" />;
    }

    if (module.masterKey) {
      temp.masterKey =
        module.masterKey.length < 50 ? (
          ''
        ) : (
          <FormattedMessage id="valid-masterkey-length" />
        );
    }

    if (module.seed) {
      temp.seed =
        module.seed.length < 50 ? (
          ''
        ) : (
          <FormattedMessage id="valid-seed-length" />
        );
    }

    temp.operationMode = module.operationMode ? (
      ''
    ) : (
      <FormattedMessage id="user" />
    );
    if (module.elementOperation) {
      switch (module.elementOperation) {
        case 2:
          temp.elementIv = module.elementIv ? (
            ''
          ) : (
            <FormattedMessage id="valid-element-iv-input" />
          );
          break;
        case 3:
          temp.elementIo = module.elementIo ? (
            ''
          ) : (
            <FormattedMessage id="valid-element-io-input" />
          );
          break;
        case 4:
          temp.elementIo = module.elementIo ? (
            ''
          ) : (
            <FormattedMessage id="valid-element-io-input" />
          );
          temp.elementIv = module.elementIv ? (
            ''
          ) : (
            <FormattedMessage id="valid-element-iv-input" />
          );
          break;
        default:
          break;
      }
    } else {
      temp.elementOperation = (
        <FormattedMessage id="valid-element-operation-select" />
      );
    }

    setErrors({
      ...temp,
    });

    if (
      temp.elementOperation ||
      temp.elementIo ||
      temp.elementIv ||
      temp.masterKey ||
      temp.counter
    ) {
      setDetail(true);
    }
    return Object.values(temp).every((x) => x === '');
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Dialog open={form} maxWidth="md" fullWidth>
          <DialogTitle>
            {mode === 'create' ? (
              <FormattedMessage id="user" />
            ) : (
              <FormattedMessage id="user" />
            )}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} md={6}>
                <MDBox display="flex" alignItems="center">
                  <MDInput
                    id="packageName"
                    name="packageName"
                    label={<FormattedMessage id="package-name" />}
                    fullWidth
                    type="text"
                    variant="standard"
                    value={module.packageName ? module.packageName : ''}
                    onChange={onChanged}
                    required
                    sx={{ mb: 1, mt: 1 }}
                    {...(errors.packageName && {
                      error: true,
                      helperText: errors.packageName,
                      style: { minHeight: '1rem' },
                    })}
                  />
                  <Tooltip
                    title={<FormattedMessage id="package-classfication-name" />}
                    placement="bottom"
                    arrow
                  >
                    <MDButton
                      variant="outlined"
                      color="secondary"
                      size="small"
                      circular
                      iconOnly
                    >
                      <PriorityHighIcon />
                    </MDButton>
                  </Tooltip>
                </MDBox>
                <MDInput
                  id="algorithm"
                  name="algorithm"
                  label={<FormattedMessage id="algorithm" />}
                  type="text"
                  select
                  fullWidth
                  required
                  sx={{ mb: 1, mt: 1 }}
                  variant="standard"
                  value={module.algorithm ? module.algorithm : ''}
                  onChange={onChanged}
                  {...(errors.algorithm && {
                    error: true,
                    helperText: errors.algorithm,
                    style: { minHeight: '1rem' },
                  })}
                >
                  {Algorithm.map((element) => (
                    <MenuItem key={element.key} value={element.key}>
                      {element.value}
                    </MenuItem>
                  ))}
                </MDInput>
                <MDInput
                  id="createOs"
                  name="createOs"
                  label="OS"
                  type="text"
                  select
                  fullWidth
                  required
                  sx={{ mb: 1, mt: 1 }}
                  variant="standard"
                  value={module.createOs ? module.createOs : ''}
                  onChange={onChanged}
                  {...(errors.createOs && {
                    error: true,
                    helperText: errors.createOs,
                    style: { minHeight: '1rem' },
                  })}
                >
                  {CreateOs.map((element) => (
                    <MenuItem key={element.key} value={element.key}>
                      {element.value}
                    </MenuItem>
                  ))}
                </MDInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <MDBox display="flex" alignItems="center">
                  <MDInput
                    id="masterKey"
                    name="masterKey"
                    label="MasterKey"
                    type="text"
                    fullWidth
                    variant="standard"
                    sx={{ mb: 1, mt: 1 }}
                    value={module.masterKey ? module.masterKey : ''}
                    onChange={onChanged}
                    {...(errors.masterKey && {
                      error: true,
                      helperText: errors.masterKey,
                      style: { minHeight: '1rem' },
                    })}
                  />
                  <NoMaxWidthTooltip
                    title={
                      <span style={{ whiteSpace: 'pre-line' }}>
                        {<FormattedMessage id="tooltip-module-masterkey" />}
                      </span>
                    }
                    placement="bottom"
                    arrow
                  >
                    <MDButton
                      variant="outlined"
                      color="secondary"
                      size="small"
                      circular
                      iconOnly
                    >
                      <PriorityHighIcon />
                    </MDButton>
                  </NoMaxWidthTooltip>
                </MDBox>
                <MDInput
                  id="operationMode"
                  name="operationMode"
                  label={<FormattedMessage id="operation-mode" />}
                  type="text"
                  variant="standard"
                  select
                  fullWidth
                  required
                  sx={{ mb: 1, mt: 1 }}
                  value={module.operationMode ? module.operationMode : ''}
                  onChange={onChanged}
                  {...(errors.operationMode && {
                    error: true,
                    helperText: errors.operationMode,
                    style: { minHeight: '1rem' },
                  })}
                >
                  {module.algorithm === 3 || module.algorithm === null
                    ? OperationMode.map((element) => (
                        <MenuItem key={element.key} value={element.key}>
                          {element.value}
                        </MenuItem>
                      ))
                    : OperationMode.filter((element) => element.key !== 3).map(
                        (element) => (
                          <MenuItem key={element.key} value={element.key}>
                            {element.value}
                          </MenuItem>
                        ),
                      )}
                </MDInput>
                {/* <MDInput
                  id="createOperation"
                  name="createOperation"
                  label={<FormattedMessage id="encrypt-decrypt" />}
                  type="text"
                  variant="standard"
                  select
                  fullWidth
                  required
                  sx={{ mb: 1, mt: 1 }}
                  value={module.createOperation ? module.createOperation : ''}
                  onChange={onChanged}
                  {...(errors.createOperation && {
                    error: true,
                    helperText: errors.createOperation,
                    style: { minHeight: '1rem' },
                  })}
                >
                  {CreateOperation.map((element) => (
                    <MenuItem key={element.key} value={element.key}>
                      {element.value}
                    </MenuItem>
                  ))}
                </MDInput> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={2}
                px={1}
              >
                <MDTypography
                  variant="body2"
                  fontWeight="medium"
                  textTransform="capitalize"
                >
                  <FormattedMessage id="advanced-options" />
                </MDTypography>
                <MDTypography variant="body2" color="secondary">
                  <Tooltip
                    title={
                      detail ? (
                        <FormattedMessage id="arrow-up" />
                      ) : (
                        <FormattedMessage id="arrow-down" />
                      )
                    }
                    placement="right"
                  >
                    <IconButton
                      size="large"
                      disableRipple
                      color="inherit"
                      variant="contained"
                      onClick={handleOpenDetail}
                    >
                      {detail ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </MDTypography>
              </MDBox>
            </Grid>
            {detail && (
              <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} md={6}>
                  <MDBox
                    display="flex"
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                  >
                    <MDInput
                      id="encDecKey"
                      name="encDecKey"
                      label="EncDecKey"
                      type="text"
                      fullWidth
                      variant="standard"
                      sx={{ mb: 1, mt: 1 }}
                      value={module.encDecKey ? module.encDecKey : ''}
                      onChange={onChanged}
                      required
                      {...(errors.encDecKey && {
                        error: true,
                        helperText: errors.encDecKey,
                        style: { minHeight: '1rem' },
                      })}
                    />

                    <MDButton
                      color="info"
                      variant="contained"
                      sx={{ m: 1 }}
                      onClick={handleInitialEncDenKey}
                      size="small"
                    >
                      <FormattedMessage id="creation" />
                    </MDButton>
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                          <FormattedMessage id="tooltip-module-encdeckey" />
                        </span>
                      }
                      placement="bottom"
                      arrow
                    >
                      <MDButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        circular
                        iconOnly
                      >
                        <PriorityHighIcon />
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                  >
                    <MDInput
                      id="counter"
                      name="counter"
                      label="Counter"
                      type="text"
                      fullWidth
                      variant="standard"
                      sx={{ mb: 1, mt: 1 }}
                      value={module.counter ? module.counter : ''}
                      onChange={onChanged}
                      required
                      {...(errors.counter && {
                        error: true,
                        helperText: errors.counter,
                        style: { minHeight: '1rem' },
                      })}
                    />

                    <MDButton
                      variant="contained"
                      color="info"
                      sx={{ m: 1 }}
                      onClick={handleInitialCounter}
                      size="small"
                    >
                      <FormattedMessage id="creation" />
                    </MDButton>
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                          <FormattedMessage id="tooltip-module-counter" />
                        </span>
                      }
                      placement="bottom"
                      arrow
                    >
                      <MDButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        circular
                        iconOnly
                      >
                        <PriorityHighIcon />
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                  <MDBox display="flex" alignItems="center">
                    <MDInput
                      id="seed"
                      name="seed"
                      label="Seed"
                      type="text"
                      fullWidth
                      variant="standard"
                      sx={{ mb: 1, mt: 1 }}
                      value={module.seed ? module.seed : ''}
                      onChange={onChanged}
                      {...(errors.seed && {
                        error: true,
                        helperText: errors.seed,
                        style: { minHeight: '1rem' },
                      })}
                    />
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                          <FormattedMessage id="tooltip-module-seed" />
                        </span>
                      }
                      placement="bottom"
                      arrow
                    >
                      <MDButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        circular
                        iconOnly
                      >
                        <PriorityHighIcon />
                      </MDButton>
                    </Tooltip>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDInput
                    id="elementOperation"
                    name="elementOperation"
                    label="elementOperation"
                    type="text"
                    select
                    fullWidth
                    required
                    variant="standard"
                    sx={{ mb: 1, mt: 1 }}
                    value={
                      module.elementOperation ? module.elementOperation : ''
                    }
                    onChange={onChangedElementOperation}
                    {...(errors.elementOperation && {
                      error: true,
                      helperText: errors.elementOperation,
                      style: { minHeight: '1rem' },
                    })}
                  >
                    {module.operationMode !== 2
                      ? ElementOperation.map((element) => (
                          <MenuItem key={element.key} value={element.key}>
                            {element.value}
                          </MenuItem>
                        ))
                      : ElementOperation.filter(
                          (element) => element.key !== 2 && element.key !== 4,
                        ).map((element) => (
                          <MenuItem key={element.key} value={element.key}>
                            {element.value}
                          </MenuItem>
                        ))}
                  </MDInput>
                  <Autocomplete
                    ListboxProps={{
                      style: { maxHeight: '15rem' },
                      position: 'bottom-start',
                    }}
                    options={PeriodicTable}
                    getOptionDisabled={(option) =>
                      module.elementOperation === 2 ||
                      module.elementOperation === 4
                        ? option === PeriodicTable[0]
                        : option !== PeriodicTable[0]
                    }
                    value={module.elementIv ? module.elementIv : ''}
                    onChange={handleChangeElementIV}
                    name="elementIv"
                    id="elementIv"
                    ref={elementIvRef}
                    disableClearable={
                      module.elementOperation === 1 ||
                      module.elementOperation === 3
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ mb: 1, mt: 1 }}
                        {...params}
                        label="ElementIV"
                        variant="standard"
                        required
                        {...(errors.elementIv && {
                          error: true,
                          helperText: errors.elementIv,
                          style: { minHeight: '1rem' },
                        })}
                      />
                    )}
                  />
                  <Autocomplete
                    ListboxProps={{
                      style: { maxHeight: '15rem' },
                      position: 'bottom-start',
                    }}
                    options={PeriodicTable}
                    getOptionDisabled={(option) =>
                      module.elementOperation === 3 ||
                      module.elementOperation === 4
                        ? option === PeriodicTable[0]
                        : option !== PeriodicTable[0]
                    }
                    value={module.elementIo ? module.elementIo : ''}
                    onChange={handleChangeElementIO}
                    name="elementIo"
                    id="elementIo"
                    ref={elementIoRef}
                    disableClearable={
                      module.elementOperation === 1 ||
                      module.elementOperation === 2
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ mb: 1, mt: 1 }}
                        {...params}
                        label="ElementIO"
                        variant="standard"
                        required
                        {...(errors.elementIo && {
                          error: true,
                          helperText: errors.elementIo,
                          style: { minHeight: '1rem' },
                        })}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleAdd}>
              <FormattedMessage id="save" />
            </MDButton>
            <MDButton onClick={handleFormClose}>
              <FormattedMessage id="cancel" />
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ModuleForm;
