import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDEditor from 'components/otis/MDEditor';
import MDInput from 'components/otis/MDInput';
import {
  changeMailBodyHtml,
  changeSetting,
  putSetting,
} from 'modules/settings';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { FormattedMessage, useIntl } from 'react-intl';

const Mailing = (props) => {
  const { handleSettingChange } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { data, error } = useSelector((state) => state.settings.settings);

  const onChangedUsedSendModuleInfo = (e) => {
    dispatch(
      changeSetting({
        key: e.target.name,
        value: e.target.checked,
      }),
    );
  };

  const onChangedContent = (fieldkey, fieldvalue) => {
    dispatch(
      changeMailBodyHtml({
        value: fieldvalue,
      }),
    );

    dispatch(
      changeSetting({
        key: fieldkey,
        value: window.btoa(unescape(encodeURIComponent(fieldvalue))),
      }),
    );
  };

  const handleSettingSave = async () => {
    dispatch(putSetting(data.mailTitle));
    dispatch(putSetting(data.mailBody));
    dispatch(putSetting(data.usedSendModuleInfo));
    if (!error) {
      swal(
        intl.formatMessage({
          id: 'change-settings',
        }),
        intl.formatMessage({
          id: 'success-change-mailing-settings',
        }),
        'success',
        {
          buttons: false,
          timer: 1000,
        },
      );
    } else {
      swal(
        intl.formatMessage({
          id: 'change-settings',
        }),
        error.response.data,
        'error',
      );
    }
  };

  return (
    <Card>
      <CardHeader title={<FormattedMessage id="mailing-information" />} />
      <Divider />
      <CardContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="usedSendModuleInfo"
                checked={
                  data.usedSendModuleInfo
                    ? JSON.parse(data.usedSendModuleInfo.value)
                    : true
                }
                onChange={onChangedUsedSendModuleInfo}
              />
            }
            label={<FormattedMessage id="module-info-transmission-enable" />}
          />
        </FormGroup>
        <MDBox sx={{ mt: 2 }}>
          <MDInput
            fullWidth
            label={<FormattedMessage id="title" />}
            margin="normal"
            name="mailTitle"
            type="text"
            variant="outlined"
            value={data.mailTitle ? data.mailTitle.value : ''}
            onChange={handleSettingChange}
          />
        </MDBox>
        <MDBox sx={{ mt: 2 }}>
          <MDEditor
            value={data.mailBodyHtml ? data.mailBodyHtml : ''}
            placeholder={<FormattedMessage id="body" />}
            onChange={(data) => {
              onChangedContent('mailBody', data);
            }}
          />
        </MDBox>
      </CardContent>
      <Divider />
      <MDBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <MDButton variant="contained" onClick={handleSettingSave}>
          <FormattedMessage id="save" />
        </MDButton>
      </MDBox>
    </Card>
  );
};

export default Mailing;
