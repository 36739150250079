import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
} from '@mui/material';
import MDButton from 'components/otis/MDButton';
import MDInput from 'components/otis/MDInput';
import { passwordUpdate } from 'lib/api/users';
import { getUserId, removeToken } from 'lib/utils/auth';
import { validPasswordRule } from 'lib/utils/password';
import { updatePasswordUpdateAlertState } from 'modules/common';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { useIntl, FormattedMessage } from 'react-intl';

const PasswordUpdate = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const passwordUpdateState = useSelector(
    (state) => state.common.alert.pwdUpdate,
  );
  const [errors, setErrors] = useState({});

  const handlePasswordUpdateAlertClose = () => {
    dispatch(updatePasswordUpdateAlertState(false));
  };

  const handlePasswordUpdate = async () => {
    if (onValidation()) {
      try {
        await passwordUpdate(getUserId(), password);
        swal(
          intl.formatMessage({
            id: 'password-change',
          }),
          intl.formatMessage({
            id: 'password-change-success',
          }) +
            '\n' +
            intl.formatMessage({
              id: 'valid-login-again',
            }),
          'success',
          {
            buttons: false,
            timer: 2000,
          },
        ).then((value) => {
          removeToken();
          handlePasswordUpdateAlertClose();
          navigate('/');
        });
      } catch (error) {
        swal(
          intl.formatMessage({
            id: 'password-update',
          }),
          error.response.data,
          'error',
        );
      }
    }
  };

  const onChangedPassword = (e) => setPassword(e.target.value);
  const onChangedPasswordConfirm = (e) => setPasswordConfirm(e.target.value);

  const onPasswordExpressionValidation = (e) => {
    setErrors({ ...errors, password: validPasswordRule(e.target.value) });
  };

  const onValidation = () => {
    let temp = {};

    temp.password = password ? (
      ''
    ) : (
      <FormattedMessage id="valid-password-input" />
    );
    temp.passwordConfirm = passwordConfirm ? (
      ''
    ) : (
      <FormattedMessage id="valid-password-input" />
    );

    if (password && passwordConfirm) {
      if (password !== passwordConfirm) {
        temp.passwordConfirm = (
          <FormattedMessage id="valid-password-different" />
        );
      }
    }

    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === '');
  };

  return (
    <Dialog open={passwordUpdateState} maxWidth="xs" fullWidth>
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="password-change" />
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControl variant="standard" sx={{ m: 2, width: '30inch' }}>
            <MDInput
              id="password"
              name="password"
              label={<FormattedMessage id="password" />}
              type="password"
              variant="standard"
              value={password}
              onChange={onChangedPassword}
              onKeyUp={onPasswordExpressionValidation}
              sx={{ mb: 2 }}
              required
              {...(errors.password && {
                error: true,
                helperText: errors.password,
                style: { minHeight: '1rem' },
              })}
            />
            <MDInput
              id="password_confirm"
              name="password_confirm"
              label={<FormattedMessage id="password-confirm" />}
              type="password"
              variant="standard"
              value={passwordConfirm}
              fullWidth
              onChange={onChangedPasswordConfirm}
              required
              {...(errors.passwordConfirm && {
                error: true,
                helperText: errors.passwordConfirm,
                style: { minHeight: '1rem' },
              })}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handlePasswordUpdate}>
          <FormattedMessage id="edit" />
        </MDButton>
        <MDButton onClick={handlePasswordUpdateAlertClose}>
          <FormattedMessage id="cancel" />
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordUpdate;
