/* eslint-disable import/no-anonymous-default-export */
/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Otis Admin PRO React base styles
import colors from 'assets/theme/base/colors';

const { transparent } = colors;

export default {
  styleOverrides: {
    select: {
      display: 'grid',
      alignItems: 'center',
      // padding: `0 ${pxToRem(12)} !important`,
      '& .Mui-selected': {
        backgroundColor: transparent.main,
      },
    },
    icon: {
      width: 24,
      height: 24,
      top: -1,
    },
    // selectMenu: {
    //   background: 'none',
    //   height: 'none',
    //   minHeight: 'none',
    //   overflow: 'unset',
    // },
    // icon: {
    //   display: 'none',
    // },
  },
};
