import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';

function ExpiredCell({ value, name }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox ml={1}>
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {value ? value : ''}
          {name ? name : ''}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default ExpiredCell;
