import axios from 'axios';

export const getModuleList = () => axios.get(`/api/modules`);
export const postModule = (module) => axios.post('/api/modules', module);
export const deleteModule = (id) => axios.delete(`/api/modules/${id}`);
export const existUser = (userId) =>
  axios.get(`/api/modules/exist/user/${userId}`);
export const downloadModule = (id) =>
  axios.get(`/api/modules/download/${id}`, { responseType: 'arraybuffer' });
export const existSameModule = (module) =>
  axios.get(`/api/modules/exist/module`, {
    params: {
      module: {
        algorithm: module.algorithm,
        operationMode: module.operationMode,
        elementOperation: module.elementOperation,
        createOs: module.createOs,
        createOperation: module.createOperation,
      },
    },
  });

export const validModuleCreate = (userId) =>
  axios.get(`/api/modules/valid/module/create/${userId}`);
