/* eslint-disable */
import { Card } from '@mui/material';
import { useCallback, useEffect } from 'react';

import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import { ed_date, st_date } from 'components/common/SearchDateControl'; //검색 시작일자 종료일자 기본값
import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import {
  changeSearchEndDate,
  changeSearchStartDate,
  getSystemerrors,
  pageOut,
} from 'modules/systemerrors';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const SystemerrorBody = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.systemerrors.systemerrors,
  );

  const fetch = useSelector((state) => state.systemerrors.fetch);

  const unLoadSystemError = useCallback(() => dispatch(pageOut()), [dispatch]);

  //useEffect Function
  const loadSystemError = useCallback(
    () => dispatch(getSystemerrors(st_date, ed_date)),
    [dispatch],
  );
  useEffect(() => {
    dispatch(changeSearchStartDate({ startDt: st_date }));
    dispatch(changeSearchEndDate({ endDt: ed_date }));

    if (fetch) {
      loadSystemError();
    }
    return () => {
      if (!fetch) unLoadSystemError();
    };
  }, [loadSystemError, unLoadSystemError, fetch]);

  const SystemErrorData = {
    columns: [
      {
        Header: <FormattedMessage id="occurrence-date" />,
        accessor: 'errorDt',
        sort: true,
        width: '20%',
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="error" />,
        accessor: 'error',
        sort: true,
        width: '20%',
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="contents" />,
        accessor: 'message',
        sort: false,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: data,
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <Card>
      <DataTable table={SystemErrorData} entriesPerPage={false} canSearch />
    </Card>
  );
};

export default SystemerrorBody;
