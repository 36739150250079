import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React, { useState } from 'react';

const Error = (props) => {
  const { error } = props;
  const [open, setOpen] = useState(true);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose}>
        <strong>{error.status} - </strong>
        {error.data}
      </Alert>
    </Snackbar>
  );
};

export default Error;
