/* eslint-disable */
import { Card } from '@mui/material';
import Error from 'components/common/error/Error';
import Loading from 'components/common/Loading';
import {
  changeSearchEndDate,
  changeSearchStartDate,
  getModulehistories,
  pageOut,
} from 'modules/modulehistories';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RetryAlert from 'components/common/alert/RetryAlert';
import DetailAlert from 'components/common/DetailAlert';
import { ed_date, st_date } from 'components/common/SearchDateControl'; //검색 시작일자 종료일자 기본값
import DateCell from 'components/datacell/DateCell';
import DefaultCell from 'components/datacell/DefaultCell';
import DetailCell from 'components/datacell/DetailCell';
import RetryCell from 'components/datacell/RetryCell';
import StatusCell from 'components/datacell/StatusCell';
import DataTable from 'components/otis/MDDataTable/DataTable';
import {
  getModulehistoryByModuleId,
  retryCreateModule,
} from 'lib/api/modulehistories';
import { filterByJson } from 'lib/utils/parse/common';
import {
  parseAlgorithm,
  parseCreateOperation,
  parseCreateOs,
  parseModuleHistoryMessage,
  parseModuleStatus,
  parsingStatusColor,
} from 'lib/utils/parse/module';
import { updateDetailAlertState, updateRetryAlertState } from 'modules/common';
import { useState } from 'react';
import { getSettingList } from 'modules/settings';
import { getSettingProperties } from 'lib/api/settings';
import { FormattedMessage, useIntl } from 'react-intl';

const ModulehistoryBody = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { startDt, endDt } = useSelector(
    (state) => state.modulehistories.search,
  );
  const { data, loading, error } = useSelector(
    (state) => state.modulehistories.modulehistories,
  );
  const [selectedId, setSelectedId] = useState(null);
  const [detailData, setDetailData] = useState([]);
  const { usedModuleRetry } = useSelector(
    (state) => state.settings.settings.data,
  );

  const fetch = useSelector((state) => state.modulehistories.fetch);

  const unLoadModulehistories = useCallback(
    () => dispatch(pageOut()),
    [dispatch],
  );

  const loadModulehistories = useCallback(
    () => dispatch(getModulehistories(st_date, ed_date)),
    [dispatch],
  );

  const handleRetryAlertOpen = useCallback(
    (id) => () => {
      setSelectedId(id);
      dispatch(updateRetryAlertState(true));
    },
    [dispatch],
  );

  const handleRetry = () => {
    retryCreateModule(selectedId)
      .then((response) => {
        if (response.data.data) {
          swal(
            intl.formatMessage({
              id: 'valid-success-request-retry',
            }),
            intl.formatMessage({
              id: 'valid-try-again-retry',
            }),
            'success',
            {
              buttons: false,
              timer: 1500,
            },
          );
          dispatch(updateRetryAlertState(false));
          dispatch(getModulehistories(startDt, endDt));
        }
      })
      .catch((e) => {
        swal(
          intl.formatMessage({
            id: 'valid-fail-request-retry',
          }),
          `${e.response.data}`,
        ).then((value) => {
          dispatch(updateRetryAlertState(false));
        });
      });
  };

  const handleDetailAlertOpen = useCallback(
    (id) => () => {
      getModulehistoryByModuleId(id).then((modulehistory) => {
        const viewData = [
          'packageName',
          'createUserName',
          'algorithm',
          'operationMode',
          'createOs',
          // 'createOperation',
          'elementOperation',
          'status',
          'message',
        ];

        let detailDataParsing = filterByJson(modulehistory.data.data, viewData);
        detailDataParsing.algorithm = parseAlgorithm(
          detailDataParsing.algorithm,
        );
        detailDataParsing.createOs = parseCreateOs(detailDataParsing.createOs);
        detailDataParsing.createOperation = parseCreateOperation(
          detailDataParsing.createOperation,
        );
        detailDataParsing.status = parseModuleStatus(detailDataParsing.status);
        detailDataParsing.message = parseModuleHistoryMessage(
          detailDataParsing.message,
        );
        setDetailData(detailDataParsing);
        dispatch(updateDetailAlertState(true));
      });
    },
    [],
  );

  useEffect(() => {
    if (fetch) {
      dispatch(changeSearchStartDate({ startDt: st_date }));
      dispatch(changeSearchEndDate({ endDt: ed_date }));
      dispatch(getSettingList());
      loadModulehistories();
    }
    return () => {
      if (!fetch) unLoadModulehistories();
    };
  }, [loadModulehistories, unLoadModulehistories, fetch]);

  const ModulehistoryData = {
    columns: [
      {
        Header: <FormattedMessage id="package-name" />,
        accessor: 'packageName',
        sort: false,
        width: '13%',
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="user-name" />,
        accessor: 'createUserName',
        sort: true,
        width: '10%',
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: <FormattedMessage id="algorithm" />,
        accessor: 'algorithm',
        sort: true,
        width: '7%',
        Cell: ({ value }) => <DefaultCell value={parseAlgorithm(value)} />,
      },
      {
        Header: <FormattedMessage id="operation-mode" />,
        accessor: 'operationMode',
        sort: false,
        width: '7%',
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: 'OS',
        accessor: 'createOs',
        sort: false,
        width: '7%',
        Cell: ({ value }) => <DefaultCell value={parseCreateOs(value)} />,
      },
      // {
      //   Header: <FormattedMessage id="encrypt-decrypt" />,
      //   accessor: 'createOperation',
      //   sort: false,
      //   width: '7%',
      //   Cell: ({ value }) => (
      //     <DefaultCell value={parseCreateOperation(value)} />
      //   ),
      // },
      {
        Header: <FormattedMessage id="status" />,
        accessor: 'status',
        sort: false,
        width: '7%',
        Cell: ({ value }) => (
          <StatusCell
            value={parseModuleStatus(value)}
            color={parsingStatusColor(value)}
          />
        ),
      },
      {
        Header: <FormattedMessage id="created-date" />,
        accessor: 'createDt',
        sort: true,
        width: '10%',
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="modified-date" />,
        accessor: 'updateDt',
        sort: true,
        width: '10%',
        Cell: ({ value }) => <DateCell value={value} />,
      },
      {
        Header: <FormattedMessage id="detail" />,
        accessor: 'moduleId',
        sort: false,
        width: '8%',
        Cell: ({ value }) => (
          <DetailCell handleDetailAlertOpen={handleDetailAlertOpen(value)} />
        ),
      },
    ],
    rows: data,
  };

  if (usedModuleRetry && JSON.parse(usedModuleRetry.value)) {
    ModulehistoryData.columns = [
      ...ModulehistoryData.columns,
      {
        Header: <FormattedMessage id="retry" />,
        accessor: 'retry',
        sort: false,
        width: '8%',
        Cell: ({ value }) => (
          <RetryCell
            enableRetry={value.status}
            handleRetry={handleRetryAlertOpen(value.id)}
          />
        ),
      },
    ];
  }

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  return (
    <Card>
      <DataTable table={ModulehistoryData} entriesPerPage={false} canSearch />
      <DetailAlert
        title={<FormattedMessage id="module" />}
        detailData={detailData}
        identity={'packageName'}
      />
      <RetryAlert
        resource={<FormattedMessage id="module" />}
        handleRetry={handleRetry}
      />
    </Card>
  );
};

export default ModulehistoryBody;
