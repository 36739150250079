import axios from 'axios';

export const getRoleList = () => axios.get(`/api/roles`);

export const getRole = (id) => axios.get(`/api/roles/${id}`);

export const postRole = (role) => axios.post(`/api/roles`, role);

export const putRole = (id, role) => axios.put(`/api/roles/${id}`, role);

export const deleteRole = (id) => axios.delete(`/api/roles/${id}`);
