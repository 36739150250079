import EmergencyAlert from 'components/common/EmergencyAlert';
import MDBox from 'components/otis/MDBox';
import Sidenav from 'components/sidenav';
import { setMiniSidenav, useMaterialUIController } from 'context';
import { getNoticeList } from 'lib/api/notices';
import { authencationMenu } from 'modules/auth';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import swal from 'sweetalert';
import Navbar from '../navbar/Navbar';

const ContentLayout = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [emergencyAlertList, setEmergencyAlertList] = useState([]);
  const [emergencyAlertOpen, setEmergencyAlertOpen] = useState(false);
  const menu = useSelector((state) => state.auth.authonrities);
  const thunkDispatch = useDispatch();

  const validEmergencyNotice = async () => {
    try {
      const response = await getNoticeList();
      let noticeList = response.data.data;
      if (noticeList.length > 0) {
        let currentDate = moment().format('YYYY-MM-DD');
        let alertNoticeList = noticeList.filter(
          (notice) =>
            notice.isEmergency &&
            moment(currentDate).isBetween(
              notice.alertStartDt,
              notice.alertEndDt,
              undefined,
              [],
            ),
        );
        if (alertNoticeList.length > 0) {
          setEmergencyAlertOpen(true);
        }
        setEmergencyAlertList(alertNoticeList);
      }
    } catch (error) {
      swal(
        '서버와의 통신 오류',
        '서버가 응답하지 않습니다.\n잠시 후 다시 시도하세요.',
        'error',
      );
    }
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    validEmergencyNotice();
    thunkDispatch(authencationMenu());
  }, [thunkDispatch]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: 'relative',

        [breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <Sidenav
        color={sidenavColor}
        routes={menu}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Navbar />
      <Outlet />
      <MDBox>
        <EmergencyAlert
          open={emergencyAlertOpen}
          handleClose={() => setEmergencyAlertOpen(!emergencyAlertOpen)}
          emergencyAlertList={emergencyAlertList}
        />
      </MDBox>
    </MDBox>
  );
};

export default ContentLayout;
