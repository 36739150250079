import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box,
  Collapse,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import MDBox from 'components/otis/MDBox';
import {
  collapseIcon,
  collapseIconBox,
  collapseItem,
  collapseText,
} from 'components/sidenav/styles/sidenavCollapse';
import { useMaterialUIController } from 'context';
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const SidenavCollapse = (props) => {
  const { title, href, icon, subItem } = props;
  const location = useLocation();
  const active = href ? location.pathname === href : false;
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem component="li">
        <MDBox
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
              })
            }
          >
            {typeof icon === 'string' ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>
                {icon}
              </Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={title}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          {subItem && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {' '}
              {!miniSidenav ? (
                <ListItemButton onClick={handleClick}>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              ) : (
                <></>
              )}
            </Box>
          )}
        </MDBox>
      </ListItem>
      {subItem && (
        <Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subItem.map((subItem, subItemIndex) => {
                return (
                  <NavLink
                    key={subItemIndex}
                    rel="noreferrer"
                    to={subItem.href}
                    sx={{ textDecoration: 'none' }}
                  >
                    <SidenavCollapse
                      key={subItemIndex}
                      href={subItem.href}
                      title={<FormattedMessage id={subItem.langId} />}
                      icon={subItem.icon}
                    />
                  </NavLink>
                );
              })}
            </List>
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default SidenavCollapse;
