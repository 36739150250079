import AddIcon from '@mui/icons-material/Add';
import MDBox from 'components/otis/MDBox';
import MDButton from 'components/otis/MDButton';
import MDTypography from 'components/otis/MDTypography';
import { getCustomer } from 'lib/api/customers';
import { validModuleCreate } from 'lib/api/modules';
import { getSettingProperties } from 'lib/api/settings';
import { existAuthonrities, getUserCustomer, getUserId } from 'lib/utils/auth';
import { changeForm, initialModule } from 'modules/modules';
import { getUserList } from 'modules/users';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { FormattedMessage, useIntl } from 'react-intl';

const ModuleListHeader = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const existWriteAuth = existAuthonrities('MODULE_RW');
  const userCustomer = getUserCustomer();
  const userId = getUserId();

  const handleFormOpen = () => {
    dispatch(getUserList());
    dispatch(changeForm({ status: true }));
  };

  const handleAddModule = () => {
    getSettingProperties('usedModuleCreate')
      .then((setting) => {
        if (setting.data.data.value === 'true') {
          if (userCustomer) {
            getCustomer(userCustomer).then((customer) => {
              let currentDate = moment().format('YYYY-MM-DD');
              let startDt = customer.data.data.expiredStartDt;
              let endDt = customer.data.data.expiredEndDt;
              if (
                moment(currentDate).isBetween(startDt, endDt, undefined, [])
              ) {
                validModuleCreate(userId)
                  .then((res) => {
                    if (res.data.data) {
                      dispatch(initialModule());
                      handleFormOpen();
                    } else {
                      swal(
                        intl.formatMessage({ id: 'license-limit' }),
                        intl.formatMessage({
                          id: 'valid-module-created-exceed',
                        }) +
                          ' \n' +
                          intl.formatMessage({ id: 'valid-contact-admin' }),
                        'error',
                      );
                    }
                  })
                  .catch((error) => {
                    swal(
                      intl.formatMessage({ id: 'server-error' }),
                      error.response.data,
                      'error',
                    );
                  });
              } else {
                swal(
                  intl.formatMessage({ id: 'license-error' }),
                  intl.formatMessage({ id: 'valid-module-license-expired' }) +
                    ' \n' +
                    intl.formatMessage({ id: 'valid-contact-admin' }),
                  'error',
                );
              }
            });
          } else {
            swal(
              intl.formatMessage({ id: 'license-error' }),
              intl.formatMessage({ id: 'valid-customer-registered' }) +
                ' \n' +
                intl.formatMessage({ id: 'valid-contact-admin' }),
              'error',
            );
          }
        } else {
          swal(
            intl.formatMessage({ id: 'valid-module-created-disabled' }),
            intl.formatMessage({ id: 'valid-check-notice' }),
            'error',
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  return (
    <MDBox>
      <MDBox
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: -1,
        }}
      >
        <MDTypography
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'text.secondary' }}
          variant="h4"
        >
          <FormattedMessage id="module" />
        </MDTypography>
        {existWriteAuth ? (
          <MDBox sx={{ m: 1 }}>
            <MDButton
              color="info"
              variant="contained"
              startIcon={<AddIcon fontSize="small" />}
              onClick={handleAddModule}
            >
              <FormattedMessage id="add-module" />
            </MDButton>
          </MDBox>
        ) : (
          <></>
        )}
      </MDBox>
    </MDBox>
  );
};

export default ModuleListHeader;
