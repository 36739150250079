import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import TimelineItem from 'components/otis/Timeline/TimelineItem';
import TimelineList from 'components/otis/Timeline/TimelineList';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';

const EmergencyAlert = (props) => {
  const intl = useIntl();
  const { open, handleClose, emergencyAlertList } = props;

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="notice" />
      </DialogTitle>
      <DialogContent>
        <TimelineList
          title={intl.formatMessage({
            id: 'alert',
          })}
        >
          {emergencyAlertList.map((emergencyAlert, index) => (
            <TimelineItem
              key={index}
              title={emergencyAlert.title}
              description={decodeURIComponent(
                escape(window.atob(emergencyAlert.content)),
              )}
              dateTime={moment(emergencyAlert.createDt).format(
                'YYYY-MM-DD HH:mm:ss',
              )}
              icon={<SpeakerNotesIcon />}
            />
          ))}
        </TimelineList>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage id="check" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmergencyAlert;
