/**
=========================================================
* Otis Admin PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Icon from '@mui/material/Icon';
// Otis Admin PRO React components
import MDBox from 'components/otis/MDBox';
import MDTypography from 'components/otis/MDTypography';
import PropTypes from 'prop-types';

function CustomerStatisticsCard({ color, title, count, icon }) {
  return (
    <>
      <MDBox display="flex" alignItems="center" pl={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === 'light' ? 'dark' : 'white'}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
        >
          <Icon fontSize="medium" color="inherit" sx={{ height: '50%' }}>
            {icon}
          </Icon>
        </MDBox>
        <MDBox display="flex" mt="auto" alignItems="center">
          <MDBox ml={3} mt={0.5} lineHeight={1.4}>
            <MDTypography variant="button" fontWeight="bold" color="text">
              {title}
            </MDTypography>
            <MDTypography variant="h4">{count}</MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

// Setting default values for the props of ComplexStatisticsCard
CustomerStatisticsCard.defaultProps = {
  color: 'info',
  percentage: {
    color: 'success',
    text: '',
    label: '',
  },
};

// Typechecking props for the ComplexStatisticsCard
CustomerStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  // title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'white',
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default CustomerStatisticsCard;
